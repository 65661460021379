import { combineReducers } from 'redux'

import participant from './participant.reducer'
import list from './participants.reducer'

import names from './participants_names.reducer'

const participants = combineReducers({
  list,
  names,
  participant
})

export default participants
