import React from 'react'

import { ConfigProvider } from 'antd'
import trTR from 'antd/es/locale/tr_TR'
import Provider from 'react-redux/es/components/Provider'

import AuthRouter from './AuthRouter'
import store from './store'
import './App.css'

function App () {
  return (
    <ConfigProvider locale={trTR}>
      <Provider store={store}>
        <AuthRouter />
      </Provider>
    </ConfigProvider>
  )
}

export default App
