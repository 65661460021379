import axios from 'axios'

import system from './config'

const getGeoCode = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(system.webService + '/geocode/', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  getGeoCode
}
