import React, { useState, useEffect } from 'react'

import {
  PlusOutlined,
  LoadingOutlined,
  CloseCircleFilled
} from '@ant-design/icons'
import { Avatar, Badge, Upload, message } from 'antd'
import {  getDownloadURL, ref as sRef, uploadBytesResumable } from 'firebase/storage'
import moment from 'moment'

import { firebaseStorage } from '../firebaseApp'

import ImgCropper from './ImageCropper'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const PhotoUploader = ({ onChange, value }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile]       = useState({})

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false)

        setFile((prev) => {
          return {
            name: info.file.name,
            url: info.file.xhr,
            date: moment(),
            fileType: info.file.type
          }
        })
      })
    }
  }

  useEffect(() => {
    if (value) { setFile(value) }
  }, [])

  useEffect(() => {
    if (onChange) { onChange(file) }
  }, [onChange, file])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml'
    if (!isJpgOrPng) {
      message.error('Sadece JPG,PNG veya SVG dosyaları yükleyebilirsiniz!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Seçtiğiniz dosya 5MB dan küçük olmalıdır!')
    }
    return isJpgOrPng && isLt5M
  }

  const handleOnClick = () => {
    setFile({})
  }

  const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Yükle</div>
        </div>
  )

  const customUpload = ({ onError, onSuccess, file }) => {
    const folderName = moment().format('YYYY_MM') // store attachments into day folders
    const fileName   = file.name.replace(/[/\\?%*:|"<>]/g, '-')

    // create upload task for the new attachment
    // const uploadTask=storage.ref(`/${folderName}/${fileName}`).put(file)

    const uploadTask = uploadBytesResumable(sRef(firebaseStorage, `/${folderName}/${fileName}`), file)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (err) => { console.log(err) },
      () => {
        // store image in firebase strorage
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onSuccess(null, downloadURL)
        }).catch((error) => console.log(error))
      })
  }

  return (
        <>
            <ImgCropper modalTitle='Düzenle'>
                <Upload
                    name="file"
                    listType="picture-card"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={customUpload}
                    showUploadList={false}
                    style={{ height: 128, width: 128 }}
                >
                    {file.url ? null : uploadButton}
                </Upload>
            </ImgCropper>
            <Badge style={{ display: file.url ? 'inline-block' : 'none', cursor: 'pointer' }} count={<CloseCircleFilled style={{ color: '#f5222d' }} />} onClick={handleOnClick}>
                <Avatar style={{ display: file.url ? 'inline-block' : 'none' }} src={file.url} size={128} />
            </Badge>
        </>
  )
}
export default PhotoUploader
