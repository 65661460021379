/* eslint-disable brace-style */
import React, { useState, useEffect } from 'react'

import { Button, Spin } from 'antd'

import moment from 'moment'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import 'moment/locale/tr'
import './css/summary.css'

import { firebaseAuth } from '../../../firebaseApp'
import { ExportSummaryTable, GetSummaryTable } from '../../../services/contactsService'

const Summary = () => {
  moment.locale('tr')
  const [isLoading, setIsLoading]     = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const selectedYear                  = new Date().getFullYear()
  const [options, setOptions]         = useState({
    chart: {
      type: 'heatmap',
      animations: { enabled: false }
    },
    plotOptions: {
      heatmap: {
        radius: 0
      }
    },
    stroke: {
      width: 1,
      colors: ['#000']
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000']
      },
      background: {
        borderWidth: 1,
        borderColor: '#000'
      },
      formatter: (val, opts) => {
        return (val === 1 || val === 2) ? '-' : val === 0 ? '+' : ''
      }
    },
    colors: ['#ffec3d'],
    title: { text: 'Aylık Ödemeler Tablosu' },
    series: [],
    xaxis: { position: 'top' },
    yaxis: {
      reversed: true,
      labels: {
        maxWidth: undefined,
        offsetX: 0,
        offsetY: 10,
        style: {
          cssClass: 'moveUp'
        }
      }

    },
    isLoaded: false
  })

  const auth = useSelector((state) => state.auth)

  useEffect(() => { refreshData() }, [selectedYear])

  const refreshData = () => {
    let lowestMonth  = 0
    let heigestMonth = 0

    setIsLoading(true)

    firebaseAuth.currentUser.getIdToken().then(token => {
      GetSummaryTable({ token }).then(d => {
        setIsLoading(false)
        setOptions((prev) => {
          d.forEach(p => {
            let currentPayedMonth = 0

            // calculate current participants payed months
            if (p.pledges[selectedYear]) {
              currentPayedMonth = (
                (p.transactions[selectedYear] ? p.transactions[selectedYear].sumYear : 0) +
                (p.balances[selectedYear - 1] ? p.balances[selectedYear - 1] : 0)
              ) / p.pledges[selectedYear]
            }

            // set the lowestmonth of the balance sheet
            if (currentPayedMonth < lowestMonth) { lowestMonth = currentPayedMonth }

            // set the heighest month of the balance sheet
            if (currentPayedMonth >= heigestMonth) { heigestMonth = currentPayedMonth }

            // if the height month is more then 12 fix it to 12
            heigestMonth = heigestMonth > 12 ? 12 : heigestMonth
          })
          const series = d.map(p => {
            let currentPayedMonth = 12

            // calculate current participants payed months
            if (p.pledges[selectedYear]) {
              currentPayedMonth = Math.floor((
                (p.transactions[selectedYear] ? p.transactions[selectedYear].sumYear : 0) +
                (p.balances[selectedYear - 1] ? p.balances[selectedYear - 1] : 0)
              ) / p.pledges[selectedYear])
            }

            if (moment(p.startingDate).isAfter(moment({ year: selectedYear, month: 0, day: 1 }))) { currentPayedMonth += moment(p.startingDate).months() }

            const data = []

            for (let i = Math.ceil(lowestMonth); i < heigestMonth; i++) {
              let yValue

              // if we are talking about past
              if (i <= moment().month()) {
                if (i < currentPayedMonth) {
                  yValue = 0
                } // print +
                else {
                  yValue = i === moment().month() ? 2 : 1
                } // print -
              }
              // if we are talking about  the future
              else {
                if (i <= currentPayedMonth) { yValue = 0 } // print +
                else { yValue = null }
              }

              data.push({ x: moment({ year: selectedYear, month: 0, day: 1 }).add(i, 'months').format('MMM.YY'), y: yValue })
            }
            return { name: p.fullname, data }
          })
          return {
            ...prev,
            series,
            isLoaded: true
          }
        })
      })
    })
  }

  const handleExport = () => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      setIsExporting(true)
      ExportSummaryTable({ token }).then(d => {
        setIsExporting(false)
        window.open(d.url, '_blank')
      })
    })
  }
  return (<Spin tip="Lütfen Bekleyin..." spinning={isLoading}>
    <Button disabled={isExporting || (auth.data.role === 'familyManager')} loading={isExporting} onClick={handleExport} >E-Tabloya Aktar</Button>
    {options.isLoaded ? <Chart options={options} series={options.series} type="heatmap" height={options.series.length * 30} width={options.series[0].data.length * 55} /> : null}
  </Spin>)
}

export default Summary
