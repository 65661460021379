import React, { useState, useEffect } from 'react'

import { DownOutlined, PlusCircleOutlined, MinusCircleOutlined, DeleteOutlined, CheckCircleTwoTone, CloseCircleTwoTone, SearchOutlined, FileExcelOutlined } from '@ant-design/icons'
import { Table, Tag, Dropdown, Button, Menu, Popconfirm, message, Input } from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom'

import FormDrawer from '../../../components/FormDrawer'
import NestedTable from '../../../components/NestedTable'
import { firebaseAuth } from '../../../firebaseApp'
import { GetParticipantContacts as GetParticipantContactService } from '../../../services/contactsService'
import { GetParticipantContacts, DeleteParticipantContacts, UpdateParticipantContacts } from '../../../store/actions/contacts'

import { useDebounce } from '../../../utils/customHooks'

import exportExcel from '../../../utils/exportExcel'

import ParticipantForm from './ParticipantForm'

const SelectedParticipantsMenu = (props) => {
  return (
    <Menu >
      <Menu.Item key="1" onClick={() => { props.SetDisplayOnSummary(true) }}>
        <PlusCircleOutlined />
          Alacak Takip Tablosuna Ekle
      </Menu.Item>
      <Menu.Item key="2" onClick={() => { props.SetDisplayOnSummary(false) }}>
        <MinusCircleOutlined />
          Alacak Takip Tablosundan Çıkar
      </Menu.Item>
      <Menu.Item key="3">
        <Popconfirm
          title="Seçilen Katılımcıları silmek istediğinizden emin misiniz?"
          onConfirm={props.ConfirmDelete}
          onCancel={props.CancelDelete}
          okText="Evet"
          cancelText="Hayır"
          placement="bottom"
        >
          <DeleteOutlined />
          Sil
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )
}

const ParticipantsList = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { _id }  = useParams()

  const participants = useSelector((state) => state.participants.list.data)
  const isLoading    = useSelector((state) => state.participants.list.isLoading)
  const auth         = useSelector((state) => state.auth)

  const [selectedRows, setSelectedRows]       = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [drawerVisible, setDrawerVisible]     = useState(false)
  const [searchQuery, setSearchQuery]         = useState('')
  const debouncedQuery                        = useDebounce(searchQuery, 500)

  const columns = (mode) => [{
    title: 'Kişi',
    width: 200,
    dataIndex: ['fullname'],
    key: ['fullname'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      if (mode === 'export') return text

      return <Tag className="nameTag" color={record.participation.isPerson ? 'geekblue' : 'green'} onClick={() => { navigate(`/participants/${record._id}`) }}   > {text}</Tag>
    },
    sorter: true
  },
  {
    title: 'Başlangıç',
    width: 110,
    dataIndex: ['participation', 'startingDate'],
    key: ['participation', 'startingDate'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      return (moment(record.participation.startingDate).format('DD.MM.YYYY'))
    },
    sorter: true
  },
  {
    title: 'E-Posta',
    width: 100,
    dataIndex: 'email',
    key: 'email',
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      return text
    }
  },
  {
    title: 'Özet Tabloda Görüntüle',
    width: 20,
    dataIndex: ['participation', 'displayAtSummary'],
    key: ['participation', 'displayAtSummary'],
    sorter: (a, b) => (a.participation.displayAtSummary === b.participation.displayAtSummary) ? 0 : a.participation.displayAtSummary ? -1 : 1,
    render: (text, record) => {
      console.log(record)
      if (record.isSummaryRow) return ''
      if (mode === 'export') return text
      return <span>{record.participation?.displayAtSummary ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#cf1322" />}</span>
    }
  },
  {
    title: 'Son Taahüt',
    width: 20,
    dataIndex: ['participation', 'pledges'],
    key: ['participation', 'pledges'],
    sorter: true,
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      try {
        const lastPledge = record?.participation.pledges[moment().year()] || ''
        if (mode === 'export') return lastPledge

        return <span>{lastPledge}</span>
      } catch (e) {
        if (mode === 'export') return ''
        return <span></span>
      }
    }
  }
  ]

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50
  })

  useEffect(() => {
    getParticipants({
      page: 1,
      query: debouncedQuery || null
    })
  }, [debouncedQuery])
  useEffect(() => {
    setPagination((prev) => {
      return {
        ...prev,
        pageSize: participants.limit,
        current: participants.page,
        total: participants.totalDocs
      }
    })
  }, [participants])

  useEffect(() => { if (_id) showDrawer() }, [_id])

  const getParticipants = (data) => {
    firebaseAuth.currentUser.getIdToken().then((token) => {
      dispatch(
        GetParticipantContacts({
          token,
          data
        })
      )
    })
  }

  const handleTableChange = (pag, filters, sorter) => {
    getParticipants({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pag.current,
      limit: pag.pageSize,
      ...filters
    })
  }

  const showDrawer = _id => setDrawerVisible(true)
  const hideDrawer = () => {
    navigate(location.pathname.replace(_id, ''))
    setDrawerVisible(false)
  }

  const ConfirmDelete       = e => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(DeleteParticipantContacts({
        token,
        _ids: selectedRows.map(s => s._id)
      }))
      setSearchQuery('')
      message.success('Seçilen Kişiler Silindi')
    })
  }
  const SetDisplayOnSummary = on => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(UpdateParticipantContacts({ token, _ids: selectedRowKeys, contact: { participation: { displayAtSummary: on } } }))
      getParticipants()
      message.success('Katılımcılar Güncellendi')
    })
  }

  // participation:
  // displayAtSummary: false
  // isParticipant: true
  // isPerson: true
  // pledges: {}
  // startingDate: "2019-01-01T00:00:00.000Z"
  // updatedBy: "Enes Yılmaz"
  // [[Prototype]]: Object
  const exportToExcel = () => {    // Get all participantData from DB
    firebaseAuth.currentUser.getIdToken().then(token => {
      GetParticipantContactService({
        token,
        data: { getAll: true }
      }).then(contactsData => {
        const exportData = [...contactsData.map(contact => {
          return {
            fullname: contact.fullname,
            participation: contact.participation
            // ...contact.participation.pledges
          }
        }), { isSummaryRow: true }]

        exportExcel([{ headers: columns('export'), data: exportData, sheetName: 'Katılımcılar' }], 'Katılımcılar')
      }).catch(err => { console.log(err) })
    })
  }

  if (auth.data.role !== 'admin') return <Navigate to="/" />

  return (
    <>
      <div>
        <Button
          type="primary"
          disabled={auth.data.role !== 'admin'}
          onClick={showDrawer}>Yeni Katılımcı</Button>
        <Dropdown disabled={selectedRowKeys.length === 0 || auth.data.role !== 'admin'} overlay={<SelectedParticipantsMenu ConfirmDelete={ConfirmDelete} SetDisplayOnSummary={SetDisplayOnSummary} />}>
          <Button>
            Seçim İşlemleri : <DownOutlined />
          </Button>
        </Dropdown>

        <Button onClick={exportToExcel}>
          <FileExcelOutlined /> XLS İndir
        </Button>

      </div>

      <div style={{ marginBottom: 16 }}>
        <Input
          addonBefore="Ara"
          addonAfter={<SearchOutlined />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <Table
        dataSource={participants.docs}
        columns={columns()}
        expandable={{ expandedRowRender: record => <NestedTable participantID={record._id} /> }}
        bordered
        size="small"
        rowKey='_id'
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        rowSelection={
          auth.data.role === 'admin'
            ? {
                selectedRows,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(selectedRowKeys)
                  setSelectedRows(selectedRows)
                }
              }
            : null}
      />
      <FormDrawer formElement={ParticipantForm} visible={drawerVisible} hide={hideDrawer}
        disableSubmit={auth.data.role !== 'admin'} />
    </>
  )
}

export default ParticipantsList
