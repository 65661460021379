import { put, takeLatest, call } from 'redux-saga/effects'

import { DeleteParticipantContacts } from '../../../services/contactsService'

import {
  DELETE_PARTICIPANT_CONTACTS,
  DELETE_PARTICIPANT_CONTACTS_SUCCESS,
  DELETE_PARTICIPANT_CONTACTS_ERROR
}
  from '../../actions/contacts'

function * deleteParticipantContactsSaga (action) {
  try {
    yield call(DeleteParticipantContacts, action.payload)
    yield put({
      type: DELETE_PARTICIPANT_CONTACTS_SUCCESS,
      payload: action.payload._ids.participant
    })
  } catch (error) {
    yield put({
      type: DELETE_PARTICIPANT_CONTACTS_ERROR, payload: error
    })
  }
}

export function * deleteParticipantContactsSagaWatcher () {
  yield takeLatest(DELETE_PARTICIPANT_CONTACTS, deleteParticipantContactsSaga)
}
