import React, { useEffect, useState } from 'react'

import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col } from 'antd'
import * as L from 'leaflet'
import moment from 'moment'

import Map from '../../../../components/Map'
import pinopint from '../../../../images/pinpoint.svg'
import { DirectionUrl } from '../../../../utils/helpers'
import ResidencesFormItem from '../FormItems/ResidencesFormItem'

const ResidencesForm = (params) => {  const { mainForm, validateMessages, next, prev, mode, data } = params
  const editMode                                               = mode === 'edit'
  const wizardMode                                             = (next && prev)
  const addressData                                            = (
    data &&  // if there is data
        data.residences && // if data contains a residences array
        data.residences.filter(a => !Object.keys(a).includes('end')) // filter addresses that has an ending date
          .sort((a, b) => moment(a.start).diff(moment(b.start))) // sort the rest of the residences by their staring dates
          .slice(-1)[0]) || // get the last element of the array
        {}

  const formItemLayout = (index) => {
    if (editMode) {
      return {
        labelCol:
                {
                  xs: { span: 24 },
                  sm: { span: 7 },
                  md: { span: 4 },
                  lg: { span: 4 },
                  xl: { span: 3 }
                },
        wrapperCol:
                    wizardMode ? {
                      xs: { span: 24, offset: 0 },
                      sm: { span: index !== 0 ? 18 : 24, offset: index !== 0 ? 7 : 0 },
                      md: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
                      lg: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
                      xl: { span: index !== 0 ? 21 : 24, offset: index !== 0 ? 3 : 0 }
                    }
                      :                        {
                          xs: { span: 24, offset: 0 },
                          sm: { span: 24, offset: 0 },
                          md: { span: 24, offset: 0 },
                          lg: { span: 24, offset: 0 },
                          xl: { span: 24, offset: 0 }
                        }
      }
    } else {
      return {
        wrapperCol: { span: 24, offset: 0 }
      }
    }
  }

  return editMode
    ? <Form form={mainForm} name='residencesStepForm' validateMessages={validateMessages} onFinish={next} >
            <Form.List name={['family', 'residences']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='İkametgah Bilgisi Eklenmemiş' />
                              : fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 && wizardMode ? 'İkametgahlar' : ''}
                                        {...formItemLayout(index)}
                                        key={field.key}
                                    >
                                        <Card actions={[<DeleteOutlined key="delete" onClick={() => remove(field.name)} />]} >
                                            <ResidencesFormItem root={field.name} form={mainForm} index={index} editMode={editMode} wizardMode={wizardMode} />
                                        </Card>

                                    </Form.Item>
                              ))}
                            {
                                editMode
                                  ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }}>
                                        <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                            <PlusOutlined />İkametgah Ekle</Button>
                                    </Form.Item>
                                  :                                    null
                            }

                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>
    :        <LastResidenceViewer addressData={addressData} />
}

const LastResidenceViewer = ({ addressData }) => {  const [marker, setMarker]                 = useState(null)
  const [markerPosition, setMarkerPosition] = useState(addressData.coords)

  const onMount = point => map => {
    if (point.coords) {
      map.panTo(point.coords)
      map.setZoom(14)
      if (!marker) {
        setMarker(() => {
          const m = L.marker(point.coords, {            icon: L.icon({
              iconUrl: pinopint,
              iconSize: [40, 40], // size of the icon
              iconAnchor: [22, 39]
            }),
            draggable: false
          })

          m.addTo(map)
          return m
        })
      } else {
        setMarker((prev) => {
          setTimeout(() => {
            map.panTo(point.coords)
          }, 400)
          prev.setLatLng(point.coords)
          return prev
        })
      }
    }
  }

  useEffect(() => {
    if (addressData.coords) { setMarkerPosition(addressData.coords) }
  }, [addressData.coords])

  const mapProps = {
    options: {
      center: markerPosition || { lat: 41.017719, lng: 28.996664 },
      zoom: 11
    },
    style: { height: 150 }
  }
  return addressData.coords
    ? <>
            <Map {...mapProps} onMount={onMount({ title: 'abc', coords: addressData.coords })} />
            <span className='addressSpan'>Adres: {addressData.address}</span>
            <Button target='blank' type='primary' style={{ width: '100%', marginTop: '10px' }} href={DirectionUrl(addressData.coords)}>Navigasyonu Başlat</Button>
        </>
    :        <Empty description='İkametgah Bilgisi Eklenmemiş' />
}

ResidencesForm.defaultProps = {  mode: 'edit'
}

export default ResidencesForm
