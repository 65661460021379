import { put, takeLatest, call } from 'redux-saga/effects'

import { GetParticipantContacts } from '../../../services/contactsService'

import {
  GET_PARTICIPANT_CONTACTS,
  GET_PARTICIPANT_CONTACTS_SUCCESS,
  GET_PARTICIPANT_CONTACTS_ERROR
}
  from '../../actions/contacts/'

function * getParticipantContactsSaga (action) {
  try {
    const data =  yield call(GetParticipantContacts, action.payload)
    yield put({
      type: GET_PARTICIPANT_CONTACTS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_PARTICIPANT_CONTACTS_ERROR, payload: error
    })
  }
}

export function * getParticipantContactsSagaWatcher () {
  yield takeLatest(GET_PARTICIPANT_CONTACTS, getParticipantContactsSaga)
}
