import React from 'react'

import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col, Descriptions } from 'antd'
import moment from 'moment'
import numeral from 'numeral'

import PledgesFormItem from '../FormItems/PledgesFormItem'

const PledgesForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, data } = params
  const editMode                                               = mode === 'edit'
  const wizardMode                                             = (next && prev)

  const formItemLayout = index => {
    return {
      labelCol: wizardMode ? {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 4 },
        lg: { span: 4 },
        xl: { span: 3 }
      } : { span: 24 },
      wrapperCol: wizardMode ? {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        md: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        lg: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        xl: { span: index !== 0 ? 21 : 24, offset: index !== 0 ? 3 : 0 }
      } : { span: 24 }
    }
  }

  return editMode ? (
        <Form form={mainForm} name='pledgesStepForm' validateMessages={validateMessages} onFinish={next}  >

            <Form.List name={['family', 'pledges']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='Taahüt Eklenmemiş' />
                              : fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 && wizardMode ? 'Taahütlerimiz' : ''}
                                        {...formItemLayout(index)}
                                        key={field.key}
                                    >
                                        <Card actions={[<DeleteOutlined key="delete" onClick={() => remove(field.name)} />]} >
                                            <PledgesFormItem root={field.name} wizardMode={wizardMode} />
                                        </Card>

                                    </Form.Item>
                              ))}

                            {editMode
                              ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                                    <Button type="dashed" onClick={() => {
                                      add({
                                        year: moment()
                                      })
                                    }} style={{ width: '100%' }} >
                                        <PlusOutlined />Taahüt Yılı Ekle</Button>
                                </Form.Item>
                              : null}
                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>

  )
    : <LastPledgeViewer familyData={data} />
}
const LastPledgeViewer = ({ familyData }) => {
  if (familyData && familyData.pledges && familyData.pledges.length > 0) {
    const latestPledge = familyData.pledges.reduce((prev, current) => (prev.year > current.year) ? prev : current, { year: 0, cash: 0, supply: 0 })

    return <Descriptions size="small" title={
            (moment.isMoment(latestPledge.year) ? moment(latestPledge.year).format('YYYY') : moment({ year: latestPledge.year }).format('YYYY')) + ' Yılı Ödeme Taahütü'
        } bordered column={1}>
            <Descriptions.Item label='Nakit Taahüt'>{numeral(latestPledge.cash).format('0,0[.]00 $')}</Descriptions.Item>
            <Descriptions.Item label='Erzak Taahüt'>{numeral(latestPledge.supply).format('0,0[.]00 $')}</Descriptions.Item>
        </Descriptions>
  } else { return <Empty description='Taahüt Bilgisi Eklenmemiş' /> }
}

PledgesForm.defaultProps = {
  mode: 'edit'
}

export default PledgesForm
