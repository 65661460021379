/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react'

import { Form, Steps, Button, Typography, Col, Row, Input, Modal, message, Avatar } from 'antd'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { firebaseAuth } from '../../../firebaseApp'
import { AddFamily } from '../../../services/familiesService'
import { validateMessages } from '../../../utils/globalVars'
import { ConvertStringToMoment } from '../../../utils/helpers'

import AssociationsForm from './Forms/AssociationsForm'
import BankForm from './Forms/BankForm'
import ChildrenForm from './Forms/ChildrenForm'
import FamilyManagersForm from './Forms/FamilyManagersForm'
import NotesForm from './Forms/NotesForm'
import OverheadsForm from './Forms/OverheadsForm'
import ParentsForm from './Forms/ParentsForm'
import PledgesForm from './Forms/PledgesForm'
import ResidencesForm from './Forms/ResidencesForm'
import Step1 from './Forms/Step1'
const { Step } = Steps
const key      = 'familySavingMessage'

const useStyles = createUseStyles({
  responsiveStepper: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  responsiveStep: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
})

const FamilyFormProvider = () => {
  const classes    = useStyles()
  const auth       = useSelector((state) => state.auth)
  const navigate   = useNavigate()
  const [mainForm] = Form.useForm()

  const [current, setCurrent]               = useState(0)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const prev                                = () => setCurrent(prev => prev - 1)
  const next                                = () => setCurrent(prev => prev + 1)

  const continueForm = () => {
    setConfirmVisible(false)
    const formCache = JSON.parse(localStorage.getItem('formCache'))

    // Convert date values into moment
    formCache.family = ConvertStringToMoment(formCache.family)

    mainForm.setFieldsValue({ family: formCache.family })
    setCurrent(formCache.stepIndex)
  }
  const startOver = () => {
    setConfirmVisible(false)
    localStorage.removeItem('formCache')
    setCurrent(0)
  }

  const steps = [{
    title: 'Aile Oluşturucu',
    content: <Step1 mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Ebeveyn',
    content: <ParentsForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Çocuklar',
    content: <ChildrenForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'İkametgahlar',
    content: <ResidencesForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Genel Giderler',
    content: <OverheadsForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Taahütler',
    content: <PledgesForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Banka Bilgileri',
    content: <BankForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Diğer Kuruluşlar',
    content: <AssociationsForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Aile Sorumlusu',
    content: <FamilyManagersForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Notlar',
    content: <NotesForm mainForm={mainForm} next={next} prev={prev} validateMessages={validateMessages} />
  },
  {
    title: 'Son'
  }
  ]
  useEffect(() => {
    const formCache = JSON.parse(localStorage.getItem('formCache'))
    if (formCache) { setConfirmVisible(true) }
  }, [])

  const onFinish = ({ family }) => {
    if (family.pledges) { family.pledges = family.pledges.map(p => ({ ...p, year: parseInt(p.year.format('YYYY')) })) }
    if (family.overheads) { family.overheads = family.overheads.map(o => ({ ...o, year: parseInt(o.year.format('YYYY')) })) }
    family.createdBy = auth.data.contact.fullname

    // exportToJson(family)
    message.loading({ content: 'Form Gönderiliyor...', key })

    firebaseAuth.currentUser.getIdToken().then(token => {
      AddFamily({ token, family }).then(() => {
        message.success({ content: 'Form Kayıt Edildi!', key, duration: 3 })
        // startOver()
        navigate('/families')
      })
    })
  }

  // const exportToJson=(objectData) => {
  //     let filename="export.json";
  //     let contentType="application/json;charset=utf-8;";
  //     if(window.navigator&&window.navigator.msSaveOrOpenBlob) {
  //         var blob=new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))],{type: contentType});
  //         navigator.msSaveOrOpenBlob(blob,filename);
  //     } else {
  //         var a=document.createElement('a');
  //         a.download=filename;
  //         a.href='data:'+contentType+','+encodeURIComponent(JSON.stringify(objectData));
  //         a.target='_blank';
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //     }
  // }
  return (

    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          const { familyForm } = forms
          const family         = familyForm.getFieldValue('family') || {}

          if (name === 'step1Form') { familyForm.setFieldsValue({ family: { ...family, ...values } }) }

          if (
            name === 'parentsStepForm' ||
            name === 'ChildrenForm' ||
            name === 'residencesStepForm' ||
            name === 'overheadsStepForm' ||
            name === 'pledgesStepForm' ||
            name === 'bankForm' ||
            name === 'associationsStepForm' ||
            name === 'familyManagersStepForm' ||
            name === 'notesStepForm' ||
            name === 'step10Form') { familyForm.setFieldsValue({ family: { ...family, ...values.family } }) }

          localStorage.setItem('formCache', JSON.stringify({ family: familyForm.getFieldsValue().family, stepIndex: current }))
        }}
      >
        <Row>
          <Col xs={0} sm={7} md={5} lg={5} xl={4}>
            <Steps direction="vertical" size="small" current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </Col>

          <Col xs={24} sm={0} md={0} lg={0} xl={0}>
            <div className={classes.responsiveStepper}>
              <div className={classes.responsiveStep}>
                <Avatar size='small' style={{ color: '#fff', backgroundColor: '#1890ff' }} >{current + 1}</Avatar>
                <Typography>{steps[current].title}</Typography>
              </div>

              {
                current < steps.length - 1 && (
                  <div className={classes.responsiveStep}>
                  <Avatar size='small' style={{ color: 'rgba(0, 0, 0, 0.25)', backgroundColor: '#fff', border: '1px rgba(0, 0, 0, 0.25) solid' }} >{current + 2}</Avatar>
                  <Typography>{steps[current + 1].title}</Typography>
                </div>
                )
              }

            </div>
          </Col>

          <Col xs={24} sm={17} md={19} lg={19} xl={20}>

            <div className="steps-content">
              {current === 0
                ? <Typography>
                  <Typography.Title  >Aile Oluşturma Sihirbazı</Typography.Title>
                  <Typography.Paragraph>
                    Aile Oluşturma Sihiarbazına hoş geldiniz. Bu Sihirbazı kullnarak aile bilgilerini oluşutrabilirsiniz.
                  </Typography.Paragraph>
                </Typography> : null}

              <Form form={mainForm} name='familyForm' onFinish={onFinish}>
                <Form.Item style={{ display: 'none' }} name={['family', '_id']}><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'familyName']}><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'startingDate']}><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'mother']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'father']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'householder']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'children']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'residences']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'overheads']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'pledges']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'otherHelpingAssociations']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'status']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'familyManagers']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'notes']} ><Input /></Form.Item>
                <Form.Item style={{ display: 'none' }} name={['family', 'attachments']} ><Input /></Form.Item>

                {current === steps.length - 1

                  ? <Row gutter={[4, 4]}>
                    <Col span={24}>
                      <Typography>
                        <Typography.Title >Tebrikler</Typography.Title>
                        <Typography.Paragraph>
                          Sihirbazın sonuna geldiniz. Aileyi kaydetmek için lütfen göndere tıklayın.
                        </Typography.Paragraph>
                      </Typography>
                    </Col>
                    <Col span={12}>
                      <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                    </Col>
                    <Col span={12}>
                      <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Gönder </Button>
                    </Col>
                  </Row>
                  : null}
              </Form>

              {steps[current].content}
            </div>

          </Col>
        </Row>

      </Form.Provider>
      <Modal
        title="Modal"
        visible={confirmVisible}
        onOk={continueForm}
        onCancel={startOver}
        okText="Devam Et"
        cancelText="Sıfırdan Başla"
      >
        <p>En son Aile ekleme işlemi yarım kalmış. Kaldığınz yerden devam etmek ister misiniz ?</p>
      </Modal>

    </>
  )
}

export default FamilyFormProvider
