import { put, takeLatest, call } from 'redux-saga/effects'

import { GetAccounts } from '../../../services/accountsService'

import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_ERROR
}
  from '../../actions/accounts'

function * getAccountsSaga (action) {
  try {
    const data =  yield call(GetAccounts, action.payload)
    yield put({
      type: GET_ACCOUNTS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_ACCOUNTS_ERROR, payload: error
    })
  }
}

export function * getAccountsSagaWatcher () {
  yield takeLatest(GET_ACCOUNTS, getAccountsSaga)
}
