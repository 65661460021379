import * as Actions from '../../actions/config'

const serverconfig = JSON.parse(localStorage.getItem('serverconfig'))

const initialState = serverconfig || {
  isLoading: false,
  error: false
}

const saveToLocalStorage = (serverconfig) => {
  localStorage.setItem('serverconfig', JSON.stringify(serverconfig))
  return serverconfig
}

const config = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CONFIG: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_CONFIG_SUCCESS: {
      return saveToLocalStorage({
        ...action.payload,
        isLoading: false,
        error: false
      })
    }
    case Actions.GET_CONFIG_ERROR: {
      return {
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default config
