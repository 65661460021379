import { put, takeLatest, call } from 'redux-saga/effects'

import { GetFamily } from '../../../services/familiesService'

import {
  GET_FAMILY,
  GET_FAMILY_SUCCESS,
  GET_FAMILY_ERROR
}
  from '../../actions/families'

function * getFamilySaga (action) {
  try {
    const data =  yield call(GetFamily, action.payload)
    yield put({
      type: GET_FAMILY_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_FAMILY_ERROR, payload: error
    })
  }
}

export function * getFamilySagaWatcher () {
  yield takeLatest(GET_FAMILY, getFamilySaga)
}
