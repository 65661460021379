import { combineReducers } from 'redux'

import account from './account.reducer'
import balances from './account_balances.reducer'
import list from './accounts.reducer'

const accounts = combineReducers({
  account,
  list,
  balances
})

export default accounts
