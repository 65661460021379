import React, { useState, useEffect } from 'react'

import {
  DeleteOutlined,
  UserOutlined,
  EditOutlined
} from '@ant-design/icons'
import { Form, Input, Button, Avatar, Divider, Modal, Row, Col, Typography, Tag } from 'antd'

import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'

import { firebaseAuth } from '../../../../firebaseApp'
import { AddParentToFamily, RemoveParentFromFamily, UpdateFamilyContact } from '../../../../store/actions'
import { useResetFormOnCloseModal } from '../../../../utils/customHooks'

import ContactForm from './ContactForm'

const useStyles = createUseStyles({
  modalDialog: {
    maxWidth: '100%',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0
  },
  modalContent: {
    overflowY: 'scroll', overflowX: 'hidden', maxHeight: 500, height: 'calc(100vh - 150px)'
  }
})

const ModalForm = ({ fieldName, mainForm, title, visible, hide, validateMessages, updateParentData }) => {
  const classes                           = useStyles()
  const [modalForm]                       = Form.useForm()
  const defaultInitialValues              = {
    gender: fieldName === 'mother' ? 'f' : 'm'
  }
  const [initialValues, setInitialValues] = useState(defaultInitialValues)

  modalForm.getInternalHooks('RC_FORM_INTERNAL_HOOKS')  // Not good practice
  useResetFormOnCloseModal({
    form: modalForm,
    visible
  })

  const onOk     = () => {
    if (updateParentData) {
      updateParentData(fieldName, modalForm.getFieldsValue())
      modalForm.resetFields()
    } else modalForm.submit()
  }
  const onCancel = () => {
    hide(fieldName)
    modalForm.resetFields()
  }
  useEffect(() => {
    const family = mainForm.getFieldValue('family') || {}
    setInitialValues(family[fieldName] || defaultInitialValues)
  }, [fieldName, mainForm, visible])

  return (
    <Modal title={title} visible={visible} onOk={onOk} width={650} onCancel={onCancel} maskClosable={false} className={isMobile ? classes.modalDialog : null}>
      <div className={classes.modalContent}>
        <ContactForm
          form={modalForm}
          fieldName={fieldName}
          initialValues={initialValues}
          visible={visible}
          validateMessages={validateMessages}
        />
      </div>
    </Modal>
  )
}

const ParentsForm = ({ mainForm, next, prev, validateMessages, mode, data, familyId }) => {
  const dispatch                                            = useDispatch()
  const [motherFormVisible, setMotherFormVisible]           = useState(false)
  const [fatherFormVisible, setFatherFormVisible]           = useState(false)
  const [householderFormVisible, setHouseholderFormVisible] = useState(false)
  const [hasMotherOrFather, setHasMotherOrFather]           = useState(false)
  const editMode                                            = mode === 'edit'
  const wizardMode                                          = (next && prev)
  const initialValues                                       = {
    family: {
      mother: null,
      father: null,
      householder: null
    }
  }

  useEffect(() => {
    updateHouseHolderDisabled()
  })

  const updateHouseHolderDisabled = () => {
    const family = mainForm.getFieldValue('family') || {}

    setHasMotherOrFather(family?.mother || family?.father || data?.mother || data?.father)
  }

  const showContactModal = (type) => {
    if (type === 'mother') { setMotherFormVisible(true) } else if (type === 'father') { setFatherFormVisible(true) } else if (type === 'householder') { setHouseholderFormVisible(true) }
  }

  const hideContactModal = (type) => {
    if (type === 'mother') { setMotherFormVisible(false) } else if (type === 'father') { setFatherFormVisible(false) } else if (type === 'householder') { setHouseholderFormVisible(false) }
  }

  const updateParentData = (parentType, parentData) => {
    firebaseAuth.currentUser.getIdToken(true).then(token => {
      if (parentData._id) {
        dispatch(UpdateFamilyContact({
          token,
          data: {
            contact: parentData,
            familyId
          }
        }))
      } else {
        dispatch(AddParentToFamily({
          token,
          data: {
            parentType,
            contact: parentData,
            familyId
          }
        }))
      }

      hideContactModal(parentType)
    })
  }

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { parentsStepForm } = forms
        const family              = parentsStepForm.getFieldValue('family') || {}

        if (name === 'motherForm') {
          parentsStepForm.setFieldsValue({
            family: { ...family, mother: values }
          })
          setMotherFormVisible(false)
          setHasMotherOrFather(true)
        } else if (name === 'fatherForm') {
          parentsStepForm.setFieldsValue({
            family: { ...family, father: values }
          })
          setFatherFormVisible(false)
          setHasMotherOrFather(true)
        } else if (name === 'householderForm') {
          parentsStepForm.setFieldsValue({
            family: { ...family, householder: values }
          })
          setHouseholderFormVisible(false)
        }
      }}
    >
      <Form form={mainForm} name='parentsStepForm'  validateMessages={validateMessages} defaultValue={initialValues} onFinish={next}>
        <Form.Item label='Anne' style={{ display: 'none' }} name={['family', 'mother']} ><Input type='hidden' /></Form.Item>
        <Form.Item label='Baba' style={{ display: 'none' }} name={['family', 'father']}><Input type='hidden' /></Form.Item>
        <Form.Item label='Vasiy' style={{ display: 'none' }} name={['family', 'householder']}><Input type='hidden' /></Form.Item>

        <ContactFormItem mainForm={mainForm} label='Anne' fieldName='mother' editMode={editMode} updateHouseHolderDisabled={updateHouseHolderDisabled} showModal={showContactModal} />
        <ContactFormItem mainForm={mainForm} label='Baba' fieldName='father' editMode={editMode} updateHouseHolderDisabled={updateHouseHolderDisabled} showModal={showContactModal} />

        {
          (editMode || !hasMotherOrFather)
            ? <>
              <Divider>Veya</Divider>
              <ContactFormItem mainForm={mainForm} label='Vasiy' fieldName='householder' editMode={editMode} updateHouseHolderDisabled={updateHouseHolderDisabled} showModal={showContactModal} disabled={hasMotherOrFather} />

              <Form.Item name={['family', 'householderRelation']} label='Yakınlık'>
                {editMode ? <Input disabled={hasMotherOrFather} /> : <PlainText />}
              </Form.Item>
            </>
            : null

        }

        {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
        {
          wizardMode
            ? <Row gutter={[4, 4]} align='bottom'>
              <Col span={12}>
                <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
              </Col>
            </Row>
            : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
        }

      </Form>
      <ModalForm fieldName='mother' mainForm={mainForm} title='Anne Bilgisi Ekle' visible={motherFormVisible} validateMessages={validateMessages} hide={hideContactModal} updateParentData={(mode === 'edit' && !wizardMode) ? updateParentData : null} />
      <ModalForm fieldName='father' mainForm={mainForm} title='Baba Bilgisi Ekle' visible={fatherFormVisible} validateMessages={validateMessages} hide={hideContactModal} updateParentData={(mode === 'edit' && !wizardMode) ? updateParentData : null}/>
      <ModalForm fieldName='householder' mainForm={mainForm} title='Vasiy Bilgisi Ekle' visible={householderFormVisible} validateMessages={validateMessages} hide={hideContactModal} updateParentData={(mode === 'edit' && !wizardMode) ? updateParentData : null}/>
    </Form.Provider>
  )
}
ParentsForm.defaultProps = {  mode: 'edit' }

const PlainText = ({ value }) => <Typography.Text>{value}</Typography.Text>

const ContactFormItem = ({ mainForm, label, fieldName, showModal, disabled, updateHouseHolderDisabled, editMode }) => {
  const dispatch = useDispatch()

  const handleRemoveParentFromFamily = (parentId, familyId) => {
    firebaseAuth.currentUser.getIdToken(true).then(token => {
      dispatch(RemoveParentFromFamily({
        token,
        data: {
          parentType: fieldName,
          parentId,
          familyId
        }
      }))
    })
  }

  return (
    <Form.Item
      label={label}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      shouldUpdate={(prevValues, curValues) => {
        return true
      }}
    >
      {() => {
        const family = mainForm.getFieldValue('family') || {}

        if (family[fieldName]) {
          const lightBg = { backgroundColor: family[fieldName].gender === 'm' ? '#d6e4ff' : '#ffd6e7' }
          const darkBg  = { backgroundColor: family[fieldName].gender === 'm' ? '#adc6ff' : '#ffadd2' }

          return (
            <div style={{ float: 'left' }}>

              <Tag className="contactNameTag" style={lightBg}>
                {family[fieldName].profilePhotoURL && family[fieldName].profilePhotoURL.url
                  ? <Avatar shape="square" src={family[fieldName].profilePhotoURL.url} />
                  : <Avatar shape="square" style={darkBg} icon={<UserOutlined />} />}
                {`${family[fieldName].firstname} ${family[fieldName].lastname || ''}`}
              </Tag>

              {editMode
                ? <>
                  <EditOutlined onClick={() => showModal(fieldName)} />
                  <DeleteOutlined onClick={() => {
                    handleRemoveParentFromFamily(family[fieldName]._id, family._id)
                    // family[fieldName] = null
                    // mainForm.setFieldsValue(Object.assign({}, family))
                    // updateHouseHolderDisabled && updateHouseHolderDisabled()
                  }} />
                </>
                : null
              }
            </div>
          )
        } else {
          return editMode
            ? <Button disabled={disabled} htmlType="button" style={{ margin: '0 8px', float: 'left' }} onClick={() => showModal(fieldName)} > {label} Ekle </Button>
            : null
        }
      }}
    </Form.Item>
  )
}

export default ParentsForm
