import React, { useEffect, useState } from 'react'

import { MailOutlined } from '@ant-design/icons'
import { Form, Input, Button, Row, Col, message, Divider, Typography } from 'antd'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { firebaseAuth } from '../../firebaseApp'

import logo from './../../logo.svg'

const ResetPasswordLayout = () => {
  const navigate = useNavigate()

  const [isSending, setIsSending] = useState(false)
  const auth                      = useSelector((state) => state.auth)

  useEffect(() => {
    if (auth.error && (auth.error.code === 'auth/user-not-found' || auth.error.code === 'auth/venue-not-found')) {
      message.error('Kullanıcı bulunamadı')
      return
    }

    if (auth.error && (auth.error.code === 'auth/venue-deactivated')) {
      message.error('Hesabınız kapatılmış. support@kolayyolla.com.tr üzerinden irtibata geçebilirsiniz')
      return
    }

    if (auth.error && auth.error.code === 'auth/too-many-requests') {
      message.error('Çok fazla sayıda hatalı giriş yaptınız. Lütfen daha sonra tekrar deneyin.')
      return
    }

    if (auth.error && auth.error.code === 'auth/wrong-password') {
      message.error('Hatalı Şifre')
      return
    }

    if (auth.error) { message.error('Bilinmeyen bir hata oluştu.') }
  }, [auth.error])

  const onFinish = values => {
    const { email } = values

    setIsSending(true)
    sendPasswordResetEmail(firebaseAuth, email).then(result => {
      message.success('Sıfırlama maili e-posta adresinize gönderildi')
      setIsSending(false)

      setTimeout(() => {
        navigate('/login')
      }, 2000)
    }).catch(err => {
      console.log(err)
      if (err.message === 'INVALID_EMAIL') { message.error('Geçersiz E-posta Adresi') } else { message.error('Bilinmeyen bir hata oluştu.') }
      setIsSending(false)
    })
  }
  return (
    <div >
      <Row style={{ height: '100vh' }} justify="space-around" align="middle" >
        <Col xs={20} sm={12} md={10} lg={6} xl={5} >
          <img alt='logo' src={logo} style={{ width: '100%' }} />
          <Typography.Title level={4}>Parola Sıfırla </Typography.Title>
          <Form
            name="normal_reset"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Lütfen e-posta adresinizi giriniz!'
                }
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isSending}>
                Gönder
              </Button>

              <Divider type='vertical'/>

              <Link to='/login' > Giriş Yap</Link>
            </Form.Item>

          </Form>

        </Col>
      </Row>
    </div>
  )
}
export default ResetPasswordLayout
