import React from 'react'

import { Descriptions } from 'antd'

import moment from 'moment'
import { useSelector } from 'react-redux'

import { getRoleName } from '../../../utils/helpers'

export default function ProfileForm () {
  const auth = useSelector((state) => state.auth)
  return (
    <Descriptions column={1} bordered>

    <Descriptions.Item label="Kullanıcı ID">{auth.data?.firebaseUid}</Descriptions.Item>
    <Descriptions.Item label="İsim Soyisim">{auth.data?.displayName}</Descriptions.Item>
    <Descriptions.Item label="E-Posta Adresi">{auth.data?.email}</Descriptions.Item>
    <Descriptions.Item label="Hesap Tipi">{getRoleName(auth.data?.role)}</Descriptions.Item>
    <Descriptions.Item label="Hesap Açılış Tarihi">{moment(auth.data?.createdAt).format('lll')}</Descriptions.Item>
  </Descriptions>
  )
}
