import React, { useState, useEffect } from 'react'

import * as L from 'leaflet'

import pinopint from '../images/pinpoint.svg'

import Map from './Map'
import './locationFinder.css'

export default function CoordinatesPicker ({ onChange, value, style }) {
  const [marker, setMarker]                 = useState(null)
  const [markerPosition, setMarkerPosition] = useState(value)

  const onMount = (point) => async map => {
    if (point?.coord?.lat) {
      if (!marker) {
        setMarker(() => {
          map.zoomControl.remove()
          map.panTo(point.coord)
          map.setZoom(15)

          const m = L.marker(point.coord, {
            icon: L.icon({
              iconUrl: pinopint,
              iconSize: [40, 40], // size of the icon
              iconAnchor: [22, 39]
            }),
            draggable: false
          })

          const cssIcon      = L.divIcon({
            className: 'css-icon',
            html: '<div class="gps_ring"></div>',
            iconSize: [22, 22]
          })
          const circleMarker = L.marker(point.coord, { icon: cssIcon }).addTo(map)

          map.on('move', function () {
            m.setLatLng(map.getCenter())
            circleMarker.setLatLng(map.getCenter())
          })
          // Dragend event of map for update marker position
          map.on('dragend', function (e) {
            const position = m.getLatLng()
            if (onChange) { onChange(position) }
          })
          m.addTo(map)
          return m
        })
      } else {
        setTimeout(() => {
          map.panTo(point.coord)
          marker.setLatLng(point.coord)
        }, 300)
      }
    }
  }

  useEffect(() => {
    if (value) { setMarkerPosition(value) }
  }, [value])

  const mapProps = {
    options: {
      center: markerPosition || { lat: 41.017719, lng: 28.996664 },
      zoom: 11
    },
    style

  }
  return <Map {...mapProps} onMount={onMount({ title: 'abc', coord: markerPosition })} />
}

CoordinatesPicker.defaultProps = {  style: { height: 150 }
}
