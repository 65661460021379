import React from 'react'

import {
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'
import { Form, Input, Button, DatePicker, Row, Col } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

import DraggerAttachmentFormItem from './DraggerAttachmentFormItem'

const HealthFormItem = (params) => {  return (<>

        <Form.List name={[params.root, 'illnesses']} >
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[8, 2]}>
                                <Col span={24} >
                                    <Form.Item
                                        label={index + 1 + '. Hastalık Adı'}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        name={[field.name, 'name']}
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder='Örn: Guatr' />
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item
                                        label='Tarih Aralığı'
                                        name={[field.name, 'start']} >
                                        <DatePicker locale={locale} placeholder='Başlangıç' format='DD.MM.YYYY' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} >
                                    <Form.Item name={[field.name, 'end']} style={{ marginTop: 40 }}>
                                        <DatePicker locale={locale} placeholder='Bitiş' format='DD.MM.YYYY' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={8} >
                                    <Form.Item
                                        label={'Doktor'}
                                        name={[field.name, 'doctor', 'name']}
                                    >
                                        <Input placeholder='Adı' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item name={[field.name, 'doctor', 'tel']} style={{ marginTop: 40 }}>
                                        <Input placeholder='Telefon' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item name={[field.name, 'doctor', 'email']} style={{ marginTop: 40 }} >
                                        <Input placeholder='E-Mail' />
                                    </Form.Item>
                                </Col>

                                <Col span={24} >
                                    <Form.Item label="Ekler" valuePropName='value'>
                                        <DraggerAttachmentFormItem
                                            text='Hastalık ile alakalı dökümanları bu formu kullanarak ekleyebilirsiniz.'
                                            hint='Örn. Reçete, Doktor Raporu vs...'
                                            root={[field.name, 'attachments']}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <MinusCircleOutlined style={{ marginBottom: 20, fontSize: 25 }}
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />   Hastalık Bilgisini Kaldır
                             </Col>
                            </Row>))}
                        <Form.Item span={24}>
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined />Hastalık Bilgisi Ekle
                            </Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>

        <Form.List name={[params.root, 'surgeries']} >
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[8, 2]}>
                                <Col span={24} >
                                    <Form.Item
                                        label={index + 1 + '. Ameliyat Adı'}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        name={[field.name, 'name']}
                                        style={{ marginBottom: 0 }}
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder='Örn: Bademcik Ameliyatı' />
                                    </Form.Item>
                                </Col>
                                <Col span={24} >
                                    <Form.Item
                                        label='Tarih'
                                        name={[field.name, 'Date']} >
                                        <DatePicker locale={locale} placeholder='Ameliyat Tarihi' format='DD.MM.YYYY' style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col span={24} >
                                    <Form.Item
                                        label='Hastane Adı'
                                        name={[field.name, 'hospital']} >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={8} >
                                    <Form.Item
                                        label={'Doktor'}
                                        name={[field.name, 'doctor', 'name']}
                                    >
                                        <Input placeholder='Adı' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item name={[field.name, 'doctor', 'tel']} style={{ marginTop: 40 }}>
                                        <Input placeholder='Telefon' />
                                    </Form.Item>
                                </Col>
                                <Col span={8} >
                                    <Form.Item name={[field.name, 'doctor', 'email']} style={{ marginTop: 40 }} >
                                        <Input placeholder='E-Mail' />
                                    </Form.Item>
                                </Col>

                                <Col span={24} >
                                    <Form.Item label="Ekler" >
                                        <DraggerAttachmentFormItem
                                            text='Ameliyat ile alakalı dökümanları bu formu kullanarak ekleyebilirsiniz.'
                                            hint='Örn. Epikriz Raprou, Röntgen filmi vs...'
                                            root={[field.name, 'attachments']}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <MinusCircleOutlined style={{ marginBottom: 20, fontSize: 25 }}
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />   Ameliyat Bilgisini Kaldır
                                </Col>
                            </Row>
                        ))}
                        <Form.Item span={24}>
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined />Ameliyat Ekle
                            </Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>

        <Form.List name={[params.root, 'alergies']}>
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key}>
                                <Col span={22}>
                                    <Form.Item
                                        label={index === 0 ? 'Bilinen Alerjiler' : ''}
                                        name={[field.name]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        key={field.key}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ marginTop: index === 0 ? 40 : 0 }}>
                                    <MinusCircleOutlined className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined />Alerji Bilgisi Ekle</Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>

        <Form.List name={[params.root, 'regularDrugs']}>
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key}>
                                <Col span={22}>
                                    <Form.Item
                                        label={index === 0 ? 'Sürekli Kullanılan İlaçlar' : ''}
                                        name={[field.name]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        key={field.key}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ marginTop: index === 0 ? 40 : 0 }}>
                                    <MinusCircleOutlined className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined />Sürekli Kullanılan İlaç Ekle</Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>

        <Form.List name={[params.root, 'chronicIllnesses']}>
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key}>
                                <Col span={22}>
                                    <Form.Item
                                        label={index === 0 ? 'Kronik Hastalıklar' : ''}
                                        name={[field.name]}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        key={field.key}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={2} style={{ marginTop: index === 0 ? 40 : 0 }}>
                                    <MinusCircleOutlined className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />
                                </Col>
                            </Row>

                        ))}
                        <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined />Kronik Hastalık Bilgisi Ekle</Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>
    </>

  )
}

export default HealthFormItem
