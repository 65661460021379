import React, { useState } from 'react'

import { Modal, Row, Col } from 'antd'

import AttachmentViewer from './AttachmentViewer'

export default function AttachmentsViewer ({ attachments }) {
  const [visible, setVisible]         = useState(false)
  const [title, setTitle]             = useState('')
  const [previewItem, setPreviewItem] = useState('')

  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  return (
        <Row gutter={[4, 4]}>
            {attachments.map((file, index) =>
                <Col key={index}>
                    <AttachmentViewer file={file} size={70} show={show} hide={hide} setTitle={setTitle} setPreviewItem={setPreviewItem} />
                </Col>
            )}

            <Modal
                visible={visible}
                title={title}
                footer={null}
                onCancel={hide}
            >
                {previewItem}
            </Modal>
        </Row>
  )
}
AttachmentsViewer.defaultProps = {  attachments: []
}
