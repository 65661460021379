import * as Actions from '../../actions/users'

const initialState = {
  data: [],
  selected: {
    data: null,
    isLoading: false,
    isLoaded: false,
    error: false
  },
  isLoading: false,
  isLoaded: false,
  error: false
}

const users = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USERS: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: false
      }
    }
    case Actions.GET_USERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isLoaded: true,
        error: false
      }
    }
    case Actions.GET_USERS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error
      }
    }

    case Actions.ADD_USER: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: false
      }
    }
    case Actions.ADD_USER_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false,
        isLoaded: true,
        error: false
      }
    }
    case Actions.ADD_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.error
      }
    }

    case Actions.GET_USER_BY_ID: {
      return {
        ...state,
        selected: {
          isLoading: true,
          isLoaded: false,
          error: false
        }
      }
    }
    case Actions.GET_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        selected: {
          data: action.payload,
          isLoading: false,
          isLoaded: true,
          error: false
        }

      }
    }
    case Actions.GET_USER_BY_ID_ERROR: {
      return {
        ...state,
        selected: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error
        }
      }
    }

    case Actions.GET_USER_BY_UID: {
      return {
        ...state,
        selected: {
          isLoading: true,
          isLoaded: false,
          error: false
        }
      }
    }
    case Actions.GET_USER_BY_UID_SUCCESS: {
      return {
        ...state,
        selected: {
          data: action.payload,
          isLoading: false,
          isLoaded: true,
          error: false
        }

      }
    }
    case Actions.GET_USER_BY_UID_ERROR: {
      return {
        ...state,
        selected: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error
        }
      }
    }
    case Actions.CHANGE_USER_EMAIL: {
      return {
        ...state,
        selected: {
          isLoading: true,
          isLoaded: false,
          error: false
        }
      }
    }
    case Actions.CHANGE_USER_EMAIL_SUCCESS: {
      const newData           = state.data
      const userIndex         = newData.docs.findIndex(u => u._id === action.payload._id)
      newData.docs[userIndex] = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          docs: newData.docs
        },
        selected: {
          data: action.payload,
          isLoading: false,
          isLoaded: true,
          error: false
        }

      }
    }
    case Actions.CHANGE_USER_EMAIL_ERROR: {
      return {
        ...state,
        selected: {
          data: {},
          isLoading: false,
          isLoaded: false,
          error: action.error
        }
      }
    }

    case Actions.UPDATE_USER: {
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: false
      }
    }
    case Actions.UPDATE_USER_SUCCESS: {
      const newData      = state.data
      const userIndex    = newData.findIndex(u => u._id === action.payload._id)
      newData[userIndex] = action.payload

      return {
        ...state,
        data: newData,
        isLoading: false,
        isUpdated: true,
        error: false
      }
    }
    case Actions.UPDATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isUpdated: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}

export default users
