/* eslint-disable default-case */
import React from 'react'

import { Breadcrumb } from 'antd'

import { Link, useLocation } from 'react-router-dom'

const breadcrumbNameMap = {
  '/participants': 'Katılımcılar',
  '/participants/add': 'Katılımcı Ekle',
  '/participants/update': 'Katılımcı Düzenle',
  '/participants/yearview': 'Yıllık Özet Tablo',
  '/participants/summary': 'Alacak Takip',

  '/transactions': 'Para Transferleri',
  '/transactions/add': 'Yeni Para Transferi Ekle',
  '/transactions/update': 'Para Transferi Düzenle',

  '/families': 'Aileler',
  '/families/add': 'Yeni Aile Ekle',
  '/families/update': 'Aile Düzenle',

  '/accounts': 'Cari Hesaplar',
  '/accounts/balances': 'Cari Mizanlar',

  '/profile': 'Profil Ayarları'

}

const Breadcrumbs = props => {
  const location     = useLocation()
  const pathSnippets = location.pathname.split('/').filter(i => i)

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    return (
            <Breadcrumb.Item key={url}>
                {url.indexOf('update') > -1 ? breadcrumbNameMap[url] : <Link to={url}>{breadcrumbNameMap[url]}</Link>}
            </Breadcrumb.Item>
    )
  })
  const breadcrumbItems = [
        <Breadcrumb.Item key="home">
            <Link to="/">Ana Sayfa</Link>
        </Breadcrumb.Item>
  ].concat(extraBreadcrumbItems)

  return (
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
  )
}
export default Breadcrumbs
