import React, { useEffect } from 'react'

import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col, Descriptions } from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { firebaseAuth } from '../../../../firebaseApp'
import { GetParticipantContactsNames } from '../../../../store/actions/contacts'
import FamilyManagersFormItem from '../FormItems/FamilyManagersFormItem'

const FamilyManagersForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, data } = params
  const editMode                                               = mode === 'edit'
  const wizardMode                                             = (next && prev)

  const lastManagerObj = (
    data &&  // if there is data
        data.familyManagers && // if data contains a familyManagers array
        data.familyManagers.filter(a => !Object.keys(a).includes('end')) // filter familyManagers that has an ending date
          .sort((a, b) => moment(a.start).diff(moment(b.start))) // sort the rest of the familyManagers by their staring dates
          .slice(-1)[0]) // get the last element of the array

  const dispatch                   = useDispatch()
  const participantsNames          = useSelector((state) => state.participants.names.data)
  const isParticipantsNamesLoading = useSelector((state) => state.participants.names.isLoading)

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetParticipantContactsNames({ token }))
    })
  }, [])

  const formItemLayout = index => {
    return {
      labelCol: wizardMode ? {
        xs: { span: 24 },
        sm: { span: 7 },
        md: { span: 4 },
        lg: { span: 4 },
        xl: { span: 3 }
      } : { span: 24 },
      wrapperCol: wizardMode ? {
        xs: { span: 24, offset: 0 },
        sm: { span: index !== 0 ? 18 : 24, offset: index !== 0 ? 7 : 0 },
        md: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        lg: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        xl: { span: index !== 0 ? 21 : 24, offset: index !== 0 ? 3 : 0 }
      } : { span: 24 }
    }
  }

  return editMode ? (
        <Form form={mainForm} name='familyManagersStepForm' validateMessages={validateMessages} onFinish={next} >

            <Form.List name={['family', 'familyManagers']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='Aile Sorumlusu Eklenmemiş' />
                              : fields.map((field, index) => (
                                    <Form.Item
                                        label={index === 0 ? 'Sorumlular' : ''}
                                        {...formItemLayout(index)}
                                        key={field.key}
                                    >
                                        <Card actions={[<DeleteOutlined key="delete" onClick={() => remove(field.name)} />]} >
                                            <FamilyManagersFormItem
                                                root={field.name}
                                                wizardMode={wizardMode}
                                                participantsNames={participantsNames}
                                                isParticipantsNamesLoading={isParticipantsNamesLoading} />
                                        </Card>

                                    </Form.Item>
                              ))}

                            {editMode
                              ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }}>
                                    <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                        <PlusOutlined />Aile Sorumlusu Ekle</Button>
                                </Form.Item>
                              : null}

                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>
  )
    :        <LastFamilyManagerViewer lastManagerObj={lastManagerObj} participantsNames={participantsNames} />
}

const LastFamilyManagerViewer = ({ lastManagerObj, participantsNames }) => {
  const lastManager = lastManagerObj && lastManagerObj.manager && participantsNames.filter(p => p._id === lastManagerObj.manager)[0]
  if (lastManager) {
    return <Descriptions size="small" bordered column={1}>
            <Descriptions.Item label="Sorumlu" >{lastManager.fullname}</Descriptions.Item>
            <Descriptions.Item label="Başlangıç" >{moment(lastManagerObj.start).format('DD.MM.YYYY')}</Descriptions.Item>
        </Descriptions>
  } else { return <Empty description='Aile Sorumlusu Eklenmemiş' /> }
}

FamilyManagersForm.defaultProps = {
  mode: 'edit'
}
export default FamilyManagersForm
