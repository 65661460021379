import React, { useState, useEffect, useMemo } from 'react'

import { Line } from '@ant-design/charts'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Select, Descriptions, Tabs, Spin, Row, Col, Typography, Tag, Space } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import { useSelector } from 'react-redux'

import 'moment/locale/tr'

import './css/yearview.css'

import { VirtualTable } from '../../../components/VirtualTable'

import { firebaseAuth } from '../../../firebaseApp'
import { GetYearViewTable } from '../../../services/contactsService'
import { formatMoney, moneyRenderer, s2v } from '../../../utils/helpers'

const { Option }  = Select
const { TabPane } = Tabs

const YearView = () => {
  moment.locale('tr')

  const serverConfig = useSelector((state) => state.serverConfig)
  const auth         = useSelector((state) => state.auth)

  const [data, setData]     = useState([])
  const [totals, setTotals] = useState({
    totalPledge: 0,
    totalBalance: 0,
    totalDonationTypes: serverConfig.transactions.donationTypes.reduce((r, a) => {
      r[s2v(a)] = 0
      return r
    }, {}),

    totalSumYear: 0,
    totalMonths: Array.from(Array(12).keys()).reduce((r, a) => {
      r[a + 1] = serverConfig.transactions.donationTypes.reduce((r, a) => {
        r[s2v(a)] = 0
        return r
      }, {})
      return r
    }, {})
  })
  const [cartData, setCartData]             = useState([])
  const [isLoading, setIsLoading]           = useState(false)
  const [selectedYear, setSelectedYear]     = useState(new Date().getFullYear())
  const [displayDetails, setDisplayDetails] = useState(false)

  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    setTotals((prev) => {
      return calculateTotals(data, selectedYear)
    })
  }, [selectedYear, data])

  useEffect(() => {
    setCartData((prev) => {
      return calculateCartData(data)
    })
  }, [data])

  const refreshData = () => {
    setIsLoading(true)

    firebaseAuth.currentUser.getIdToken().then(token => {
      GetYearViewTable({ token }).then(d => {
        setIsLoading(false)
        setData(d)
      })
    })
  }

  const columns = useMemo(() => {
    const cols = [
      { title: 'Kişi', width: 250, dataIndex: 'fullname', key: 'fullname', fixed: 'left' },
      {
        title: 'Aylık Taahüt',
        align: 'center',
        width: 125,
        dataIndex: ['pledges', selectedYear],
        key: ['pledges', selectedYear],
        render: moneyRenderer
      },
      {
        title: ((selectedYear - 1) + ' Bakiye'),
        align: 'center',
        width: 125,
        dataIndex: ['balances', selectedYear - 1],
        key: ['balances', selectedYear - 1],
        render: moneyRenderer
      },
      ...serverConfig.transactions.donationTypes.map(dt => {
        return {
          title: 'Toplam ' + dt,
          align: 'center',
          width: 125,
          dataIndex: ['transactions', selectedYear, 'sum' + dt],
          key: ['transactions', selectedYear, 'sum' + dt],
          render: moneyRenderer
        }
      }),

      {
        title: 'Toplam Ödeme',
        align: 'center',
        width: 125,
        dataIndex: ['transactions', selectedYear, 'sumYear'],
        key: ['transactions', selectedYear, 'sumYear'],
        render: moneyRenderer
      }
    ]

    for (let i = 1; i < 13; i++) {
      cols.push({
        title: moment(i, 'M').format('MMMM'),
        children: [
          ...serverConfig.transactions.donationTypes.map(dt => {
            return {
              title: dt,
              align: 'center',
              width: 125,
              className: i % 2 === 0 ? 'evenColumn' : 'oddColumn',
              dataIndex: ['transactions', selectedYear, i, dt],
              key: ['transactions', selectedYear, i, dt],
              render: moneyRenderer
            }
          })
        ]
      })
    }

    return cols
  }, [selectedYear])

  const calculateTotals = (d, year) => {
    const newTotals = {
      totalPledge: 0,
      totalBalance: 0,
      totalDonationTypes: serverConfig.transactions.donationTypes.reduce((r, a) => {
        r[s2v(a)] = 0
        return r
      }, {}),
      totalSumYear: 0,
      totalMonths: Array.from(Array(12).keys()).reduce((r, a) => {
        r[a + 1] = serverConfig.transactions.donationTypes.reduce((r, a) => {
          r[s2v(a)] = 0
          return r
        }, {})
        return r
      }, {})
    }
    d.forEach(({ pledges, balances, transactions }) => {
      newTotals.totalPledge  += (pledges[year] || 0)
      newTotals.totalBalance += (balances[year - 1] || 0)
      const transactionYear   = transactions[year]

      serverConfig.transactions.donationTypes.forEach(dt => {
        newTotals.totalDonationTypes[s2v(dt)] += transactionYear ? (transactionYear['sum' + dt] || 0) : 0
      })
      newTotals.totalSumYear += transactionYear ? (transactionYear.sumYear || 0) : 0
      for (let i = 1; i < 13; i++) {
        serverConfig.transactions.donationTypes.forEach(dt => {
          newTotals.totalMonths[i][s2v(dt)] += transactionYear ? transactionYear[i] ? (transactionYear[i][dt] || 0) : 0 : 0
        })
      }
    })
    return newTotals
  }

  const calculateCartData = (d) => {
    const hashData    = {}
    const resultArray = []
    const years       = Array.from(Array(new Date().getFullYear() - 2018 + 1).keys()).map(y => 2018 + y)

    // initialize data with years
    years.forEach(y => {
      hashData[y] =
      {
        Taahüt: 0,
        ...serverConfig.transactions.donationTypes.reduce((r, a) => {
          r[`${capitalize(s2v(a))} Toplamı`] = 0
          return r
        }, {}),
        'Yıl Toplamı': 0,
        ...Array.from(Array(12).keys()).reduce((r, a) => {
          r[`${moment.months(a - 1)} Toplamı`] = 0
          return r
        }, {})
      }
    })

    d.forEach(({ pledges, transactions }) => {
      years.forEach(year => {
        const transactionYear          = transactions[year]
        hashData[year]['Taahüt']      += (pledges[year] || 0)
        hashData[year]['Yıl Toplamı'] += transactionYear ? (transactionYear.sumYear || 0) : 0

        serverConfig.transactions.donationTypes.forEach(dt => {
          hashData[year][`${capitalize(s2v(dt))} Toplamı`] += transactionYear ? (transactionYear['sum' + dt] || 0) : 0
        })

        for (let i = 1; i < 13; i++) {
          serverConfig.transactions.donationTypes.forEach(dt => {
            hashData[year][`${moment.months(i - 1)} Toplamı`] += transactionYear ? transactionYear[i] ? (transactionYear[i][dt] || 0) : 0 : 0
          })
        }
      })
    })

    Object.keys(hashData).forEach(year => {
      const yearData = Object.keys(hashData[year])

      yearData.forEach(key => {
        resultArray.push({
          name: key,
          x: year,
          y: hashData[year][key]
        })
      })
    })
    return resultArray
  }

  const converTotalsToArray = () => {
    const arr = ['Toplam:']
    arr.push(totals.totalPledge)
    arr.push(totals.totalBalance)
    serverConfig.transactions.donationTypes.forEach(dt => {
      arr.push(totals.totalDonationTypes[s2v(dt)])
    })
    arr.push(totals.totalSumYear)
    Object.keys(totals.totalMonths).forEach(m => {
      serverConfig.transactions.donationTypes.forEach(dt => {
        arr.push(totals.totalMonths[m][s2v(dt)])
      })
    })
    return arr
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const config = {
    data: cartData,
    xField: 'x',
    yField: 'y',
    seriesField: 'name',
    legend: {
      flipPage: false,
      position: 'bottom',
      maxRow: 2,
      selected: {
        'Yıl Toplamı': true,
        Taahüt: false,
        ...serverConfig.transactions.donationTypes.reduce((r, a) => {
          r[`${capitalize(s2v(a))} Toplamı`] = false
          return r
        }, {}),
        ...Array.from(Array(12).keys()).reduce((r, a) => {
          r[`${moment.months(a - 1)} Toplamı`] = false
          return r
        }, {})
      }
    },
    label: {
      position: 'middle',

      formatter: (val) => {
        return formatMoney(val.y)
      }
    },
    lineStyle: {
      lineWidth: 3,
      strokeOpacity: 1
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000
      }
    }
  }

  return (
    <Spin tip="Lütfen Bekleyin..." spinning={isLoading}>
      <Tabs tabBarExtraContent={
        <Select defaultValue={selectedYear} onChange={(value) => { setSelectedYear(value) }}
          style={{ width: 120 }} allowClear>

          {/* + - 5 years of the current year */}
          {[...Array(11).keys()].map(x => { const year = x += (new Date().getFullYear() - 5); return <Option key={year} value={year}>{year}</Option> })}

        </Select>
      }>
        <TabPane tab="Özet Tablo" key="1">
          <Row gutter={[8, 8]}>
            <Col sm={24} lg={8}>
            <Typography.Title level={4}>Özet Tablo</Typography.Title>
            <Descriptions size='small' bordered style={{ maxWidth: '550px' }} column={1} >
            <Descriptions.Item label="GENEL TOPLAM">{numeral(totals.totalSumYear).format('0,0[.]00 $')}</Descriptions.Item>
            <Descriptions.Item label="Aylık Toplam Taahüt">{numeral(totals.totalPledge).format('0,0[.]00 $')}</Descriptions.Item>

            {
              serverConfig.transactions.donationTypes.map((dt, i) => <Descriptions.Item key={i} label={'Toplam ' + dt}>{numeral(totals.totalDonationTypes[s2v(dt)]).format('0,0[.]00 $')}</Descriptions.Item>)
            }
            {
              Object.entries(totals.totalMonths).map(month =>
                <Descriptions.Item key={month[0]} label={moment(month[0], 'M').format('MMMM') + ' Toplamı'}>

                  <Space direction='vertical' size="small">
                    <Tag color="geekblue" style={{ fontSize: 18, lineHeight: '32px' }} >
                      <Space size="large" direction='horizontal'>
                      {numeral(
                        serverConfig.transactions.donationTypes.reduce((r, a) => {
                          r += month[1][s2v(a)]
                          return r
                        }, 0)
                      ).format('0,0[.]00 $')}

                      {
                        displayDetails ? <ArrowUpOutlined onClick={() => setDisplayDetails(false)} /> : <ArrowDownOutlined onClick={() => setDisplayDetails(true)} />
                        }
                        </Space>
                  </Tag>

                    {
                      displayDetails && serverConfig.transactions.donationTypes.map((dt) => {
                        return <Tag key={dt} color="pink">{`${dt} : ${numeral(month[1][s2v(dt)]).format('0,0[.]00 $')}`}</Tag>
                      })
                    }
                    </Space>

                </Descriptions.Item>)
            }
          </Descriptions>

            </Col>
            <Col sm={24} lg={16}>
              <Typography.Title level={4}>Yıllara Göre Değişim Grafiği</Typography.Title>
              <Line  {...config} />
            </Col>
         </Row>
        </TabPane>

        {
          (auth.data.role === 'admin' || auth.data.role === 'adminViewer') && (
            <TabPane tab="Detay Tablo" key="2">

            <VirtualTable
              columns={columns}
              dataSource={data}
              rowKey='_id'
              size='small'
              scroll={{ x: 2500, y: window.innerHeight - 250 }}
              totals={converTotalsToArray()}
            />
          </TabPane>

          )
        }

      </Tabs>

    </Spin>)
}

export default YearView
