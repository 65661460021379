import React, { useState, useEffect } from 'react'

import {

  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col, Timeline, Tooltip, Modal } from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import AttachmentsViewer from '../../../../components/AttachmentsViewer'
import { firebaseAuth } from '../../../../firebaseApp'
import { GetParticipantContactsNames } from '../../../../store/actions/contacts'
import FamilyVisitsFormItem from '../FormItems/FamilyVisitsFormItem'

const { confirm } = Modal

const FamilyVisitsForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, setMode, data } = params
  const editMode                                                        = mode === 'edit'
  const addNewMode                                                      = mode === 'addNew'
  const wizardMode                                                      = (next && prev)
  const familyVisitsData                                                = (
    data &&  // if there is data
        data.familyVisits) || // return if contains a familyVisits array
        []

  const [visitIndexToEdit, setVisitIndexToEdit] = useState(undefined)

  const dispatch                   = useDispatch()
  const participantsNames          = useSelector((state) => state.participants.names.data)
  const isParticipantsNamesLoading = useSelector((state) => state.participants.names.isLoading)
  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetParticipantContactsNames({ token }))
    })
  }, [])

  useEffect(() => {
    if (typeof visitIndexToEdit !== 'undefined') { setMode('edit') }
  }, [visitIndexToEdit])

  useEffect(() => {
    if (mode === 'addNew') {
      const family = mainForm.getFieldValue('family')
      family.familyVisits.push({ date: moment() })

      mainForm.setFieldsValue({
        family
      })
    }
  }, [mode])

  const formItemLayout = index => {
    return {
      labelCol: wizardMode ? {
        xs: { span: 24 },
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 3 },
        xl: { span: 1 }
      } : { span: 24 },
      wrapperCol: wizardMode ? {
        xs: { span: 24, offset: 0 },
        sm: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        md: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        lg: { span: index !== 0 ? 21 : 24, offset: index !== 0 ? 3 : 0 },
        xl: { span: index !== 0 ? 23 : 24, offset: index !== 0 ? 1 : 0 }
      } : { span: 24 }
    }
  }
  return editMode || addNewMode ? (
        <Form form={mainForm} name='familyVisitsStepForm' validateMessages={validateMessages} onFinish={next} >
            <Form.List name={['family', 'familyVisits']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='Hiç Aile Ziyareti Eklenmemiş' />
                              : fields.map((field, index) => {
                                return (
                                        <Form.Item
                                            label={index === 0 && wizardMode ? 'Ziyaretler' : ''}
                                            {...formItemLayout(index)}
                                            key={field.key}
                                            style={{
                                              display:
                                                    !wizardMode &&
                                                        ((!addNewMode && index !== visitIndexToEdit) ||
                                                            (addNewMode && index !== fields.length - 1))
                                                      ? 'none' : null
                                            }}
                                        >
                                            <Card actions={[<DeleteOutlined key="delete" onClick={() => {
                                              if (wizardMode) { remove(field.name) } else {
                                                confirm({
                                                  title: 'Bu aile ziyaretini silmek istediğinizden emin misiniz?',
                                                  icon: <ExclamationCircleOutlined />,
                                                  content: 'Bu kayıt tamamen silinecektir',
                                                  okText: 'Evet',
                                                  okType: 'danger',
                                                  cancelText: 'Hayır',
                                                  onOk () {
                                                    const family = mainForm.getFieldValue('family')
                                                    family.familyVisits.splice(field.name, 1)
                                                    next({ values: { family } })
                                                    setVisitIndexToEdit(undefined)
                                                    setMode('display')
                                                  }
                                                })
                                              }
                                            }
                                            } />]} >
                                                <FamilyVisitsFormItem root={field.name} index={index} mainForm={mainForm} participantsNames={participantsNames} isParticipantsNamesLoading={isParticipantsNamesLoading} />
                                            </Card>

                                        </Form.Item>
                                )
                              })}

                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode || addNewMode ? <Button type="primary" htmlType='submit' onClick={() => { setVisitIndexToEdit(undefined) }} style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>
  )
    : <FamilyVisitsTimeline familyVisitsData={familyVisitsData} setVisitIndexToEdit={setVisitIndexToEdit} />
}

const FamilyVisitsTimeline    = ({ familyVisitsData, setVisitIndexToEdit }) => {
  if (familyVisitsData.length) {
    return <Timeline mode='left'>
            {familyVisitsData.sort((a, b) => moment(b.date).diff(moment(a.date))).map((v, index) =>
                <Timeline.Item key={index} label={moment(v.date).format('DD.MM.YYYY')}>
                    {v.text}
                    <Tooltip title='Düzenle' > <EditOutlined style={{ cursor: 'pointer' }} onClick={() => setVisitIndexToEdit(index)} /></Tooltip>
                    <AttachmentsViewer attachments={v.attachments} />
                </Timeline.Item>
            )}

        </Timeline>
  } else { return <Empty description='Hiç Aile Ziyareti Eklenmemiş' /> }
}
FamilyVisitsForm.defaultProps = {
  mode: 'edit'
}
export default FamilyVisitsForm
