import axios from 'axios'

import system from './config'

const GetParticipantContact = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/contacts/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetParticipantContacts = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetContacts = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts/all', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetParticipantContactsNames = ({ token }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts/nameList')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetYearViewTable = ({ token }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + '/contacts/yearview')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetSummaryTable = ({ token }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + '/contacts/summary')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const ExportSummaryTable = ({ token }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + '/contacts/export')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddParticipantContact = ({ token, contact }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts/add', contact)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const DeleteParticipantContact = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.delete(system.webService + `/contacts/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}
const DeleteParticipantContacts = ({ token, _ids }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts/bulkDelete', { _ids })
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateParticipantContact = ({ token, _id, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + `/contacts/update/${_id}`, data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateParticipantContacts = ({ token, _ids, contact }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/contacts/updateMany/', { _ids, contact })
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetParticipantContact,
  GetParticipantContacts,
  GetContacts,
  GetParticipantContactsNames,
  GetYearViewTable,
  GetSummaryTable,
  ExportSummaryTable,
  AddParticipantContact,
  DeleteParticipantContact,
  DeleteParticipantContacts,
  UpdateParticipantContact,
  UpdateParticipantContacts
}
