export const numeralConfig = {  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '₺'
  }
}

export const validateMessages = {  required: 'Bu alanı doldurunuz',
  types: {
    email: 'Lütfen geçerli bir mail adresi giriniz.',
    number: 'Lütfen geçerli bir rakkam giriniz!'
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Taahüt Tutarı ${min} dan daha az olamaz'
  }
}

export const jexcelText = {  noRecordsFound: 'Kayıt Bulunamadı',
  showingPage: 'Sayfa: {0} / {1}',
  show: 'Göster',
  entries: 'veriler',
  insertANewColumnBefore: 'Sola yeni sütün ekle',
  insertANewColumnAfter: 'Sağa yeni sütün ekle',
  deleteSelectedColumns: 'Seçilen sütünu sil',
  renameThisColumn: 'Bu sütünu yeniden adlandır',
  orderAscending: 'Artarak Sırala',
  orderDescending: 'Azalarak Sırala',
  insertANewRowBefore: 'Üste yeni satır ekle',
  insertANewRowAfter: 'Alta yeni satır ekle',
  deleteSelectedRows: 'Seçilen satırı sil',
  editComments: 'Yorumları düzenle',
  addComments: 'Yeni yorum ekle',
  comments: 'Yorumlar',
  clearComments: 'Yorumları Temizle',
  copy: 'Kopyala ...',
  paste: 'Yapıştır ...',
  saveAs: 'Farklı Kaydet ...',
  about: 'Hakkında',
  areYouSureToDeleteTheSelectedRows: 'Seçilen satırı silmek istediğinizden emin misiniz?',
  areYouSureToDeleteTheSelectedColumns: 'Seçilen sütünü silmek istediğinizden emin misiniz?',
  thisActionWillDestroyAnyExistingMergedCellsAreYouSure: 'Bu işlem daha önce birleştirilen tüm hücreleri ayrıştıracaktır. Devam etmek istediğinizden emin misiniz?',
  thisActionWillClearYourSearchResultsAreYouSure: 'Bu işlem daha arama sonuçlarınızı temizleyecektir. Devam etmek istediğinizden emin misiniz?',
  thereIsAConflictWithAnotherMergedCell: 'Daha önce birleştirilmiş bir hücre ile çakışma var',
  invalidMergeProperties: 'Geçersiz birleştirme parametreleri',
  cellAlreadyMerged: 'Hücre zaten birleştirilmiş',
  noCellsSelected: 'Hiç bir hücre seçili değil'
}
