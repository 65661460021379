import * as Actions from '../../actions/accounts'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getAccountBalancesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ACCOUNT_BALANCES: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_ACCOUNT_BALANCES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_ACCOUNT_BALANCES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default getAccountBalancesReducer
