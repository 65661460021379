
import moment from 'moment'
import numeral from 'numeral'

import { numeralConfig } from './globalVars'

if (numeral.locales.tr === undefined) { numeral.register('locale', 'tr', numeralConfig) }
numeral.locale('tr')

const ConvertStringToMoment = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      ConvertStringToMoment(obj[key])
    } else if (typeof obj[key] === 'string') {
      if (moment(obj[key], moment.ISO_8601, true).isValid()) { obj[key] = moment(obj[key]) }
    }
  })

  return obj
}

const ConvertMomentToString = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object' && !moment.isMoment(obj[key])) {
      ConvertMomentToString(obj[key])
    } else if (obj[key] && moment.isMoment(obj[key])) {
      obj[key] = moment(obj[key]).format()
    }
  })
  return obj
}

const DirectionUrl = coords => {
  if (coords) {
    // Detect type of device to prepare URL for map directions
    switch (true) {
      case (/ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())):
        return `https://maps.google.com/?saddr=Current+Location&daddr=${coords.lat},${coords.lng}`
      case (/windows phone 7/i.test(navigator.userAgent.toLowerCase())):
        return `maps:${coords.lat},${coords.lng}`
      case (/windows phone 8/i.test(navigator.userAgent.toLowerCase())):
        return `ms-drive-to:?destination.latitude=${coords.lat}&destination.longitude=${coords.lng}`
      case (/android/i.test(navigator.userAgent.toLowerCase())):
        return `google.navigation:q=${coords.lat},${coords.lng}`
      case (/blackberry/i.test(navigator.userAgent.toLowerCase())):
        return `javascript:blackberry.launch.newMap({'nav_end':{'latitude':${coords.lat},'longitude':${coords.lng}}})`
      default:
        return `https://maps.google.com?daddr=loc:${coords.lat},${coords.lng}`
    }
  }
}

const isEqual = (a, b) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

const makeid = length => {
  let text       = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}
const zeroPad = (num, places) => String(num).padStart(places, '0')

const s2v = str => {
  const translateRe = /[öüçğşıÖÜÇĞŞİ]/g
  const translate   = {
    ö: 'o',
    ü: 'u',
    ç: 'c',
    ğ: 'g',
    ş: 's',
    ı: 'i',
    Ö: 'O',
    Ü: 'U',
    Ç: 'C',
    Ğ: 'G',
    Ş: 'S',
    İ: 'I'
  }
  const strArr      = str.replace(translateRe, match => translate[match]).toLowerCase().split(' ')
  const arr2        = strArr.slice(1).map(s => s.charAt(0).toUpperCase() + s.slice(1))

  return strArr[0] + arr2.join('')
}

const formatMoney   = (text) => numeral(text).format('0,0[.]00 $')
const moneyRenderer = (text, record) => text ? numeral(text).format('0,0[.]00 $') : null

const getRoleName = (role) => {
  switch (role) {
    case 'admin':
      return 'Yönetici'
    case 'familyManager':
      return 'Aile Sorumlusu'
    case 'participant':
      return 'Katılımcı'
    case 'family':
      return 'Aile Yöneticisi'
    case 'adminViewer':
      return 'Yönetici (Görüntüleyen)'
    default:
      return 'Kullanıcı'
  }
}

export {
  ConvertStringToMoment,
  ConvertMomentToString,
  DirectionUrl,
  isEqual,
  makeid,
  zeroPad,
  formatMoney,
  s2v,
  moneyRenderer,
  getRoleName
}
