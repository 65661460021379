import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateFamilyContact } from '../../../services/familiesService'

import {
  UPDATE_FAMILY_CONTACT,
  UPDATE_FAMILY_CONTACT_SUCCESS,
  UPDATE_FAMILY_CONTACT_ERROR
}
  from '../../actions/families'

function * updateFamilyContactSaga (action) {
  try {
    const data =  yield call(UpdateFamilyContact, action.payload)
    yield put({
      type: UPDATE_FAMILY_CONTACT_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: UPDATE_FAMILY_CONTACT_ERROR, payload: error
    })
  }
}

export function * updateFamilyContactSagaWatcher () {
  yield takeLatest(UPDATE_FAMILY_CONTACT, updateFamilyContactSaga)
}
