import moment from 'moment'

import * as Actions from '../../actions/transactions'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getTransactionsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_TRANSACTIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.GET_TRANSACTIONS_OFPARTICIPANTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_TRANSACTIONS_OFPARTICIPANTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_TRANSACTIONS_OFPARTICIPANTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.ADD_TRANSACTION: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.ADD_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: [...action.payload, ...state.data],
        isLoading: false,
        error: false
      }
    }
    case Actions.ADD_TRANSACTION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.DELETE_TRANSACTION: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(t => t.groupCode !== action.payload),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_TRANSACTION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.DELETE_TRANSACTIONS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(t => !action.payload.includes(t.groupCode)),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_TRANSACTIONS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.UPDATE_TRANSACTION: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.UPDATE_TRANSACTION_SUCCESS: {
      // first find index of the transaction that will be replaced
      const index = state.data.findIndex(p => p.groupCode === action.payload.oldGroupCode)

      // delete all the transactions with the oldGroup Code
      state.data = state.data.filter(t => t.groupCode !== action.payload.oldGroupCode)

      // insert new data into old index
      state.data.splice(index, 0, ...action.payload.newData)

      return {
        ...state,
        data: state.data.sort((a, b) => moment(b.date).diff(moment(a.date))),
        isLoading: false,
        error: false
      }
    }
    case Actions.UPDATE_TRANSACTION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default getTransactionsReducer
