import React, { useState, useEffect } from 'react'

import { FileExcelOutlined } from '@ant-design/icons'
import { Table, Tag,  Button,  Popconfirm, message } from 'antd'

import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation, Navigate } from 'react-router-dom'

import FormDrawer from '../../../components/FormDrawer'
import { firebaseAuth } from '../../../firebaseApp'
import { DeleteTransactions } from '../../../services/transactionsService'
import { GetTransactions } from '../../../store/actions/transactions'

import exportExcel from '../../../utils/exportExcel'
import { formatMoney } from '../../../utils/helpers'

import TransactionForm from './TransactionForm'

const TransactionsList = () => {
  const transactions                      = useSelector((state) => state.transactions.list.data)
  const isLoading                         = useSelector((state) => state.transactions.list.isLoading)
  const auth                              = useSelector((state) => state.auth)
  const dispatch                          = useDispatch()
  const navigate                          = useNavigate()
  const location                          = useLocation()
  const { _id }                           = useParams()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const transactionType                   = location.pathname.includes('contribution') ? 'contribution' : 'donation'

  const showDrawer = _id => setDrawerVisible(true)
  const hideDrawer = () => {
    navigate(location.pathname.replace(_id, ''))
    setDrawerVisible(false)
  }

  // Table States
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20
  })

  const columns = (mode) =>  [
    {
      title: 'Gönderen',
      dataIndex: ['source', 'name'],
      key: ['source', 'name'],
      render: (text, record, index) => {
        if (record.isSummaryRow) return ''
        if (mode === 'export') return record.source.name

        const obj       = {
          children: <Tag className='nameTag' color="pink" onClick={() => { navigate(`/transactions/${transactionType}/${record.groupCode}`) }}>{text}</Tag>,
          props: {}
        }
        const filterted = transactions.docs.filter(d => d.groupCode === record.groupCode)
        let curIndex    = -1
        if (filterted.length > 1) {
          for (let i = 0; i < filterted.length; i++) {
            if (filterted[i]._id === record._id) {
              curIndex = i
              break
            }
          }
          obj.props.rowSpan = curIndex === 0 ? filterted.length : 0
          obj.props.colSpan = curIndex === 0 ? 1 : 0
        }

        return obj
      },
      sorter: true
    },
    {
      title: 'Tarih',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (text, record, index) => {
        if (record.isSummaryRow) return ''
        if (mode === 'export') return  moment(record.date).format('DD.MM.YYYY')

        const obj       = {
          children: moment(record.date).format('DD.MM.YYYY'),
          props: { rowSpan: transactions.docs.filter(d => d.groupCode === record.groupCode).length }
        }
        const filterted = transactions.docs.filter(d => d.groupCode === record.groupCode)
        let curIndex    = -1
        if (filterted.length > 1) {
          for (let i = 0; i < filterted.length; i++) {
            if (filterted[i]._id === record._id) {
              curIndex = i
              break
            }
          }
          obj.props.rowSpan = curIndex === 0 ? filterted.length : 0
          obj.props.colSpan = curIndex === 0 ? 1 : 0
        }

        return obj
      }
    },
    {
      title: 'Tutar',
      dataIndex: 'destinationTotal',
      key: 'destinationTotal',
      render: (text, record) => {
        if (record.isSummaryRow) return formatMoney(transactions.docs.reduce((acc, cur) => acc + cur.destinationTotal, 0))

        return formatMoney(text)
      }
    },
    {
      title: 'Bağış Türü',
      dataIndex: 'donationType',
      key: 'donationType',
      render: (text, record) => {
        if (record.isSummaryRow) return ''
        return text
      }
    },
    {
      title: 'Açıklama',
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => {
        if (record.isSummaryRow) return ''
        return text
      }
    },
    {
      title: 'Ödeme Yapılan Hesap',
      dataIndex: ['destination', 'name'],
      key: ['destination', 'name'],
      render: (text, record) => {
        if (record.isSummaryRow) return ''
        if (mode === 'export') return record.destination.name
        return text
      }
    }
  ]
  // End of Table States

  useEffect(() => { if (_id) showDrawer() }, [_id])
  useEffect(() => { getTransactions() }, [transactionType])
  useEffect(() => {
    setPagination((prev) => {
      return {
        ...prev,
        pageSize: transactions.limit,
        current: transactions.page,
        total: transactions.totalDocs
      }
    })
  }, [transactions])

  const getTransactions = (data) => {
    firebaseAuth.currentUser.getIdToken().then((token) => {
      dispatch(
        GetTransactions({
          token,
          data: {
            ...data,
            transactionType
          }
        })
      )
    })
  }

  const handleTableChange = (pag, filters, sorter) => {
    getTransactions({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pag.current,
      limit: pag.pageSize,
      ...filters
    })
  }

  function ConfirmDelete (e) {
    firebaseAuth.currentUser.getIdToken().then(token => {
      DeleteTransactions({ token, _ids: selectedRowKeys }).then(() => {
        getTransactions()
      })
    })
    message.success('Seçilen Ödemeler Silindi')
  }

  function CancelDelete (e) {
    message.error('Click on No')
  }

  const exportToExcel = () => {
    const exportData = [...transactions.docs, { isSummaryRow: true }]
    exportExcel([{ headers: columns('export'), data: exportData, sheetName: 'Cari Ekstre' }], `${transactionType === 'donation' ? 'Toplanan'  : 'Yapılan'} Yardımlar`)
  }

  if (auth.data.role !== 'admin') return <Navigate to='/' />

  return (
    <>
      <div>
        <Button
          type="primary"
          disabled={auth.data.role !== 'admin'}
          onClick={showDrawer}>Yeni Ödeme {transactionType === 'donation' ? 'Girişi' : 'Çıkışı'}</Button>

        <Popconfirm
          title="Seçilen Transfer işlemlerini silmek istediğinizden emin misiniz?"
          onConfirm={ConfirmDelete}
          onCancel={CancelDelete}
          okText="Evet"
          cancelText="Hayır"
          disabled={auth.data.role !== 'admin'}
        >
          <Button disabled={auth.data.role !== 'admin'}>Seçilenleri Sil</Button>
        </Popconfirm>
        <Button onClick={exportToExcel}>
          <FileExcelOutlined /> XLS İndir
          </Button>
      </div>
      <Table
        dataSource={transactions.docs}
        columns={columns()}
        bordered
        size="small"
        rowKey='groupCode'
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
        rowSelection={
          auth.data.role === 'admin' ? {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedRowKeys(selectedRowKeys)
            }
          } : null}
      >
      </Table>
      <FormDrawer formElement={TransactionForm} formStates={{ transactionType, getTransactions }} visible={drawerVisible} hide={hideDrawer}
        disableSubmit={auth.data.role !== 'admin'} />
    </>
  )
}

export default TransactionsList
