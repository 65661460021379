import React, { useEffect } from 'react'

import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd'
import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import {  useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import { firebaseAuth } from '../../firebaseApp'

import logo from './../../logo.svg'

import './index.css'

const LoginForm = () => {
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (auth.error) { handleErrorMessages(auth.error) }
  }, [auth.error])

  const handleErrorMessages = (err) => {
    if (err && (err.code === 'auth/user-not-found' || err.code === 'auth/user-not-found')) {
      message.error('Kullanıcı bulunamadı')
    } else if (err && (err.code === 'auth/user-deactivated')) {
      message.error('Hesabınız yönetici tarafından kapatılmış. Lütfen sistem yöneticiniz ile görüşün')
    } else if (err && err.code === 'auth/too-many-requests') {
      message.error('Çok fazla sayıda hatalı giriş yaptınız. Lütfen daha sonra tekrar deneyin.')
    } else if (err && err.code === 'auth/wrong-password') {
      message.error('Hatalı Şifre')
    } else if (err && err.code === 'auth/access-denied') {
      message.error('Giriş yetkiniz bulunmuyor')
    } else if (err) message.error('Bilinmeyen bir hata oluştu.')
  }

  const onFinish = (values) => {
    const { email, password, rememmber } = values

    signInWithEmailAndPassword(firebaseAuth, email, password).then((user) => {
      if (rememmber) { setPersistence(firebaseAuth, rememmber ? browserSessionPersistence : browserSessionPersistence) }
    }).catch((error) => handleErrorMessages(error))
  }

  return (
        <div style={{ height: window.innerHeight }}>
            <Row justify="space-around" align="middle" style={{ height: window.innerHeight / 3 }} >
            </Row>
            <Row justify="space-around" align="middle" >
                <Col xs={20} sm={12} md={10} lg={6} xl={5}>
                    <img src={logo} alt='logo' className="login-form" />
                </Col>
            </Row>
            <Row justify="space-around" align="middle" >

                <Col xs={20} sm={12} md={10} lg={6} xl={5} >
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Lütfen email adresinizi giriniz!'
                              }
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Lütfen şifrenizi giriniz!'
                              }
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Beni Hatırla</Checkbox>
                            </Form.Item>

                            <Link to="/resetpassword"> Şifremi Unuttum</Link>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Giriş Yap
                    </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>

  )
}

export default LoginForm
