import { put, takeLatest, call } from 'redux-saga/effects'

import { GetStatistics } from '../../../services/statisticsService'

import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR
}
  from '../../actions/statistics'

function * getStatisticsSaga (action) {
  try {
    const data =  yield call(GetStatistics, action.payload)
    yield put({
      type: GET_STATISTICS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_STATISTICS_ERROR, payload: error
    })
  }
}

export function * getStatisticsSagaWatcher () {
  yield takeLatest(GET_STATISTICS, getStatisticsSaga)
}
