import { put, takeLatest, call } from 'redux-saga/effects'

import { SaveAccounts } from '../../../services/accountsService'

import {
  SAVE_ACCOUNTS,
  SAVE_ACCOUNTS_SUCCESS,
  SAVE_ACCOUNTS_ERROR
}
  from '../../actions/accounts'

function * saveAccountsSaga (action) {
  try {
    yield call(SaveAccounts, action.payload)
    yield put({
      type: SAVE_ACCOUNTS_SUCCESS,
      payload: action.payload.accounts
    })
  } catch (error) {
    yield put({
      type: SAVE_ACCOUNTS_ERROR, payload: error
    })
  }
}

export function * saveAccountsSagaWatcher () {
  yield takeLatest(SAVE_ACCOUNTS, saveAccountsSaga)
}
