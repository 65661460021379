import React from 'react'

import { Routes, Route } from 'react-router-dom'

import AccountDetails from './AccountDetails'
import TransactionsList from './TransactionsList'

const Transactions = () => {
  return (
    <Routes>

      <Route exact path="/" element={<AccountDetails />} />
      <Route path={'/donation/:_id'} element={<TransactionsList />} />
      <Route path={'/donation/'} element={<TransactionsList />} />
      <Route path={'/contribution/:_id'} element={<TransactionsList />} />
      <Route path={'/contribution/'} element={<TransactionsList />} />
    </Routes>
  )
}
export default Transactions
