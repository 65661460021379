/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'

import {
  MinusCircleOutlined,
  PlusOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import { Form, Input, Button, Row, Col, DatePicker, Select, InputNumber, Typography, Spin, Checkbox, Divider, notification } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IconFromStringComp } from '../../../components/IconFromStringComp'
import { firebaseAuth } from '../../../firebaseApp'
import { AddParticipantContact, UpdateParticipantContact, GetParticipantContact, GetParticipantContacts } from '../../../store/actions/contacts'
import { validateMessages } from '../../../utils/globalVars'

const { Title, Paragraph } = Typography
const key                  = 'notificationBoxKey'

const ParticipantForm = forwardRef(({ setIsSubmitting, hide }, ref) => {
  const auth                 = useSelector((state) => state.auth)
  const dispatch             = useDispatch()
  const [form]               = Form.useForm()
  const { _id }              = useParams()
  const initialData          = useSelector((state) => state.participants.participant.data)
  const isParticipantLoading = useSelector((state) => state.participants.participant.isLoading)
  const isListUpdating       = useSelector((state) => state.participants.list.isLoading)

  const [actionClicked, setActionClicked] = useState(false)
  const [isPerson, setIsPerson]           = useState(true)

  const initialValues = {
    contact: {
      isPerson: true,
      startingDate: moment(),
      displayAtSummary: true,
      pledgeCurrency: 'TRY',
      initialBalance: 0
    }
  }

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      _id
        ? dispatch(GetParticipantContact({ token, _id }))
        : dispatch(GetParticipantContact({ token, _id: null }))
    })
  }, [_id])
  useEffect(() => { initialData ? fillForm(initialData) : form.resetFields() }, [initialData])
  useEffect(() => {
    if (actionClicked && isListUpdating === false) {
      setActionClicked(false)

      firebaseAuth.currentUser.getIdToken().then(token => {
        dispatch(GetParticipantContacts({ token }))    // Update Main list
      })

      form.resetFields()
      notification.success({
        key,
        message: 'Kayıt Edildi...',
        placement: 'bottomRight',
        duration: 0.5,
        onClose: () => {
          setIsSubmitting(false)
        }
      })
    }
  }, [isListUpdating])

  const OnIsPersonChange = value => {
    if (!value) {
      form.setFieldsValue({
        contact: { displayAtSummary: false }
      })
    }
    setIsPerson(value)
  }

  useImperativeHandle(ref, () => ({
    submitForm () { form.submit() },
    resetForm () { form.resetFields() }
  }))

  const onFinish = ({ contact }) => {
    setActionClicked(true)
    contact = { ...contact, startingDate: contact.startingDate.format('YYYY-MM-DD') }
    if (contact.pledges) {
      contact.pledges = contact.pledges.reduce((obj, item) => {
        obj[item.year.format('YYYY')] = item.total
        return obj
      }, {})
    }
    notification.open({
      key,
      message: 'İşleniyor...',
      icon: <LoadingOutlined style={{ color: '#108ee9' }} />,
      placement: 'bottomRight'
    })

    setIsSubmitting(true)

    if (!initialData) {
      contact.createdBy = auth.data.contact.fullname

      firebaseAuth.currentUser.getIdToken().then(token => {
        dispatch(AddParticipantContact({ token, contact }))
      })
    } else {
      const updateData = {
        contact: {
          firstname: contact.firstname,
          middlename: contact.middlename,
          lastname: contact.lastname,
          email: contact.email,
          updatedBy: auth.data.contact.fullname,
          participation: {
            isParticipant: true,
            startingDate: contact.startingDate,
            isPerson: contact.isPerson,
            displayAtSummary: contact.displayAtSummary,
            pledges: (contact?.pledges && Object.keys(contact?.pledges).length > 0) ? contact.pledges : null
          }
        },
        account: {
          initialBalance: contact.initialBalance,
          pledgeCurrency: contact.pledgeCurrency
        }
      }

      firebaseAuth.currentUser.getIdToken().then(token => {
        dispatch(UpdateParticipantContact({ token, _id,  data: updateData }))
      })
      hide()
    }
  }

  const fillForm = (p) => {
    const constactData = {
      firstname: p.firstname,
      middlename: p.middlename,
      lastname: p.lastname,
      startingDate: moment(p.startingDate),
      isPerson: p.isPerson,
      displayAtSummary: p.displayAtSummary,
      email: p.emails.length > 0 ? p.emails[0].email : '',
      pledgeCurrency: p.pledgeCurrency,
      initialBalance: p.initialBalance || 0,
      account: p.account
    }
    if (p.pledges) { constactData.pledges = Object.keys(p.pledges).map(pledge => ({ year: moment(pledge, 'YYYY'), total: p.pledges[pledge] })) }

    form.setFieldsValue({
      contact: constactData
    })
  }

  const formItemLayout = {
    labelCol: {
      xl: { span: 6 },
      lg: { span: 7 },
      md: { span: 9 },
      sm: { span: 8 },
      xs: { span: 10 }

    },
    wrapperCol: {
      xl: { span: 18 },
      lg: { span: 17 },
      md: { span: 15 },
      sm: { span: 16 },
      xs: { span: 14 }
    }
  }

  return (
        <Spin tip="Lütfen Bekleyin..." spinning={isParticipantLoading}>

            <Typography>
                <Title  >{initialData ? 'Katılımcı Bilgileri' : 'Yeni Katılımcı'}</Title>
                <Paragraph>
                    Aşağıdaki formu kullanarak derneğe düzenli yardımda bulunmak isteyen katılımcı bilgisini {initialData ? 'düzenleyebilirsiniz' : 'oluşutrabilirsiniz'}.
            </Paragraph>
                <Row>
                    <Col span={24}>
                        <Form form={form}  {...formItemLayout} layout='horizontal' defaultValue={initialValues} onFinish={onFinish} validateMessages={validateMessages}>
                            <Form.Item style={{ display: 'none' }} name={['contact',  '_id']}> <Input hidden type='hidden' /></Form.Item>
                            <Form.Item
                                name={['contact', 'firstname']}
                                label="Adı"
                                rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'middlename']}
                                label="İkinci Adı">
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'lastname']}
                                label="Soyadı"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name={['contact', 'startingDate']} label="Başlangıç Tarihi" rules={[{ type: 'object', required: true, message: 'Lütfen sisteme giriş tarihini giriniz!' }]}>
                                <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item name={['contact', 'isPerson']} label="Katılımcı Tipi" rules={[{ required: true }]}>
                                <Select allowClear onChange={OnIsPersonChange}>
                                    <Select.Option value={true}>Kişi</Select.Option>
                                    <Select.Option value={false}>Kurum veya Diğer</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'email']}
                                label="Email"
                                rules={[{ type: 'email' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item wrapperCol={{ sm: { offset: 8 } }} name={['contact', 'displayAtSummary']} valuePropName="checked" >
                                <Checkbox defaultChecked={true} disabled={!isPerson}>Alacak Takip Tablosunda görüntüle</Checkbox>
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }} >
                                <Divider >Taahütler</Divider>
                            </Form.Item>

                            <Form.List name={['contact', 'pledges']} >
                                {(fields, { add, remove }) => {
                                  return (
                                        <div>
                                            {fields.map((field, index) => (
                                                <Row key={field.key} gutter={[4, 4]}>
                                                    <Col span={11} >
                                                        <Form.Item
                                                            name={[field.name, 'year']}
                                                            wrapperCol={{ span: 24 }}
                                                            fieldKey={[field.fieldKey, 'pledgeYear']}

                                                        >
                                                            <DatePicker locale={locale} picker="year" style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={11}  >
                                                        <Form.Item
                                                            name={[field.name, 'total']}
                                                            wrapperCol={{ span: 24 }}
                                                            fieldKey={[field.fieldKey, 'pledgeTotal']}
                                                            rules={[{ type: 'number' }]}
                                                        >
                                                            <InputNumber
                                                                placeholder="Tutar"
                                                                style={{ width: '100%' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}  >
                                                        <MinusCircleOutlined className="dynamic-delete-button"
                                                            onClick={() => {
                                                              remove(field.name)
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }} >
                                                <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                                    <PlusOutlined /> Taahüt Ekle
                                                </Button>
                                            </Form.Item>
                                        </div>
                                  )
                                }}
                            </Form.List>

                            <Form.Item wrapperCol={{ span: 24 }} >
                                <Divider >Hesap Ayarları</Divider>
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'pledgeCurrency']}
                                label="Taahüt Cinsi"
                            >
                                <Select showSearch placeholder="Hesap Seçin" optionFilterProp="children">
                                    <Select.Option value='TRY'><IconFromStringComp string='TRY' />TRY</Select.Option>
                                    <Select.Option value='USD'><IconFromStringComp string='USD' />USD</Select.Option>
                                    <Select.Option value='EUR'><IconFromStringComp string='EUR' />EUR</Select.Option>
                                    <Select.Option value='GBP'><IconFromStringComp string='GBP' />GBP</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'initialBalance']}
                                label="Başlangıç Bakiyesi"
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                name={['contact', 'account']}
                                label="Hesap Numarası"
                            >
                                <PlainText />

                            </Form.Item>

                            {/*
                            <Form.Item wrapperCol={{xs: {span: 24},sm: {span: 10,offset: 4}}}>
                                <Button type="primary" htmlType="submit">
                                    {initialData? 'Güncelle':'Kaydet'}
                                </Button>
                            </Form.Item> */}
                        </Form>
                    </Col>
                </Row>

            </Typography>
        </Spin>

  )
})

const PlainText = ({ value }) => <Typography.Text type='secondary'>{value || 'Hesap Numarası henüz belirlenmemiş'}</Typography.Text>

export default ParticipantForm
