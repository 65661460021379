import React, { useEffect, useRef, useState } from 'react'

import { PushpinOutlined } from '@ant-design/icons'
import { Form, Input, DatePicker, message, Button, Spin } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

import { createUseStyles } from 'react-jss'

import CoordinatesPicker from '../../../../components/CoordinatesPicker'
import { getGeoCode } from '../../../../services/geocodeService'
import { useForceUpdate, useScript } from '../../../../utils/customHooks'

const useStyles = createUseStyles({
  searchBox: {
    maxWidth: '100%',
    height: 'auto',
    minHeight: 32,
    lineHeight: 1.5715,
    verticalAlign: 'bottom',
    transition: 'all 0.3s, height 0s',
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px',
    border: '1px solid #d9d9d9',
    borderRadius: 2
  }
})

const ResidencesFormItem = (params) => {
  const { form, index, wizardMode } = params
  const classes                     = useStyles()
  const inputRef                    = useRef()
  const [isLoading, setIsLoading]   = useState(false)
  const forceUpdate                 = useForceUpdate()
  const status                      = useScript(
    'https://maps.google.com/maps/api/js?key=AIzaSyDmod8tun5xv6Omdv2a6d-W9Sirouto22M&libraries=places'
  )

  const formItemLayout = {
    labelCol: {
      span: wizardMode ? 5 : 24
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: wizardMode ? 19 : 24 }
    }
  }
  const mapFieldLayout = {
    wrapperCol:
      wizardMode
        ? {
            xs: { span: 24 },
            sm: { span: 19, offset: 5 }
          }
        : {
            span: 24
          }
  }

  useEffect(() => {
    if (status === 'ready') { init() }
  }, [status])

  const init = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current)
    autocomplete.setFields(['address_components', 'geometry', 'name', 'formatted_address'])

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()

      if (!place.geometry) {
        message.error("Girilen adrese ait bir konum bulunamadı: '" + place.name + "'")
      } else {
        const family                               = form.getFieldValue('family') || {}
        family.residences[index].address           = place.formatted_address
        family.residences[index].formatted_address = place.formatted_address
        family.residences[index].coords            = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        form.setFieldsValue({
          family
        })
        forceUpdate()
      }
    })
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const family = form.getFieldValue('family') || {}

        setIsLoading(true)
        getGeoCode({ latlng: position.coords.latitude + ',' + position.coords.longitude }).then(d => {
          setIsLoading(false)

          if (d.results.length > 0) {
            family.residences[index]                   = family.residences[index]?.coords || {}
            family.residences[index].coords            = { lat: position.coords.latitude, lng: position.coords.longitude }
            family.residences[index].address           = d.results[0].formatted_address
            family.residences[index].formatted_address = d.results[0].formatted_address
            form.setFieldsValue({
              family
            })
          }
        })
      },

      err => message.error(err.code === 1
        ? 'Error: Erişim Engellendi!'
        : err.code === 2 ? 'Error: Konum Bulunamadı!' : 'Error :' + err.message),
      { timeout: 60000 })
    } else {
      message.error('Üzgünüz, tarayıcınız konum bilgisi paylaşımını desteklemiyor')
    }
  }

  return (<>
    <Spin spinning={isLoading}>
      <Form.Item  {...formItemLayout} name={[params.root, 'address']} label="Adres" rules={[{ required: true }]}>
        <textarea className={classes.searchBox} ref={inputRef} />
      </Form.Item>
      <Form.Item>
        <Button icon={<PushpinOutlined />} block onClick={() => getLocation()}>
          Konumumu Kullan
        </Button>
      </Form.Item>

      <Form.Item {...formItemLayout} name={[params.root, 'start']} label="Başlangıç Tarihi">
        <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item {...formItemLayout} name={[params.root, 'end']} label="Bitiş Tarihi">
        <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item {...formItemLayout} name={[params.root, 'formatted_address']} label="Konum" ><Input.TextArea disabled /></Form.Item>

      <Form.Item {...mapFieldLayout} name={[params.root, 'coords']} valuePropName='value'>
        <CoordinatesPicker style={{ height: 220 }} onChange={(value) => {
          const family = form.getFieldValue('family')

          getGeoCode({ latlng: value.lat + ',' + value.lng }).then(d => {
            if (d.results.length > 0) {
              family.residences[index].formatted_address = d.results[0].formatted_address
              family.residences[index].address           = d.results[0].formatted_address
              family.residences[index].coords            = value
              form.setFieldsValue({
                family
              })
            }
          })
        }} />
      </Form.Item>

    </Spin>
  </>

  )
}
export default ResidencesFormItem
