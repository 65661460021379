import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateUser } from '../../../services/usersService'
import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR
}
  from '../../actions/users'

function * updateUserSaga (action) {
  try {
    const data = yield call(UpdateUser, action.payload)
    yield put({
      type: UPDATE_USER_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: UPDATE_USER_ERROR, error: error.data
    })
  }
}

export function * updateUserSagaWatcher () {
  yield takeLatest(UPDATE_USER, updateUserSaga)
}
