/* eslint-disable no-return-assign */
import React, { useState, useRef } from 'react'

import { Table } from 'antd'
import classNames from 'classnames'
import ResizeObserver from 'rc-resize-observer'
import { VariableSizeGrid as Grid } from 'react-window'

export const VirtualTable = (props) => {
  const { columns, scroll, className, totals } = props
  const [tableWidth, setTableWidth]            = useState(0)
  const widthColumnCount                       = columns.filter(({ width }) => !width).length
  const mergedColumns                          = columns.map(column => {
    if (column.width) return column
    return { ...column, width: Math.floor(tableWidth / widthColumnCount) }
  }).reduce((acc, val) => (val.children) ? [...acc, ...val.children] : acc = [...acc, val], [])

  const gridRef             = useRef()
  const mergedColumnsLength = mergedColumns.length

  const [connectObject] = useState(() => {
    const obj = {}
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: scrollLeft => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft
          })
        }
      }
    })
    return obj
  })

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject
    return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumnsLength}
                columnWidth={index => {
                  const { width } = mergedColumns[index]
                  return index === mergedColumnsLength - 1 ? width - scrollbarSize - 1 : width
                }}
                height={scroll.y}
                rowCount={rawData.length + 1}
                rowHeight={() => 48}
                width={tableWidth}
                onScroll={({ scrollLeft }) => { onScroll({ scrollLeft }) }}
            >
                {
                    ({ columnIndex, rowIndex, style }) => {
                      const rowClass          = rowIndex % 2 === 0 ? 'oddRow' : null
                      let tempRecord          = rawData[rowIndex]
                      const di                = mergedColumns[columnIndex].dataIndex
                      const renderer          = mergedColumns[columnIndex].render
                      const classNameOfColumn = mergedColumns[columnIndex].className

                      if (rowIndex !== rawData.length) {
                        let tempDataIndex
                        let result = ''

                        if (di) {
                          if (typeof di === 'string' || di instanceof String) { tempDataIndex = [di] } else { tempDataIndex = [...di] }

                          while (tempDataIndex.length > 0) {
                            const field = tempDataIndex.shift()
                            if (tempRecord) { tempRecord = tempRecord[field] } else { break }
                          }
                          result = tempRecord
                        }

                        return (
                                <div key={rowIndex + '_' + columnIndex}
                                    className={classNames('virtual-table-cell', classNameOfColumn, rowClass)} style={style}>
                                    {renderer ? renderer(result) : result}
                                </div>
                        )
                      } else {
                        return (
                                <div key={rowIndex + '_' + columnIndex} className={classNames('virtual-table-cell', 'footer')} style={style}>
                                    {renderer ? renderer(totals[columnIndex]) : totals[columnIndex]}
                                </div>
                        )
                      }
                    }

                }
            </Grid>
    )
  }

  return (
        <ResizeObserver onResize={({ width }) => { setTableWidth(width) }} >
            <Table
                {...props}
                className={'virtual-table ' + className}
                columns={columns}
                pagination={false}
                components={{
                  body: renderVirtualList
                }}

            />
        </ResizeObserver>
  )
}
