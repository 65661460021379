import * as Actions from '../../actions/contacts'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getParticipantsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PARTICIPANT_CONTACTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.ADD_PARTICIPANT_CONTACT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.ADD_PARTICIPANT_CONTACT_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false,
        error: false
      }
    }
    case Actions.ADD_PARTICIPANT_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACT_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(p => p._id !== action.payload),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(p => !action.payload.includes(p._id)),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_PARTICIPANT_CONTACTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACT_SUCCESS: {
      const index       = state.data.findIndex(p => p._id === action.payload._id)
      state.data[index] = action.payload
      return {
        ...state,
        isLoading: false,
        error: false
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACTS_SUCCESS: {
      let index
      action.payload._ids.forEach(element => {
        index             = state.data.findIndex(p => element === p._id)
        state.data[index] = { ...state.data[index], ...action.payload.participant }
      })

      return {
        ...state,
        data: [...state.data],
        isLoading: false,
        error: false
      }
    }
    case Actions.UPDATE_PARTICIPANT_CONTACTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default getParticipantsReducer
