import React from 'react'

import { Form, Input, Button, DatePicker, Row, Col } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import moment from 'moment'

const Step1 = (params) => {
  const { mainForm, validateMessages, next } = params

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 7 },
      lg: { span: 6 },
      xl: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 17 },
      lg: { span: 18 },
      xl: { span: 20 }
    }
  }
  const initialValues  = {
    family: {
      startingDate: moment(),
      status: 'onhold',
      children: []
    }
  }
  return (<>
        <Form form={mainForm} name='step1Form' validateMessages={validateMessages} defaultValue={initialValues} onFinish={next} >

            <Form.Item
                name={['family', 'familyName']}
                label="Aile Adı"
                {...formItemLayout}
                rules={[{ required: true }]}>
                <Input />
            </Form.Item>

            <Form.Item
                name={['family', 'startingDate']}
                label="Sisteme Giriş Tarihi"
                {...formItemLayout}
                rules={[{ required: true }]}>
                <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item style={{ display: 'none' }} name={['family', 'status']} ><Input /></Form.Item>

            <Row gutter={[4, 4]}>

                <Col span={24}>
                    <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                </Col>
            </Row>
        </Form>
    </>
  )
}

export default Step1
