import axios from 'axios'

import system from './config'

const GetUserById = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/users/id/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetUserByUid = ({ token, uid }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/users/uid/${uid}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetUsers = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/users', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddUser = ({ token, user }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/users/add', user)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateUser = ({ token, user }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + `/users/update/${user._id}`, user)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const ChangeEmail = ({ token, uid, newEmail }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/users/changeemail', { uid, newEmail })
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const CheckEmail = ({ email }) => {
  return new Promise((resolve, reject) => {
    axios.get(system.webService + `/users/checkemail/${email}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetUserById,
  GetUserByUid,
  GetUsers,
  AddUser,
  UpdateUser,
  ChangeEmail,
  CheckEmail
}
