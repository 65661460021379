import { put, takeLatest, call } from 'redux-saga/effects'

import { DeleteTransactions } from '../../../services/transactionsService'

import {
  DELETE_TRANSACTIONS,
  DELETE_TRANSACTIONS_SUCCESS,
  DELETE_TRANSACTIONS_ERROR
}
  from '../../actions/transactions'

function * deleteTransactionsSaga (action) {
  try {
    yield call(DeleteTransactions, action.payload)
    yield put({
      type: DELETE_TRANSACTIONS_SUCCESS,
      payload: action.payload._ids
    })
  } catch (error) {
    yield put({
      type: DELETE_TRANSACTIONS_ERROR, payload: error
    })
  }
}

export function * deleteTransactionsSagaWatcher () {
  yield takeLatest(DELETE_TRANSACTIONS, deleteTransactionsSaga)
}
