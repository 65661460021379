import { put, takeLatest, call } from 'redux-saga/effects'

import { AddTransaction } from '../../../services/transactionsService'

import {
  ADD_TRANSACTION,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_ERROR
}
  from '../../actions/transactions'

function * addTransactionSaga (action) {
  try {
    const data =  yield call(AddTransaction, action.payload)
    yield put({
      type: ADD_TRANSACTION_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: ADD_TRANSACTION_ERROR, payload: error
    })
  }
}

export function * addTransactionSagaWatcher () {
  yield takeLatest(ADD_TRANSACTION, addTransactionSaga)
}
