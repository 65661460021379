import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateFamily } from '../../../services/familiesService'

import {
  UPDATE_FAMILY,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_FAMILY_ERROR
}
  from '../../actions/families'

function * updateFamilySaga (action) {
  try {
    const data =  yield call(UpdateFamily, action.payload)
    yield put({
      type: UPDATE_FAMILY_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: UPDATE_FAMILY_ERROR, payload: error
    })
  }
}

export function * updateFamilySagaWatcher () {
  yield takeLatest(UPDATE_FAMILY, updateFamilySaga)
}
