/* eslint-disable no-undef */
import { initializeApp } from 'firebase/app'

import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getMessaging, isSupported } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'

let firebaseApp       = null
let firebaseDatabase  = null
let firebaseStorage   = null
let firebaseAuth      = null
let firebaseMessaging = null

// Initialize Firebase
firebaseApp = initializeApp({
  apiKey: 'AIzaSyDmod8tun5xv6Omdv2a6d-W9Sirouto22M',
  authDomain: 'kardes-payi.firebaseapp.com',
  databaseURL: 'https://kardes-payi.firebaseio.com',
  projectId: 'kardes-payi',
  storageBucket: 'kardes-payi.appspot.com',
  messagingSenderId: '86388551736',
  appId: '1:86388551736:web:f11dd1ef2c5579f40276cd',
  measurementId: 'G-Z413T87E1G'
})

firebaseAuth      = getAuth(firebaseApp)
firebaseDatabase  = getDatabase(firebaseApp)
firebaseStorage   = getStorage(firebaseApp)
firebaseMessaging = isSupported() ? getMessaging(firebaseApp) : null

export {
  firebaseApp,
  firebaseDatabase,
  firebaseStorage,
  firebaseAuth,
  firebaseMessaging
}
