import { put, takeLatest, call } from 'redux-saga/effects'

import { GetTransactionsOfParticipant } from '../../../services/transactionsService'

import {
  GET_TRANSACTIONS_OFPARTICIPANTS,
  GET_TRANSACTIONS_OFPARTICIPANTS_SUCCESS,
  GET_TRANSACTIONS_OFPARTICIPANTS_ERROR
}
  from '../../actions/transactions'

function * getTransactionsOfParticipantsSaga (action) {
  try {
    const data =  yield call(GetTransactionsOfParticipant, action.payload)
    yield put({
      type: GET_TRANSACTIONS_OFPARTICIPANTS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_TRANSACTIONS_OFPARTICIPANTS_ERROR, payload: error
    })
  }
}

export function * getTransactionsOfParticipantsSagaWatcher () {
  yield takeLatest(GET_TRANSACTIONS_OFPARTICIPANTS, getTransactionsOfParticipantsSaga)
}
