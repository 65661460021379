import { put, takeLatest, call } from 'redux-saga/effects'

import { GetParticipantContact } from '../../../services/contactsService'

import {
  GET_PARTICIPANT_CONTACT,
  GET_PARTICIPANT_CONTACT_SUCCESS,
  GET_PARTICIPANT_CONTACT_ERROR
}
  from '../../actions/contacts'

function * getParticipantContactSaga (action) {
  try {
    if (!action.payload._id) { yield put({ type: GET_PARTICIPANT_CONTACT_SUCCESS, payload: null }) } else {
      const data = yield call(GetParticipantContact, action.payload)
      yield put({
        type: GET_PARTICIPANT_CONTACT_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    yield put({
      type: GET_PARTICIPANT_CONTACT_ERROR, payload: error
    })
  }
}

export function * getParticipantContactSagaWatcher () {
  yield takeLatest(GET_PARTICIPANT_CONTACT, getParticipantContactSaga)
}
