import { put, takeLatest, call } from 'redux-saga/effects'

import { AddParticipantContact } from '../../../services/contactsService'

import {
  ADD_PARTICIPANT_CONTACT,
  ADD_PARTICIPANT_CONTACT_SUCCESS,
  ADD_PARTICIPANT_CONTACT_ERROR
}
  from '../../actions/contacts'

function * addParticipantContactSaga (action) {
  try {
    const data =  yield call(AddParticipantContact, action.payload)
    yield put({
      type: ADD_PARTICIPANT_CONTACT_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: ADD_PARTICIPANT_CONTACT_ERROR, payload: error
    })
  }
}

export function * addParticipantContactSagaWatcher () {
  yield takeLatest(ADD_PARTICIPANT_CONTACT, addParticipantContactSaga)
}
