
import React from 'react'

import * as L from 'leaflet'

import Map from '../../../components/Map'
import pinpoint from '../../../images/pinpoint.svg'
import { DirectionUrl } from '../../../utils/helpers'

const FamilyMap = ({ data }) => {
  const coordsOfFamilies = data.map(family => { return { familyName: family.familyName, coords: family.residences.coords } })
  const onMount = points => map => {
    points.forEach(point => {
      map.zoomControl.remove()

      if (point?.coords?.lat) {
        const marker = L.marker(point.coords, {
          icon: L.icon({
            iconUrl: pinpoint,
            iconSize: [40, 40], // size of the icon
            iconAnchor: [22, 39], // point of the icon which will correspond to marker's location
            popupAnchor: [0, -39]
          })
        }).addTo(map)
        marker.bindPopup(`
        <h4 style="font-weight=bold" >${point.familyName}</h4>
        <a type="button" target="_blank" href="${DirectionUrl(point.coords)}">
          <span>Navigasyonu Başlat</span>
        </a>
        <span style="margin-left: 8px, margin-right : 8px">|</span>
        <a type="button" target="_blank" onclick="(async function(e){
          if (navigator.share) {
            await navigator.share({
              title: 'Kardespayi.org',
              text  : '${point.familyName}',
              url : '${DirectionUrl(point.coords)}'
            })
          }
          return false;
      })(arguments[0]);return false;"><span>Paylaş</span></a>`)
      }
    })
  }

  const mapProps = {
    options: {
      center: { lat: 41.017719, lng: 28.996664 },
      zoom: 11
    },
    onMount: onMount(coordsOfFamilies)
  }

  return <Map  {...mapProps} style={{ height: '65vh' }} />
}

export default FamilyMap
