import { put, takeLatest, call } from 'redux-saga/effects'

import { GetAccountBalances } from '../../../services/accountsService'

import {
  GET_ACCOUNT_BALANCES,
  GET_ACCOUNT_BALANCES_SUCCESS,
  GET_ACCOUNT_BALANCES_ERROR
}
  from '../../actions/accounts'

function * getAccountBalancesSaga (action) {
  try {
    const data =  yield call(GetAccountBalances, action.payload)
    yield put({
      type: GET_ACCOUNT_BALANCES_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_ACCOUNT_BALANCES_ERROR, payload: error
    })
  }
}

export function * getAccountBalancesSagaWatcher () {
  yield takeLatest(GET_ACCOUNT_BALANCES, getAccountBalancesSaga)
}
