import { signOut } from 'firebase/auth'
import { put, takeLatest } from 'redux-saga/effects'

import { firebaseAuth } from '../../../firebaseApp'

import {
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR
}
  from '../../actions/auth'

function * logoutSaga (action) {
  try {
    signOut(firebaseAuth)

    yield put({ type: LOGOUT_SUCCESS, payload: null })
  } catch (error) {
    const errorMessage = { code: error.code, message: error.message }

    yield put({ type: LOGOUT_ERROR, error: errorMessage })
  }
}

export function * logoutWatcher () {
  yield takeLatest(LOGOUT, logoutSaga)
}
