import React, { useEffect, useState } from 'react'

import { SaveOutlined } from '@ant-design/icons'
import { Form, Input, Button,  Row, Col, message, Spin, Typography, Alert, Divider } from 'antd'
import {  signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import {  useSelector } from 'react-redux'

import { firebaseAuth } from '../../../firebaseApp'
import { validateMessages } from '../../../utils/globalVars'

const formItemLayout     = {
  labelCol: {
    xl: { span: 5 },
    lg: { span: 6 },
    md: { span: 7 },
    sm: { span: 8 },
    xs: { span: 24 }

  },
  wrapperCol: {
    xl: { span: 14 },
    lg: { span: 14 },
    md: { span: 13 },
    sm: { span: 12 },
    xs: { span: 24 }
  }
}
const submitButtonOffset = {
  xl: { offset: 3 },
  lg: { offset: 4 },
  md: { offset: 5 },
  sm: { offset: 6 },
  xs: { offset: 0 }
}
const ResetPasswordForm  = () => {
  const [form]                      = Form.useForm()
  const [isUpdating, setIsUpdating] = useState(false)
  const auth                        = useSelector(state => state.auth)

  useEffect(() => {
    form.setFieldsValue({
      email: auth.data.email
    })
  }, [auth.data])

  const onPasswordFormFinish = ({ currentPassword, password, password2 }) => {
    if (!currentPassword) {
      message.error('Lütfen Mevcut Şifrenizi Girin')
      return
    }

    if (!password) {
      message.error('Lütfen Yeni Şifrenizi Girin')
      return
    }

    if (!password2) {
      message.error('Lütfen Yeni Şifrenizi Tekrar Girin')
      return
    }

    if (password !== password2) {
      message.error('Şifreler eşleşmiyor')
      return
    }

    if (password.length < 6) {
      message.error('Lütfen 6 haneden uzun bir şifre belirleyin')
      return
    }

    setIsUpdating(true)
    signInWithEmailAndPassword(firebaseAuth, firebaseAuth.currentUser.email, currentPassword).then(user => {
      updatePassword(firebaseAuth.currentUser, password).then(user => {
        message.success('Şifreniz güncellendi')
        setIsUpdating(false)
      }).catch(error => {
        message.error(error.message)
      })
    }).catch(function (error) {
      if (error.code === 'auth/wrong-password') { message.error('Mevcut şifrenizi yanlış girdiniz. Mevcut şifrenizi hatırlamıyorsanız çıkış yapıp "Şifremi Unutttum" ekranından yeni şifrenizi oluşturabilirsiniz') }
      setIsUpdating(false)
    })
  }
  return    <Spin tip="Güncelleniyor..." spinning={isUpdating}>
  <div style={{ backgroundColor: '#fff', padding: '1.5rem' }}>

    <Typography.Title level={2}>
      Şifre Güncelle
    </Typography.Title>

    <Alert style={{ marginBottom: 20 }}
      description="Panel giriş şifrenizi güncelleyebilirsiniz"
    />

    <Row>
      <Col span={24}>
        <Form  {...formItemLayout} layout='horizontal' onFinish={onPasswordFormFinish} validateMessages={validateMessages}>

          <Form.Item
            name={['currentPassword']}
            label="Mevcut Şifre"
            rules={[{ required: true }]}
          >
            <Input type='password' />
          </Form.Item>

          <Form.Item
            name={['password']}
            label="Yeni Şifre"
            rules={[{ required: true }]}
          >
            <Input type='password' />
          </Form.Item>

          <Form.Item
            name={['password2']}
            label='Yeni Şifre Tekrar'
            rules={[{ required: true }]}
          >
            <Input type='password' />
          </Form.Item>

          <Divider />
          <Form.Item wrapperCol={{ ...submitButtonOffset }}>
            <Button type="primary" htmlType="submit" loading={isUpdating} icon={<SaveOutlined />}>
              Güncelle
            </Button>
          </Form.Item>

        </Form>
      </Col>
    </Row>
  </div >
</Spin>
}

export default ResetPasswordForm
