import React from 'react'

import { Form, DatePicker, InputNumber } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

const PledgesFormItem = (params) => {  const { wizardMode } = params
  const formItemLayout = {
    labelCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 3 }
    } : { span: 24 },
    wrapperCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 21 }
    } : { span: 24 }
  }

  return (<>

        <Form.Item {...formItemLayout} name={[params.root, 'year']} label="Sene">
            <DatePicker locale={locale} picker='year' format='YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item {...formItemLayout} name={[params.root, 'cash']} label="Nakit">
            <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item {...formItemLayout} name={[params.root, 'supply']} label="Erzak">
            <InputNumber style={{ width: '100%' }}/>
        </Form.Item>

    </>

  )
}
export default PledgesFormItem
