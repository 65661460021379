import * as Actions from '../../actions/families'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getFamiliesTableViewReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FAMILIES_TABLEVIEW: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_FAMILIES_TABLEVIEW_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_FAMILIES_TABLEVIEW_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    case Actions.DELETE_FAMILY: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_FAMILY_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(p => p._id !== action.payload._id),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_FAMILY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default getFamiliesTableViewReducer
