import React from 'react'

import { Form, Select, DatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import { useSelector } from 'react-redux'

const FamilyManagersFormItem = (params) => {
  const { wizardMode, root } = params

  const formItemLayout = {
    labelCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 5 }
    } : { span: 24 },
    wrapperCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 19 }
    } : { span: 24 }
  }

  const participantsNames          = useSelector((state) => state.participants.names.data)
  const isParticipantsNamesLoading = useSelector((state) => state.participants.names.isLoading)

  return (<>
        <Form.Item
            name={[root, 'manager']}
            label="Kişi"
            hasFeedback={isParticipantsNamesLoading}
            validateStatus="validating"
            rules={[{ required: true }]}
            {...formItemLayout}
        >
            <Select
                showSearch
                placeholder="Kişi Seçin"
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {
                  participantsNames.map((p, i) => <Select.Option key={i} value={p._id}>{p.fullname}</Select.Option>)
                }
            </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} name={[root, 'start']} label="Başlangıç Tarihi">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item {...formItemLayout} name={[root, 'end']} label="Bitiş Tarihi">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

    </>

  )
}
export default FamilyManagersFormItem
