import React from 'react'

import { Form, Input, Button,  Row, Col, Descriptions, Typography } from 'antd'

const BankForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, data } = params
  const editMode                                               = mode === 'edit'
  const wizardMode                                             = (next && prev)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 7 },
      lg: { span: 6 },
      xl: { span: 4 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 17 },
      lg: { span: 18 },
      xl: { span: 20 }
    }
  }

  return editMode ?  (<>
        <Form form={mainForm} name='bankForm' validateMessages={validateMessages} onFinish={next} >

            <Form.Item name={['family', 'bankAccount', 'accountName']} label="Hesap Adı"  {...formItemLayout}><Input /></Form.Item>
            <Form.Item name={['family', 'bankAccount', 'accountNo']} label="Hesap No"  {...formItemLayout}><Input /></Form.Item>
            <Form.Item name={['family', 'bankAccount', 'IBAN']} label="IBAN No"  {...formItemLayout} rules={[{ required: true }]}><Input /></Form.Item>
            <Form.Item name={['family', 'bankAccount', 'bankName']} label="Banka Adı"  {...formItemLayout}><Input /></Form.Item>
            <Form.Item name={['family', 'bankAccount', 'bankBranch']} label="Banka Şubesi"  {...formItemLayout}><Input /></Form.Item>

    {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
    {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>
    </>
  )
    : <BankInfoViewer data={data} />
}

const BankInfoViewer = ({ data }) => {
  const bankAccount = data?.bankAccount || {}

  return <Descriptions size="small" title="Banka Bilgileri" bordered column={1}>
    {bankAccount?.accountName ? <Descriptions.Item label="Hesap Adı">{bankAccount.accountName}</Descriptions.Item> : null}
    {bankAccount?.accountNo ? <Descriptions.Item label="Hesap No">{bankAccount.accountNo}</Descriptions.Item> : null}
    {bankAccount?.IBAN ? <Descriptions.Item label="IBAN No"><Typography.Paragraph copyable>{bankAccount.IBAN}</Typography.Paragraph> </Descriptions.Item> : null}
    {bankAccount?.bankName ? <Descriptions.Item label="Banka Adı">{bankAccount.bankName}</Descriptions.Item> : null}
    {bankAccount?.bankBranch ? <Descriptions.Item label="Banka Şubesi">{bankAccount.bankBranch}</Descriptions.Item> : null}
</Descriptions>
}

BankForm.defaultProps = {
  mode: 'edit'
}

export default BankForm
