/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'

import { onAuthStateChanged } from 'firebase/auth'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'

import { firebaseAuth } from './firebaseApp'

import LoginLayout from './layouts/Login'
import MainPageLayout from './layouts/Main'
import Accounts from './layouts/Main/Accounts'
import Dashboard from './layouts/Main/Dashboard'
import Families from './layouts/Main/Families'
import Participants from './layouts/Main/Participants'
import Profile from './layouts/Main/Profile'
import Transactions from './layouts/Main/Transactions'
import Users from './layouts/Main/Users'
import ResetPasswordLayout from './layouts/ResetPassword'

import { authStateChange } from './store/actions/auth'

const AuthRouter = () => {
  const auth = useSelector((state) => state.auth)
  // const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (user) => {
      dispatch(authStateChange({ user }))
    })
  }, [])

  // useEffect(() => {
  // window.gtag("event", "page_view", {
  //   page_path: location.pathname + location.search,
  // });
  // }, [location])

  return (
    auth.data ? <Routers auth={auth} /> : null
  )
}

const Routers = ({ auth }) => {
  return (
    <BrowserRouter >
          <Routes>
          <Route exact path="/" element={
            <RequireAuth conditions={[{ condition: !auth.isLoggedIn, redirectUrl: '/login' }]}>
              <MainPageLayout />
            </RequireAuth>} >

            <Route exact path="/" element={<Dashboard />} />
            <Route path="/participants/*" element={<Participants />} />
            <Route path="/transactions/*" element={<Transactions />} />
           <Route path="/families/*" element={<Families />} />
            <Route path="/accounts/*" element={<Accounts />} />
          <Route path="/users/*" element={<Users />} />
          <Route path="/profile/*" element={<Profile />} />
          </Route>

          <Route exact path="/login" element={<RequireAuth conditions={[{ condition: auth.isLoggedIn, redirectUrl: '/' }]} ><LoginLayout /></RequireAuth>} />
          <Route exact path="/resetpassword" element={<RequireAuth conditions={[{ condition: auth.isLoggedIn, redirectUrl: '/' }]}><ResetPasswordLayout /></RequireAuth>} />

      </Routes>
    </BrowserRouter>)
}

const RequireAuth = ({ conditions, children }) => {
  const location = useLocation()

  let url = ''
  for (let i = 0; i < conditions.length; i++) {
    if (conditions[i].condition) { url = conditions[i].redirectUrl }
  }
  return (url ? <Navigate from="*" to={url} state={{ from: location }} /> : children)
}

export default AuthRouter
