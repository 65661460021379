import axios from 'axios'

import system from './config'

const GetTransactions = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/transactions', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetTransaction = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/transactions/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetTransactionsOfParticipant = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/transactions/ofparticipant/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddTransaction = ({ token, transaction }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/transactions/add', transaction)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const DeleteTransaction = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.delete(system.webService + `/transactions/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}
const DeleteTransactions = ({ token, _ids }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/transactions/bulkDelete', _ids)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateTransaction = ({ token, transaction }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + `/transactions/update/${transaction._id}`, transaction)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetTransactions,
  GetTransaction,
  GetTransactionsOfParticipant,
  AddTransaction,
  DeleteTransaction,
  DeleteTransactions,
  UpdateTransaction
}
