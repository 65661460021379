import React, { useState, useEffect } from 'react'

import { CheckCircleTwoTone, DeleteOutlined, ExclamationCircleTwoTone, PauseCircleTwoTone, PlusOutlined, StopTwoTone } from '@ant-design/icons'
import { Card, Button, Row, Col, Typography, Spin, Form, Tooltip, Dropdown, Space, Popconfirm, Descriptions } from 'antd'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import './css/FamilyProfile.css'

import { firebaseAuth } from '../../../firebaseApp'
import { DeleteFamily, GetFamily, UpdateFamily } from '../../../store/actions/families'

import { validateMessages } from '../../../utils/globalVars'
import { ConvertStringToMoment, ConvertMomentToString } from '../../../utils/helpers'

import AssociationsForm from './Forms/AssociationsForm'
import BankForm from './Forms/BankForm'
import ChildrenForm from './Forms/ChildrenForm'
import FamilyManagersForm from './Forms/FamilyManagersForm'
import FamilyVisitsForm from './Forms/FamilyVisitsForm'
import NotesForm from './Forms/NotesForm'
import OverheadsForm from './Forms/OverheadsForm'
import ParentsForm from './Forms/ParentsForm'
import PledgesForm from './Forms/PledgesForm'
import ResidencesForm from './Forms/ResidencesForm'

const { Text, Title } = Typography

const FamilyProfile = () => {
  const dispatch         = useDispatch()
  const { _id }          = useParams()
  const navigate         = useNavigate()
  const data             = useSelector((state) => state.families.selectedFamily.data || {})
  const isFamilyLoading  = useSelector((state) => state.families.selectedFamily.isLoading)
  const isFamilyUpdating = useSelector((state) => state.families.list.isLoading)
  const [mainForm]       = Form.useForm()
  const [gutter]         = useState([16, 16])

  const auth = useSelector((state) => state.auth)

  const canEdit = auth.data.role === 'admin' || data?.familyManagers?.map(fm => fm.manager).includes(auth.data.contact._id)
  const isAdmin = auth.data.role === 'admin'

  // scroll to top on load
  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetFamily({ token, _id }))
    })
  }, [_id, isFamilyUpdating])

  useEffect(() => {
    const newData = ConvertStringToMoment(data)
    if (newData?.pledges?.length > 0) newData.pledges = newData.pledges.map(p => ({ ...p, year: moment({ year: p.year, month: 0, day: 1 }) }))
    if (newData?.overheads?.length > 0) newData.overheads = newData.overheads.map(p => ({ ...p, year: moment({ year: p.year, month: 0, day: 1 }) }))
    mainForm.setFieldsValue({ family: newData })
  }, [data])

  const colWidths = (colIndex) => {
    if (colIndex === 1 || colIndex === 3) {
      return {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 7
      }
    } else if (colIndex === 2) {
      return {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 10
      }
    }
  }

  const updateFamilyName = (value) => {
    const family = mainForm.getFieldValue('family') || {}

    mainForm.setFieldsValue({
      family: {
        ...family,
        familyName: value
      }
    })
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(UpdateFamily({
        token,
        _id,
        family: {
          familyName: value
        }
      }))
    })
  }

  const handleMenuClick = (key) => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(UpdateFamily({
        token,
        _id,
        family: {
          status: key
        }
      }))
    })
  }

  const handleDeleteFamily = () => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(DeleteFamily({
        token,
        _id
      }))
    })

    navigate('/families')
  }

  const statusData = status => {
    switch (status) {
      case 'active':
        return { icon: <CheckCircleTwoTone twoToneColor="#52c41a" />, text: 'Aktif' }
      case 'onhold':
        return { icon: <PauseCircleTwoTone twoToneColor="#d48806" />, text: 'Onay Bekliyor' }
      case 'suspended':
        return { icon: <ExclamationCircleTwoTone twoToneColor="#ff9c6e" />, text: 'Dondurulmuş' }
      case 'deactive':
        return { icon: <StopTwoTone twoToneColor="#cf1322" />, text: 'Çıkartılmış' }
      default:
        return null
    }
  }

  const StatusChangeButton = ({ status }) => {
    const items = [
      {
        key: '1',
        label: 'Aktifleştir',
        disabled: status === 'active',
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        onClick: () => {
          handleMenuClick('active')
        }
      },
      {
        key: '2',
        label: 'Dondur',
        disabled: status === 'suspended',
        icon: <ExclamationCircleTwoTone twoToneColor="#ff9c6e" />,
        onClick: () => {
          handleMenuClick('suspended')
        }
      },
      {
        key: '3',
        label: 'Çıkart',
        disabled: status === 'deactive',
        icon: <StopTwoTone twoToneColor="#cf1322" />,
        onClick: () => {
          handleMenuClick('deactive')
        }
      }

    ]
    const sData = statusData(status)
    return (
      <Space size="small">
        <Dropdown menu={{ items }} placement="bottomLeft" arrow >
          <Button type='text' style={{ color: '#fff', border: '1px solid #3c5d7f' }} icon={sData?.icon}>{sData?.text}</Button>
        </Dropdown>

        <Popconfirm
          title="Bu aileye ait tüm veriler silinecek. Onaylıyor musunuz?"
          onConfirm={() => handleDeleteFamily()}
          okText="Evet"
          cancelText="Hayır"
          placement='bottom'
        >
          <Button type="primary" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </Space>
    )
  }

  const houseHolderData = () => {
    if (data.mother) return data.mother

    if (data.father) return data.father

    if (data.houseHolder) return data.houseHolder
  }

  return (
    <Spin tip="Lütfen Bekleyin..." spinning={isFamilyLoading}>
      <div className='wrapper'>
        <div className='profileBanner'>
          <Row justify="center" align="middle" >
            <Col span={18} >
              <Title className='main' level={3} editable={{ onChange: str => updateFamilyName(str) }}>{data.familyName}</Title>
              <Text className="secondary" >Başlangıç : {moment(data.startingDate).format('DD.MM.YYYY')}</Text>
            </Col>
            <Col span={6} >
              {isAdmin ? <StatusChangeButton status={data?.status} /> : <span style={{ fontSize: 20 }}>{statusData(data?.status)?.icon} {statusData(data?.status)?.text}</span>}
            </Col>
          </Row>

        </div>

        <Row gutter={gutter}>

          <Col  {...colWidths(1)}>
            <ProfileBlockCard title='Ebeveny Bilgileri' FormComponent={ParentsForm} mainForm={mainForm} data={data} familyId={_id} canEdit={canEdit} />
            <ProfileBlockCard title='Çocuklar' FormComponent={ChildrenForm} mainForm={mainForm} canEdit={canEdit} familyId={_id} />
            <ProfileBlockCard title='İkametgah' FormComponent={ResidencesForm} mainForm={mainForm} data={data} canEdit={canEdit} />

          </Col>
          <Col {...colWidths(2)}>
            <CustomEditBlockCard title={'Aile Notları'} mainForm={mainForm} FormComponent={NotesForm} data={data} />
            <CustomEditBlockCard title={'Aile ziyaretleri'} mainForm={mainForm} FormComponent={FamilyVisitsForm} data={data} />
            <ProfileBlockCard title='Banka Bilgileri' FormComponent={BankForm} mainForm={mainForm} data={data} canEdit={canEdit} />

            <Card title='İletişim Bilgisi' >
              <Space size="small">
                <Typography.Paragraph>{houseHolderData()?.fullname} : </Typography.Paragraph>
                <Typography.Paragraph copyable>{houseHolderData()?.phones?.[0]?.value}</Typography.Paragraph>
              </Space>

           </Card>
          </Col>
          <Col {...colWidths(3)}>
            <ProfileBlockCard title='Aile Sorumlusu' FormComponent={FamilyManagersForm} mainForm={mainForm} data={data} canEdit={canEdit} />
            <ProfileBlockCard title='Genel Giderler' FormComponent={OverheadsForm} mainForm={mainForm} data={data} canEdit={canEdit} />
            <ProfileBlockCard title='Taahütler' FormComponent={PledgesForm} mainForm={mainForm} data={data} canEdit={canEdit} />
            <ProfileBlockCard title='Yardım Alınan Kuruluşlar' FormComponent={AssociationsForm} mainForm={mainForm} canEdit={canEdit} />
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

const ProfileBlockCard = ({ title, FormComponent, mainForm, data, canEdit, familyId }) => {
  const [mode, setMode] = useState('display')
  const { _id }         = useParams()
  const dispatch        = useDispatch()

  const handleClick = () => mode === 'display' ? setMode('edit') : setMode('display')

  const handleSubmit = (values) => {
    let family = mainForm.getFieldValue('family') || {}

    mainForm.setFieldsValue({
      family: {
        ...family,
        ...values.family
      }
    })

    family = ConvertMomentToString({
      ...family,
      ...values.family
    })

    if (family.pledges) family.pledges = family.pledges.map(p => ({ ...p, year: moment(p.year).format('YYYY') }))
    if (family.overheads) family.overheads = family.overheads.map(p => ({ ...p, year: moment(p.year).format('YYYY') }))

    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(UpdateFamily({ token, _id, family }))
    })
    setMode('display')
  }
  return <Card title={title} extra={canEdit && <Button type="link" onClick={handleClick} >Düzenle</Button>}>
    <FormComponent
      mainForm={mainForm}
      mode={mode}
      data={data}
      next={handleSubmit}
      validateMessages={validateMessages}
      familyId={familyId}
    /></Card>
}

const CustomEditBlockCard = ({ title, FormComponent, mainForm, data }) => {
  const [mode, setMode] = useState('display')
  const { _id }         = useParams()
  const dispatch        = useDispatch()

  const handleClick = () => {
    if (mode === 'display') { setMode('addNew') }
  }

  const handleSubmit = (values) => {
    let family = mainForm.getFieldValue('family') || {}

    mainForm.setFieldsValue({
      family: {
        ...family,
        ...values.family
      }
    })

    family = ConvertMomentToString({
      ...family,
      ...values.family
    })

    if (family.pledges) family.pledges = family.pledges.map(p => ({ ...p, year: moment(p.year).format('YYYY') }))
    if (family.overheads) family.overheads = family.overheads.map(p => ({ ...p, year: moment(p.year).format('YYYY') }))

    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(UpdateFamily({ token, _id, family }))
    })
    setMode('display')
  }

  return <Card title={title} extra={<Tooltip title="Yeni Ekle"><Button disabled={mode === 'addNew'} type="dashed" icon={<PlusOutlined />} onClick={handleClick} >Yeni</Button></Tooltip>}>
    <FormComponent
      mainForm={mainForm}
      mode={mode}
      setMode={setMode}
      data={data}
      next={handleSubmit}
      validateMessages={validateMessages}

    /></Card>
}

export default FamilyProfile
