import { all } from 'redux-saga/effects'

import { addUserSagaWatcher } from './add_user.saga'
import { changeUserEmailSagaWatcher } from './change_user_email.saga'
import { getUserByIdSagaWatcher } from './get_user_by_id.saga'
import { getUserByUidSagaWatcher } from './get_user_by_uid.saga'
import { getUsersSagaWatcher } from './get_users.saga'
import { updateUserSagaWatcher } from './update_user.saga'

export default function * userSaga () {
  yield all([
    addUserSagaWatcher(),
    changeUserEmailSagaWatcher(),
    getUserByIdSagaWatcher(),
    getUserByUidSagaWatcher(),
    getUsersSagaWatcher(),
    updateUserSagaWatcher()
  ])
}
