import React from 'react'

import { Route, Routes } from 'react-router-dom'

import Families from './Families'
import FamilyFormProvider from './FamilyFormProvider'
import FamilyProfile from './FamilyProfile'
import './css/index.css'
const Transactions = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Families />} />
      <Route path={'/add'}  element={<FamilyFormProvider />} />
      <Route path={'/:_id'} element={<FamilyProfile />} />
    </Routes>
  )
}

export default Transactions
