import { put, takeLatest, call } from 'redux-saga/effects'

import { GetUserByUid } from '../../../services/usersService'
import {
  GET_USER_BY_UID,
  GET_USER_BY_UID_SUCCESS,
  GET_USER_BY_UID_ERROR
}
  from '../../actions/users'

function * getUserByUidSaga (action) {
  try {
    const data = yield call(GetUserByUid, action.payload)
    yield put({
      type: GET_USER_BY_UID_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_USER_BY_UID_ERROR, error: error.data
    })
  }
}

export function * getUserByUidSagaWatcher () {
  yield takeLatest(GET_USER_BY_UID, getUserByUidSaga)
}
