import React from 'react'

import {
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'
import { Form, Row, Col, DatePicker, InputNumber, Button, Input, Checkbox } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

const OverheadsFormItem = (params) => {
  const { wizardMode } = params

  const formItemLayout = {
    labelCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 3 },
      md: { span: 2 },
      xl: { span: 1 }
    } : { span: 24 },
    wrapperCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 21 },
      md: { span: 22 },
      xl: { span: 23 }
    } : { span: 24 }
  }
  const keyColLayout   = wizardMode ? {
    xs: { span: 12 },
    sm: { span: 12, offset: 3 },
    md: { span: 9, offset: 2 },
    xl: { span: 11, offset: 1 }
  } : { span: 24 }

  const valueColLayout = wizardMode ? {
    xs: { span: 12 },
    sm: { span: 9 },
    md: { span: 5 },
    xl: { span: 6 }
  } : { span: 24 }

  const coveredColLayout = wizardMode ? {
    xs: { span: 12, offset: 0 },
    sm: { span: 12, offset: 3 },
    md: { span: 6, offset: 0 },
    xl: { span: 5, offset: 0 }
  } : { span: 20 }

  const removeColLayout = wizardMode ? {
    xs: { span: 12 },
    sm: { span: 9 },
    md: { span: 2 },
    xl: { span: 1 }
  } : { span: 4 }

  return (<>

        <Form.Item {...formItemLayout} name={[params.root, 'year']} label="Sene">
            <DatePicker locale={locale} picker='year' format='YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.List name={[params.root, 'data']} rules={[{ required: true }]}  >
            {(fields, { add, remove }) => {
              return (
                    <div>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[8, 2]} className={!wizardMode ? 'overHeadRow' : null}>
                                <Col {...keyColLayout}>
                                    <Form.Item name={[field.name, 'key']} style={{ marginBottom: 0 }} rules={[{ required: true }]}  >
                                        <Input placeholder='Örn: Kira ' />
                                    </Form.Item>
                                </Col>

                                <Col {...valueColLayout}>
                                    <Form.Item name={[field.name, 'value']} rules={[{ type: 'number' }]}  >
                                        <InputNumber placeholder="Tutar" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>

                                <Col {...coveredColLayout}>
                                    <Form.Item name={[field.name, 'covered']} style={{ float: 'left' }} valuePropName='checked'>
                                        <Checkbox size > Karşılanıyor </Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col {...removeColLayout}>
                                    <MinusCircleOutlined className="dynamic-delete-button"
                                        onClick={() => {
                                          remove(field.name)
                                        }}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item wrapperCol={
                            wizardMode ? {
                              xs: { span: 24 },
                              sm: { span: 21, offset: 3 },
                              md: { span: 22, offset: 2 },
                              xl: { span: 23, offset: 1 }
                            } : { span: 24 }} >
                            <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                <PlusOutlined /> Gider Ekle
                            </Button>
                        </Form.Item>
                    </div>
              )
            }}
        </Form.List>
    </>
  )
}
export default OverheadsFormItem
