import React from 'react'

import { Form, DatePicker, Input } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

import DraggerAttachmentFormItem from './DraggerAttachmentFormItem'
const NotesFormItem = (params) => {  const { root, wizardMode } = params
  const formItemLayout       = {
    labelCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 9 },
      md: { span: 7 },
      lg: { span: 5 },
      xl: { span: 3 }
    } : { span: 24 },
    wrapperCol: wizardMode ? {
      xs: { span: 24 },
      sm: { span: 15 },
      md: { span: 17 },
      lg: { span: 19 },
      xl: { span: 21 }
    } : { span: 24 }
  }

  return (<>

        <Form.Item {...formItemLayout} name={[root, 'noteTaker']} rules={[{ required: true }]} label="Not Tutan" >
            <Input />
        </Form.Item>

        <Form.Item {...formItemLayout} name={[root, 'date']} label="Tarih">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item {...formItemLayout} name={[root, 'note']} label="Not" rules={[{ required: true }]}>
            <Input.TextArea />
        </Form.Item>

        <Form.Item {...formItemLayout}  label="Ekler" valuePropName='value'>
            <DraggerAttachmentFormItem root={[root, 'attachments']}  />
        </Form.Item>
    </>
  )
}
export default NotesFormItem
