import { combineReducers } from 'redux'

// import ofparticipants from './ofparticipants.reducer'
import transaction from './transaction.reducer'
import list from './transactions.reducer'

const transactions = combineReducers({  list,
  // ofparticipants,
  transaction
})

export default transactions
