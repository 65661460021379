import React from 'react'

import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col, Descriptions } from 'antd'
import moment from 'moment'
import numeral from 'numeral'

import OverheadsFormItem from '../FormItems/OverheadsFormItem'

const OverheadsForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, data } = params
  const editMode                                               = mode === 'edit'
  const wizardMode                                             = (next && prev)

  return editMode
    ? (
            <Form form={mainForm} name='overheadsStepForm' validateMessages={validateMessages} onFinish={next} >
                <Form.List name={['family', 'overheads']}>
                    {(fields, { add, remove }) => {
                      return (
                            <div>
                                {fields.length === 0 ? <Empty description='Genel Gider Eklenmemiş' />
                                  : fields.map((field, index) => (
                                        <Form.Item
                                            colon={true}
                                            label={index === 0 && wizardMode ? 'Genel Giderler' : ''}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            key={field.key}
                                        >
                                            <Card actions={[<DeleteOutlined key="delete" onClick={() => remove(field.name)} />]} >
                                                <OverheadsFormItem root={field.name} wizardMode={wizardMode}/>
                                            </Card>

                                        </Form.Item>
                                  ))}

                                {editMode
                                  ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                                        <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                            <PlusOutlined />Genel Gider Yılı Ekle</Button>
                                    </Form.Item>
                                  : null}
                            </div>
                      )
                    }}
                </Form.List>

                {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
                {
                    wizardMode
                      ? <Row gutter={[4, 4]} align='bottom'>
                            <Col span={12}>
                                <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                            </Col>
                        </Row>
                      : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
                }
            </Form>
      )
    :        <LastOverheadViewer familyData={data} />
}

const LastOverheadViewer = ({ familyData }) => {
  if (familyData && familyData.overheads && familyData.overheads.length > 0) {
    const latestOverhead = familyData.overheads.reduce((prev, current) => (prev.year > current.year) ? prev : current, { data: [], year: 0 })

    return <Descriptions size="small" title={
            (moment.isMoment(latestOverhead.year) ? moment(latestOverhead.year).format('YYYY') :  moment({ year: latestOverhead.year }).format('YYYY')) + ' Yılı Genel Giderleri'
        } bordered column={1}>
            {latestOverhead.data.map((item, index) => <Descriptions.Item key={index} label={item.key}>{numeral(item.value).format('0,0[.]00 $')}</Descriptions.Item>)}
        </Descriptions>
  } else { return <Empty description='Genel Gider Eklenmemiş' /> }
}

OverheadsForm.defaultProps = {
  mode: 'edit'
}

export default OverheadsForm
