import React from 'react'

import { Route, Routes } from 'react-router-dom'

import AccountList from './AccountList'
import Balances from './Balances'
const Accounts = () => {
  return (
    <Routes>
      <Route exact path="/" element={<AccountList /> } />
      <Route path={'/balances'}  element={<Balances />} />
    </Routes>
  )
}
export default Accounts
