import * as Actions from '../../actions/contacts'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const GetParticipantContactsNamesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PARTICIPANT_CONTACTS_NAMES: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACTS_NAMES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACTS_NAMES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default GetParticipantContactsNamesReducer
