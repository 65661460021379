import React, { useCallback, useEffect, useState } from 'react'

import { EllipsisOutlined, MailOutlined, ReloadOutlined } from '@ant-design/icons'
import { Alert, Button, Dropdown, Form, Input, Menu, message, Modal, Popconfirm, Space, Table, Tag, Typography } from 'antd'
import { sendPasswordResetEmail } from 'firebase/auth'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import FormDrawer from '../../../components/FormDrawer'
import { firebaseAuth } from '../../../firebaseApp'
import { CheckEmail } from '../../../services/usersService'
import { GetUsers, ChangeUserEmail } from '../../../store/actions/users'

import { getRoleName } from '../../../utils/helpers'

import UserForm from './UserForm'

export default function UsersList () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const users    = useSelector((state) => state.users)
  const auth     = useSelector((state) => state.auth)
  const { _id }  = useParams()

  const [pagination, setPagination]                     = useState({
    current: 1,
    pageSize: 50
  })
  const [drawerVisible, setDrawerVisible]               = useState(false)
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)
  const [userRecordToChange, setUserRecordToChange]     = useState({})
  const [newEmail, setNewEmail]                         = useState('')
  const [checkingNewEmail, setCheckingNewEmail]         = useState(false)
  const [isValidEmail, setIsValidEmail]                 = useState(false)

  const showDrawer = () => setDrawerVisible(true)
  const hideDrawer = () => { navigate(location.pathname.replace(_id, '')); setDrawerVisible(false) }

  const columns = useCallback(() => {
    const cols = [
      {
        title: 'İsim',
        dataIndex: ['contact', 'fullname'],
        key: ['contact', 'fullname'],
        sorter: true,
        render: (text, record) => {
          return (
            <Button
              type="link"
              onClick={() => navigate(`/users/${record._id}`)}
            >
              {record.contact.fullname}
            </Button>
          )
        }
      },
      {
        title: 'E-posta', dataIndex: 'email', key: 'email', sorter: true
      },
      {
        title: 'Son Giriş',
        render: (text, record) => {
          return record.lastLoginTime ? moment(record.lastLoginTime).fromNow() : null
        },
        sorter: true

      },
      {
        title: 'Kullanıcı Tipi',
        dataIndex: ['role'],
        key: ['role'],
        render: (text, record) => {
          return text ? <Tag color="geekblue">{getRoleName(text)}</Tag> : null
        },
        sorter: (a, b) => moment(a.lastLoginTime).diff(moment(b.lastLoginTime))
      },

      {
        title: 'Firebase Uid', dataIndex: 'firebaseUid', key: 'firebaseUid', sorter: (a, b) => b._id.localeCompare(a._id)
      },
      {
        title: 'Kayıt Tarihi', dataIndex: 'createdAt', key: 'createdAt', render: (text, record) => (moment(record.createdAt).format('DD.MM.YYYY | HH:mm')), sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt))
      }
    ]

    if (auth.data?.role === 'admin') {
      cols.push({
        title: 'İşlem',
        fixed: 'right',
        width: 50,
        render: (text, record) => (
          <Dropdown overlay={(
            <Menu>
              <Popconfirm
                title="Bu kullanıcının adresine şifre sıfırlama e-postası göndermek istediğinizden emin misiniz?"
                onConfirm={() => {
                  handlePasswordReset()
                }}
                okText="Evet"
                cancelText="Hayır"
              >
                <Menu.Item icon={<ReloadOutlined />}>

                  Şifre Sıfırla

                </Menu.Item>
              </Popconfirm>
              <Menu.Item
                icon={<MailOutlined />}
                onClick={() => {
                  setUserRecordToChange(record)
                  setShowChangeEmailModal(true)
                }}
              >
                E-Posta Adresi Değiştir
              </Menu.Item>
            </Menu>
          )}
          >
            <EllipsisOutlined rotate={90} style={{ fontSize: 25, cursor: 'pointer' }} />
          </Dropdown>
        )
      })
    }

    return cols
  }, [auth.data.role])

  useEffect(() => { if (_id) { showDrawer() } }, [_id])
  useEffect(() => { getUsersData() }, [])
  useEffect(() => {
    setPagination((prev) => {
      return {
        ...prev,
        pageSize: users.data.limit,
        current: users.data.page,
        total: users.data.totalDocs
      }
    })
  }, [users.data])
  useEffect(() => {
    if (newEmail) {
      setCheckingNewEmail(true)
      CheckEmail({ email: newEmail }).then((result) => {
        setCheckingNewEmail(false)
        setIsValidEmail(result)
      })
    }
  }, [newEmail])

  const getUsersData = (data) => {
    firebaseAuth.currentUser.getIdToken().then((token) => {
      dispatch(GetUsers({
        token,
        data
      }))
    })
  }

  const handleTableChange = (pag, filters, sorter) => {
    getUsersData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pag.current,
      limit: pag.pageSize,
      ...filters
    })
  }

  const handlePasswordReset = () => {
    sendPasswordResetEmail(firebaseAuth, userRecordToChange.email).then(() => {
      message.success(
        'Şifre sıfırlama e-postası kullanıcının adresine gönderilidi'
      )
    }).catch((err) => console.log(err))
  }

  const handleChangeUserEmail = () => {
    if (newEmail && isValidEmail) {
      firebaseAuth.currentUser.getIdToken().then((token) => {
        dispatch(
          ChangeUserEmail({
            token,
            newEmail,
            uid: userRecordToChange.firebaseUid
          })
        )
        message.success('E-posta adresi başarı ile güncellendi.')
        setShowChangeEmailModal(false)
      })
    }
  }

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Button type="primary" disabled={auth.data.role !== 'admin'} onClick={showDrawer}>
          Yeni Kullanıcı Ekle
        </Button>  </Space>

      <Alert
        banner
        type='info'
        description="Dernek Yönetim Platformuna bağlanarak bilgi edinmek veya bilgi girişi yapmak isteyen kullanıcılarınızı bu ekrandan ekleyebilirsiniz."
      />
      <Table
        loading={!users.isLoaded}
        bordered
        columns={columns()}
        rowKey="_id"
        pagination={pagination}
        onChange={handleTableChange}
        dataSource={users.data.docs}
      />

      <Modal
        title="E-Posta Adresi Değiştir"
        icon={<MailOutlined />}
        okText="Değiştir"
        okButtonProps={{
          disabled: !newEmail || !isValidEmail || checkingNewEmail,
          danger: true
        }}
        visible={showChangeEmailModal}
        onCancel={() => setShowChangeEmailModal(false)}
        onOk={() => {
          handleChangeUserEmail()
        }}
        maskClosable={false}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text>
            <Typography.Text mark>{userRecordToChange.email}</Typography.Text>
            {' '}
            hesabının
            E-Posta adresini değiştirmek üzeresiniz.
          </Typography.Text>
          <Typography.Text>
            Devam etmek için lütfen yeni e-posta adresini yazınız.
          </Typography.Text>

          <Form.Item
            rules={[
              {
                required: true,
                min: 3
              }
            ]}
            hasFeedback
            validateStatus={
              checkingNewEmail
                ? 'validating'
                : isValidEmail
                  ? 'success'
                  : newEmail.length > 0
                    ? 'error'
                    : null
            }
            help={
              checkingNewEmail
                ? 'Kontrol ediliyor...'
                : isValidEmail
                  ? 'Bu e-posta adresini kullanabilirsiniz'
                  : newEmail.length > 0
                    ? 'Bu e-posta adresini kullanamazsınız'
                    : null
            }
          >
            <Input
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </Form.Item>
        </Space>
      </Modal>

      <FormDrawer
        formElement={UserForm}
        visible={drawerVisible}
        hide={hideDrawer}
        disableSubmit={auth.data.role !== 'admin'}
      />
    </>
  )
}
