
import React from 'react'

import { blue, red, volcano, orange, gold, lime, green, cyan, magenta, purple } from '@ant-design/colors'
import { CheckCircleTwoTone, PauseCircleTwoTone, ExclamationCircleTwoTone, StopTwoTone, HomeOutlined, TeamOutlined } from '@ant-design/icons'
import { List, Avatar, Card } from 'antd'

import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'

const colors = [blue, red, volcano, orange, gold, lime, green, cyan, magenta, purple]

const FamiliesList = ({ data, pageSize, setPageSize, page, setPage }) => {
  const IconText = ({ icon, text }) => (<>{icon || null} {text}</>)

  return (
        <List
          itemLayout="vertical"
            pagination={{
              pageSize,
              current: page || 1,
              position: 'top',
              onChange: (current, pageSize) => {
                setPage(current)
                setPageSize(pageSize)
              },
              onShowSizeChange: (current, pageSize) => {
                setPageSize(pageSize)
                setPage(current)
              }
            }}
            dataSource={data}
            renderItem={item => {
              let statusIcon = null
              let statusText = ''

              switch (item.status) {
                case 'active':
                  statusIcon = <CheckCircleTwoTone twoToneColor="#52c41a" />
                  statusText = 'Aktif'
                  break
                case 'onhold':
                  statusIcon = <PauseCircleTwoTone twoToneColor="#d48806" />
                  statusText = 'Onay Bekliyor'
                  break
                case 'suspended':
                  statusIcon = <ExclamationCircleTwoTone twoToneColor="#ff9c6e" />
                  statusText = 'Dondurulmuş'
                  break
                case 'deactive':
                  statusIcon = <StopTwoTone twoToneColor="#cf1322" />
                  statusText = 'Çıkartılmış'
                  break
                default:
                  return null
              }

              return (
                    <Card className='familyListCard'>
                        <List.Item
                            key={item.title}
                            actions={[
                                <IconText key={0} icon={statusIcon} text={statusText} />,
                                <IconText key={1} icon={<TeamOutlined />} text={'Çocuk Sayısı : ' + item.numberOfChildren} />,
                                <IconText key={2} text={item?.familyManager?.fullname} />
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: colors[Math.floor(Math.random() * colors.length)][Math.floor(Math.random() * 11)] }} icon={<HomeOutlined />} />}
                                title={<Link to={`/families/${item._id}`}>{item.familyName} / {item.householderName} </Link>}
                                description={'Sisteme Giriş Tarihi: ' + moment(item.startingDate).format('DD.MM.YYYY')}
                            />
                            <ul>
                                <li>Ebeveyn Çalışma Durumu : {item.householderProfession}</li>
                                {item.childs ? <li>{item.childs}</li> : null}
                                <li>Nakit Taahüt : {numeral((item.pledges || {}).cash).format('0,0[.]00 $')}</li>
                                <li>Erzak Taahüt : {numeral((item.pledges || {}).supply).format('0,0[.]00 $')}</li>
                            </ul>
                        </List.Item>
                    </Card>
              )
            }}
        />
  )
}

export default FamiliesList
