import React, { useState, useEffect } from 'react'

import {
  LoadingOutlined,
  PlusOutlined,
  CloseCircleFilled

} from '@ant-design/icons'
import { Upload, Row, Col, message, Badge, Avatar } from 'antd'
import { getDownloadURL,  ref as sRef, uploadBytesResumable } from 'firebase/storage'
import moment from 'moment'

import { firebaseStorage } from '../../../../firebaseApp'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const PicturesAttachmentFormItem = ({ root, onChange, value }) => {
  const [loading, setLoading] = useState(false)
  const [files, setFiles]     = useState([])

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false)
        setFiles((prev) => {
          const newList = [...prev,
            {
              name: info.file.name,
              url: info.file.xhr,
              date: moment(),
              fileType: info.file.type
            }]
          return newList
        })
      })
    }
  }

  useEffect(() => {
    if (value) {
      if (value.length > 0) { setFiles(value) }
    }
  }, [])

  useEffect(() => {
    if (onChange) { onChange(files) }
  }, [onChange, files])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Sadece jpg veya png dosyaları yükleyebilirsiniz!')
    }
    const isLt5M = file.size / 1024 / 1024 < 5
    if (!isLt5M) {
      message.error('Seçtiğiniz dosyalar 5MB dan düşük olmalı!')
    }
  }

  const customUpload = ({ onError, onSuccess, file }) => {
    const folderName = moment().format('YYYY_MM') // store attachments into day folders
    // const fileName   = moment().format('YYYYMMDD_HHmmss')

    // create upload task for the new attachment
    // const uploadTask=storage.ref(`/${folderName}/${fileName}`).put(file)

    const uploadTask = uploadBytesResumable(sRef(firebaseStorage, `/${folderName}/${file.name}`), file)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (err) => { console.log(err) },
      () => {
        // store image in firebase strorage
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onSuccess(null, downloadURL)
        }).catch((error) => console.log(error))
      })
  }

  const RemovePicture = index => {
    setFiles(prev => {
      prev.splice(index, 1)
      return [...prev]
    })
  }

  return (
        <>
            <Row gutter={[8, 8]}>
                {files.map((file, index) =>
                  (
                        <Col key={index}>

                            <Badge style={{ cursor: 'pointer' }} count={<CloseCircleFilled style={{ color: '#f5222d' }} />} onClick={() => RemovePicture(index)}>
                                <Avatar shape="square" src={file.url} size={96} />
                            </Badge>
                        </Col>
                  )
                )}
                <Col>
                    <Upload
                        name="file"
                        multiple={true}
                        listType="picture-card"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        customRequest={customUpload}
                        showUploadList={false}
                    >
                        <div>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                            <div className="ant-upload-text">Yükle</div>
                        </div>
                    </Upload>
                </Col>
            </Row>
        </>

  )
}

export default PicturesAttachmentFormItem
