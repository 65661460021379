import React, { useState, useEffect } from 'react'

import {
  PlusOutlined,
  DeleteOutlined,
  UserOutlined,
  EditOutlined
} from '@ant-design/icons'
import { Form, Button, Avatar, Modal, Empty, Row, Col, Tag, Popconfirm } from 'antd'

import { isMobile } from 'react-device-detect'
import { createUseStyles } from 'react-jss'

import { useDispatch } from 'react-redux'

import { firebaseAuth } from '../../../../firebaseApp'
import { AddChildToFamily, RemoveChildFromFamily, UpdateFamilyContact } from '../../../../store/actions'
import { useResetFormOnCloseModal } from '../../../../utils/customHooks'

import ContactForm from './ContactForm'

const useStyles = createUseStyles({
  modalDialog: {
    maxWidth: '100%',
    margin: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0
  },
  modalContent: {
    overflowY: 'scroll', overflowX: 'hidden', maxHeight: 500, height: 'calc(100vh - 150px)'
  }
})

const ModalForm = ({ fieldName, mainForm, title, visible, hide, index, validateMessages, updateChildrenData, wizardMode }) => {
  const classes                           = useStyles()
  const [modalForm]                       = Form.useForm()
  const defaultInitialValues              = {
    gender: 'm'
  }
  const [initialValues, setInitialValues] = useState(defaultInitialValues)

  useResetFormOnCloseModal({
    form: modalForm,
    visible
  })

  useEffect(() => {
    if (typeof index !== 'undefined') {
      const family = mainForm.getFieldValue('family') || {}
      setInitialValues(family.children[index] || defaultInitialValues)
    }
  }, [fieldName, mainForm, visible, index, defaultInitialValues])

  const onOk     = () => {
    if (updateChildrenData) {
      updateChildrenData(modalForm.getFieldsValue())
      modalForm.resetFields()
    } else modalForm.submit()
  }
  const onCancel = () => {
    hide(fieldName)

    if (wizardMode && typeof index === 'undefined') {
      const family = mainForm.getFieldValue('family') || []
      family.children.pop()
      mainForm.setFieldsValue({ family })
    }
  }
  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel} width={650} className={isMobile ? classes.modalDialog  : null} maskClosable={false}>
      <div className={classes.modalContent}>

        <ContactForm
          form={modalForm}
          fieldName={fieldName}
          initialValues={initialValues}
          visible={visible}
          validateMessages={validateMessages}
        />
      </div>
    </Modal>
  )
}

const ChildrenForm = ({ mainForm, next, prev, mode, familyId }) => {
  const dispatch                                = useDispatch()
  const [childFormVisible, setChildFormVisible] = useState(false)
  const [childIndex, setChildIndex]             = useState(undefined)
  const editMode                                = mode === 'edit'
  const wizardMode                              = (next && prev)

  const showContactModal = () => {
    setChildFormVisible(true)
  }

  const hideContactModal = () => {
    setChildFormVisible(false)
  }

  const updateChildrenData = (childData) => {
    firebaseAuth.currentUser.getIdToken(true).then(token => {
      if (childData._id) {
        dispatch(UpdateFamilyContact({
          token,
          data: {
            contact: childData,
            familyId
          }
        }))
      } else {
        dispatch(AddChildToFamily({
          token,
          data: {
            contact: childData,
            familyId
          }
        }))
      }

      hideContactModal()
    })
  }

  return (

    <Form.Provider onFormFinish={(name, { values, forms }) => {
      const { ChildrenForm } = forms
      const family           = ChildrenForm.getFieldValue('family') || []

      if (name === 'childForm') {
        if (typeof childIndex !== 'undefined') { family.children[childIndex] = values } else { family.children[family.children.length - 1] = values }

        ChildrenForm.setFieldsValue({
          family
        })

        setChildFormVisible(false)
      }
    }
      }
    >
      <Form form={mainForm} name='ChildrenForm' onFinish={next} >

        <Form.List name={['family', 'children']}>
          {(fields, { add }) => {
            return (
              <div>
                {fields.length === 0 ? <Empty description='Çocuk Eklenmemiş' />
                  : fields.map((field, index) => (
                    <ChildFormItem
                      key={field.key}
                      thisForm={mainForm}
                      field={field}
                      index={index}
                      showModal={showContactModal}
                      setChildIndex={setChildIndex}
                      editMode={editMode}
                      wizardMode={wizardMode}
                    />
                  ))}

                {editMode
                  ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                    <Button type="dashed" onClick={() => {
                      if (wizardMode) add()
                      showContactModal()
                    }} style={{ width: '100%' }} >
                      <PlusOutlined />Çocuk Ekle</Button>
                  </Form.Item>
                  : null}
              </div>
            )
          }}
        </Form.List>

        {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
        {
          wizardMode
            ? <Row gutter={[4, 4]} align='bottom'>
              <Col span={12}>
                <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
              </Col>
              <Col span={12}>
                <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
              </Col>
            </Row>
            : null // editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
        }
      </Form>

      <ModalForm fieldName='child' mainForm={mainForm} title='Çocuk Bilgisi Ekle' wizardMode={wizardMode} visible={childFormVisible} hide={hideContactModal} index={childIndex} updateChildrenData={(mode === 'edit' && !wizardMode) ? updateChildrenData : null}  />
    </Form.Provider>
  )
}

const ChildFormItem = (params) => {
  const { thisForm, field, index, showModal, setChildIndex, editMode, wizardMode } = params
  const dispatch                                                                   = useDispatch()
  const formItemLayout                                                             = (index) => {
    if (editMode) {
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 },
          md: { span: 4 },
          lg: { span: 4 },
          xl: { span: 2 }
        },
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: index !== 0 ? 18 : 24, offset: index !== 0 && wizardMode ? 7 : 0 },
          md: { span: index !== 0 ? 20 : 24, offset: index !== 0 && wizardMode ? 4 : 0 },
          lg: { span: index !== 0 ? 20 : 24, offset: index !== 0 && wizardMode ? 4 : 0 },
          xl: { span: index !== 0 ? 22 : 24, offset: index !== 0 && wizardMode ? 2 : 0 }
        }
      }
    } else {
      return {
        wrapperCol: { span: 24, offset: 0 }
      }
    }
  }

  const handleRemoveChildFromFamily = (childId, familyId) => {
    firebaseAuth.currentUser.getIdToken(true).then(token => {
      dispatch(RemoveChildFromFamily({
        token,
        data: {
          childId,
          familyId
        }
      }))
    })
  }

  return (
    <Form.Item
      label={index === 0 && wizardMode ? 'Çocuklar' : ''}
      {...formItemLayout(index)}
      key={field.key}
      // shouldUpdate={(prevValues, curValues) => prevValues.child !== curValues.child}
      shouldUpdate={(prevValues, curValues) => {
        return true
      }}
    >
      {() => {
        const family = thisForm.getFieldValue('family') || []
        if (family.children.length > 0 && family.children[index]) {
          const lightBg = { backgroundColor: family.children[index].gender === 'm' ? '#f0f5ff' : '#fff0f6' }
          const darkBg  = { backgroundColor: family.children[index].gender === 'm' ? '#d6e4ff' : '#ffd6e7' }

          return <div style={{ float: 'left' }}>
            <Tag className="contactNameTag" style={lightBg} >
              {family.children[index].profilePhotoURL && family.children[index].profilePhotoURL.url
                ? <Avatar shape="square" src={family.children[index].profilePhotoURL.url} />
                : <Avatar shape="square" style={darkBg} icon={<UserOutlined />} />}
              {`${family.children[index].firstname} ${family.children[index].lastname || ''}`}
            </Tag>
            {editMode
              ? <>
                <EditOutlined onClick={() => {
                  setChildIndex(index)
                  showModal()
                }} />

                <Popconfirm
                  title="Bu çocuk bilgisini silmek istediğinize emin misiniz?"
                  okText="Evet Sil"
                  cancelText="Hayır"
                  onConfirm={() => {
                    handleRemoveChildFromFamily(family.children[index]._id, family._id)
                    // family.children.splice(index, 1)
                    // thisForm.setFieldsValue(family.children)
                    // setChildIndex(undefined)
                  }}
                >
                  <DeleteOutlined />
                </Popconfirm>
              </>
              : null
            }
          </div>
        }
      }}
    </Form.Item>
  )
}

ChildrenForm.defaultProps = {  mode: 'edit' }

export default ChildrenForm
