import React from 'react'

import { Form, DatePicker, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

import DraggerAttachmentFormItem from './DraggerAttachmentFormItem'

const FamilyVisitsFormItem = (params) => {
  const { root, mainForm, index, participantsNames, isParticipantsNamesLoading } = params

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  }

  const handleChange = (values, data) => {
    const family                    = mainForm.getFieldValue('family')
    family.familyVisits[index].text = data.map(row => row.children[0]).join(', ').replace(/,(?=[^,]*$)/, ' ve')
    mainForm.setFieldsValue({ family })
  }

  return (<>
        <Form.Item {...formItemLayout} name={[root, 'date']} label="Tarih">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
            name={[root, 'visitors']}
            label="Ziyaretçiler"
            hasFeedback={isParticipantsNamesLoading}
            {...formItemLayout}
        >

            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Ziyaretçi Seçin"
                onChange={handleChange}
            >
                {
                    participantsNames.map((p, i) => <Select.Option key={i} value={p._id}>{p.fullname} </Select.Option>)
                }
            </Select>
        </Form.Item>

        <Form.Item {...formItemLayout} label="Ekler" valuePropName='value'>
            <DraggerAttachmentFormItem root={[root, 'attachments']} />
        </Form.Item>
    </>
  )
}
export default FamilyVisitsFormItem
