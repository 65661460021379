import React, { useEffect } from 'react'

import { Mix, Area, Column } from '@ant-design/plots'
// import { ChartCard, Field, MiniBar } from 'ant-design-pro/lib/Charts'

import { Row, Col, Card,  Typography } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import { useDispatch, useSelector } from 'react-redux'

import { firebaseAuth } from '../../firebaseApp'
import { GetStatistics } from '../../store/actions/statistics'
import { formatMoney } from '../../utils/helpers'

const visitData = []
const beginDay  = new Date().getTime()
for (let i = 0; i < 20; i++) {
  visitData.push({
    x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
    y: Math.floor(Math.random() * 100) + 10
  })
}

const Dashboard = (props) => {
  const dispatch   = useDispatch()
  const statistics = useSelector(state => state.statistics)
  const auth       = useSelector((state) => state.auth)

  const isAdmin = auth.data.role === 'admin'

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetStatistics({ token }))
    })
  }, [])

  const donationConfig = {
    appendPadding: 8,
    tooltip: {
      shared: true
    },
    syncViewPadding: true,
    plots: [
      {
        type: 'column',
        options: {
          isGroup: true,
          xField: 'x',
          yField: 'y',
          data: (statistics.data?.donationXYData || []),
          seriesField: 'name',
          color: ['#1ca9e6', '#f88c24'],
          label: {
            position: 'middle',
            layout: [
              {
                type: 'interval-adjust-position'
              },
              {
                type: 'interval-hide-overlap'
              },
              {
                type: 'adjust-color'
              }
            ],
            formatter: (val) => {
              return formatMoney(val.y)
            }
          }

        }
      },
      {
        type: 'line',
        options: {
          data: (statistics.data?.donationPercentData || []),
          xField: 'x',
          yField: 'percent',
          xAxis: false,
          yAxis: {
            line: null,
            grid: null,
            position: 'right',
            tickCount: 8
          },
          meta: {
            date: {
              sync: 'x'
            },
            value: {
              alias: 'asd',
              formatter: (v) => `%${(v * 100).toFixed(1)}`
            }
          },
          smooth: true,
          label: {
            formatter: (val) => `%${val.percent}`,
            callback: (value) => {
              return {
                offsetY: value === 0.148 ? 36 : value === 0.055 ? 0 : 20,
                style: {
                  fill: '#1AAF8B',
                  fontWeight: 700,
                  stroke: '#fff',
                  lineWidth: 3
                }
              }
            }
          },
          color: '#1AAF8B'
        }
      }
    ]
  }

  const contributionConfig = {
    appendPadding: 8,
    tooltip: {
      shared: true
    },
    syncViewPadding: true,
    plots: [
      {
        type: 'column',
        options: {
          isGroup: true,
          xField: 'x',
          yField: 'y',
          data: (statistics.data?.contributionXYData || []),
          seriesField: 'name',
          color: ['#ff4265', '#107c41'],
          height: 500,
          label: {
            position: 'middle',
            layout: [
              {
                type: 'interval-adjust-position'
              },
              {
                type: 'interval-hide-overlap'
              },
              {
                type: 'adjust-color'
              }
            ],
            formatter: (val) => {
              return formatMoney(val.y)
            }
          }

        }
      },
      {
        type: 'line',
        options: {
          data: (statistics.data?.contributionPercentData || []),
          xField: 'x',
          yField: 'percent',
          xAxis: false,
          yAxis: {
            line: null,
            grid: null,
            position: 'right',
            max: 300,
            tickCount: 8
          },
          meta: {
            date: {
              sync: 'x'
            },
            value: {
              alias: 'asd',
              formatter: (v) => `%${(v * 100).toFixed(1)}`
            }
          },
          smooth: true,
          label: {
            formatter: (val) => `%${val.percent}`,
            callback: (value) => {
              return {
                offsetY: value === 0.148 ? 36 : value === 0.055 ? 0 : 20,
                style: {
                  fill: '#1AAF8B',
                  fontWeight: 700,
                  stroke: '#fff',
                  lineWidth: 3
                }
              }
            }
          },
          color: '#1AAF8B'
        }
      }
    ]
  }

  console.log(statistics?.data)
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={12}>
        <Card title="Katılımcı Sayısı" extra={ <Typography.Title level={4}> {numeral(statistics?.data?.totalParticipantCount).format('0,0')} </Typography.Title>}>
          <Area
            data={(statistics?.data?.participationXYData || [])}
            xField='x'
            yField='y'
            height={134}
            xAxis={ {
              range: [0, 1],
              tickCount: 5
            }
          }
          />
        </Card>
      </Col>
      <Col xs={24} sm={12}>
        <Card
          title="Aile Sayısı"
          extra={<Typography.Title level={4}>{numeral(statistics?.data?.totalFamilyCount).format('0,0')}</Typography.Title>}
          // footer={<Field label="Toplam Kardeş Sayımız" value={numeral(statistics?.data?.childrenCount).format('0,0')} />}
        >
          <Column
            height={114}
            data={statistics?.data?.familyXYData || []}
            autoFit={true}
            xField='x'
            yField='y'
            tooltip= {
              {
                customContent: function (x, data) {
                  return `${data[0]?.data?.y}`
                }
              }
            }
          />
          <Typography.Text type="secondary">Toplam Kardeş Sayımız: {numeral(statistics?.data?.childrenCount).format('0,0')}</Typography.Text>
        </Card>
      </Col>

      {
        isAdmin && (<>
         <Col span={24} style={{ marginTop: 24 }}>
            <Card title="Taahüt Toplamı" >
              <Mix  {...donationConfig} />
            </Card>

            </Col>

            <Col span={24} style={{ marginTop: 24 }}>
            <Card title="Yapılan Yardımlar Toplamı" >
              <Mix  {...contributionConfig} />
            </Card>
          </Col>
        </>)
      }

    </Row>
  )
}
export default Dashboard
