import axios from 'axios'

import system from './config'

const GetAccounts = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/accounts', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetAccount = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/accounts/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetAccountBalance = ({ token, accountCode, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + `/accounts/balance/${accountCode}`, data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetAccountBalances = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/accounts/balances', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const SaveAccounts = ({ token, accounts }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/accounts/save', accounts)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetAccount,
  GetAccounts,
  GetAccountBalance,
  GetAccountBalances,
  SaveAccounts
}
