import React from 'react'

import { createFromIconfontCN } from '@ant-design/icons'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_1822550_rmu254ld82j.js'
})

export const IconFromStringComp = ({ string }) => {
  switch (string) {
    case 'TRY':
      return <IconFont type="icon-lira-sign" />
    case 'USD':
      return <IconFont type="icon-dollar-sign" />
    case 'EUR':
      return <IconFont type="icon-euro-sign" />
    case 'GBP':
      return <IconFont type="icon-pound-sign" />
    default:
      return <IconFont type="icon-lira-sign" />
  }
}
