import { all } from 'redux-saga/effects'

import accountSaga from './accounts'
import authSaga from './auth'
import configSaga from './config'
import familiesSaga from './families'
import participantsSaga from './participants'
import statisticsSaga from './statistics'
import transactionsSaga from './transactions'
import usersSaga from './users'

export default function * rootSaga () {
  yield all([
    accountSaga(),
    authSaga(),
    configSaga(),
    familiesSaga(),
    participantsSaga(),
    statisticsSaga(),
    transactionsSaga(),
    usersSaga()
  ])
}
