import React, { useEffect, useState } from 'react'

import { FileExcelOutlined } from '@ant-design/icons'
import { Button,  Checkbox,  Form, Space, Table, Tag } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import DebounceSelect from '../../../components/DebounceSelect'
import { firebaseAuth } from '../../../firebaseApp'
import { GetAccounts } from '../../../services/accountsService'
import { GetAccountBalance } from '../../../store/actions/accounts'
import { useQuery } from '../../../utils/customHooks'
import exportExcel from '../../../utils/exportExcel'
import { formatMoney } from '../../../utils/helpers'

export default function AccountDetails () {
  const accountDetails = useSelector((state) => state.accounts.account.data)
  const isLoading      = useSelector((state) => state.accounts.account.isLoading)
  const serverConfig   = useSelector((state) => state.serverConfig.accounts)
  const auth           = useSelector((state) => state.auth)

  const dispatch    = useDispatch()
  const navigate    = useNavigate()
  const queryParams = useQuery()

  const query                     = queryParams.get('q')
  const participantAccountInitial = serverConfig.defaults.find(d => d.key === 'participant').root.value

  const [withParticipationBalance, setWithParticipationBalance] = useState(false)
  const [showCheckBox, setShowCheckBox]                         = useState(false)
  const [selectedAccount, setSelectedAccount]                   = useState({ label: '', value: '' })

  const columns = (mode) => [{
    title: 'Tarih',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    render: (text, record, index) => {
      if (record.isSummaryRow) return ''
      if (mode === 'export') return  moment(record.date).format('DD.MM.YYYY')

      const obj       = {
        children: moment(record.date).format('DD.MM.YYYY'),
        props: { rowSpan: accountDetails.filter(d => d.groupCode === record.groupCode).length }
      }
      const filterted = accountDetails.filter(d => d.groupCode === record.groupCode)
      let curIndex    = -1
      if (filterted.length > 1) {
        for (let i = 0; i < filterted.length; i++) {
          if (filterted[i]._id === record._id) {
            curIndex = i
            break
          }
        }
        obj.props.rowSpan = curIndex === 0 ? filterted.length : 0
        obj.props.colSpan = curIndex === 0 ? 1 : 0
      }

      return obj
    }
  },
  {
    title: 'Hesap',
    dataIndex: ['account', 'name'],
    key: ['account', 'name'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      if (mode === 'export') return record.account.name

      const obj       = {
        children: <Tag className='nameTag' color={auth.data.role === 'admin' ? 'pink' : 'default'} onClick={() => {
          if (auth.data.role === 'admin') { window.open(`/transactions/${record.transactionType}/${record.groupCode}`, '_blank') }
        }}>{text}</Tag>,
        props: {}
      }
      const filterted = accountDetails.filter(d => d.groupCode === record.groupCode)
      let curIndex    = -1
      if (filterted.length > 1) {
        for (let i = 0; i < filterted.length; i++) {
          if (filterted[i]._id === record._id) {
            curIndex = i
            break
          }
        }
        obj.props.rowSpan = curIndex === 0 ? filterted.length : 0
        obj.props.colSpan = curIndex === 0 ? 1 : 0
      }

      return obj
    },
    sorter: true
  },
  {
    title: 'Açıklama',
    dataIndex: 'note',
    key: 'note',
    render: (text, record) => {
      if (record.isSummaryRow) return 'Toplam : '

      return text
    }
  },
  {
    title: 'Alacak',
    dataIndex: 'in',
    key: 'in',
    render: (text, record) => {
      if (record.isSummaryRow) return formatMoney(accountDetails.reduce((acc, cur) => acc + cur.in, 0))

      return formatMoney(text)
    }

  },
  {
    title: 'Borç',
    dataIndex: 'out',
    key: 'out',
    render: (text, record) => {
      if (record.isSummaryRow) return formatMoney(accountDetails.reduce((acc, cur) => acc + cur.out, 0))

      return formatMoney(text)
    }
  },
  {
    title: 'Bakiye',
    dataIndex: 'balance',
    key: 'balance',
    render: (text, record) => {
      if (record.isSummaryRow) return formatMoney(accountDetails[accountDetails.length - 1].balance)

      return formatMoney(text)
    }
  }

  ]

  useEffect(() => {
    const fetchData = async (q) => {
      const data = await fetchAccounts(q)
      if (data.length > 0) { setSelectedAccount(data[0]) }
    }

    if (query && (auth.data.role === 'admin')) {
      fetchData(query).catch(console.error)
    } else if (auth.data.contact.fullname) {
      fetchData(auth.data.contact.fullname).catch(console.error)
    }
  }, [query, auth])

  useEffect(() => { if (selectedAccount.value) getAccountBalance() }, [selectedAccount, withParticipationBalance])

  const getAccountBalance = (data) => {
    firebaseAuth.currentUser.getIdToken().then((token) => {
      dispatch(
        GetAccountBalance({
          token,
          accountCode: selectedAccount.value,
          data: {
            ...data,
            withParticipationBalance
          }
        })
      )
    })
  }

  const fetchAccounts = async (debouncedQuery) => {
    return firebaseAuth.currentUser.getIdToken().then((token) =>
      GetAccounts({
        token,
        data: {
          query: debouncedQuery || null
        }
      }).then((accounts) => accounts.map((account) => ({
        label: `${account.name} (${account.accountCode})`,
        value: account.accountCode
      }))))
  }

  const exportToExcel = () => {
    const exportData = [...accountDetails, { isSummaryRow: true }]
    exportExcel([{ headers: columns('export'), data: exportData, sheetName: 'Cari Ekstre' }], 'Hesap Özeti')
  }
  return (
    <>
      <Space align='start'>
        {
          (auth.data.role === 'familyManager' || auth.data.role === 'adminViewer') ? (
            <Form.Item label='Hesap'>
              <Tag>{selectedAccount.label}</Tag>
            </Form.Item>)
            :          <Form.Item label="Hesaplar">
          <DebounceSelect
            showSearch
            value={selectedAccount}
            placeholder="Hesap Ara"
            fetchOptions={fetchAccounts}
            onChange={(newValue) => {
              setShowCheckBox(newValue.value.startsWith(participantAccountInitial))

              navigate(`/transactions?q=${newValue.value}`)
            }}
            style={{
              minWidth: 250
            }}
          />
          </Form.Item>

        }
        {
          showCheckBox && (
            <Form.Item label="Katılımcı Bakiyesi Göster">
              <Checkbox checked={withParticipationBalance} onChange={(v) => setWithParticipationBalance(v.target.checked)} />
            </Form.Item>
          )
        }

        <Button onClick={exportToExcel}>
          <FileExcelOutlined /> XLS İndir
          </Button>
      </Space>
      <Table
        dataSource={accountDetails}
        columns={columns()}
        bordered
        size="small"
        rowKey='groupCode'
        loading={isLoading}
        // pagination={pagination}
        // onChange={handleTableChange}
      >
      </Table>
    </>
  )
}
