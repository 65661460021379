import React from 'react'

import { Route, Routes } from 'react-router-dom'

import UsersList from './UsersList'
const Accounts = () => {
  return (
    <Routes>
      <Route exact path="/" element={<UsersList /> } />
      <Route path={'/:_id'} element={<UsersList />} />
    </Routes>
  )
}
export default Accounts
