import { put, takeLatest, call } from 'redux-saga/effects'

import { GetConfig } from '../../../services/configService'

import {
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_ERROR
}
  from '../../actions/config'

function * getConfigSaga (action) {
  try {
    const data =  yield call(GetConfig, action.payload)
    yield put({
      type: GET_CONFIG_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_CONFIG_ERROR, payload: error
    })
  }
}

export function * getConfigSagaWatcher () {
  yield takeLatest(GET_CONFIG, getConfigSaga)
}
