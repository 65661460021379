import React, { useState } from 'react'

import { CheckCircleTwoTone, PauseCircleTwoTone, ExclamationCircleTwoTone, StopTwoTone } from '@ant-design/icons'
import {  Table } from 'antd'

import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'

const columns = [{
  title: 'Aile Adı',
  dataIndex: 'familyName',
  key: 'familyName',
  fixed: 'left',
  width: 200,
  render: (text, record) => <Link to={`/families/${record._id}`}>{record.familyName}</Link>,
  sorter: (a, b) => a.familyName.localeCompare(b.familyName)
},
{
  title: 'Ebeveyn Adı',
  dataIndex: 'householderName',
  key: 'householderName',
  width: 200,
  sorter: (a, b) => a.householderName.localeCompare(b.householderName)
},
{
  title: 'Çocuklar',
  dataIndex: 'childs',
  key: 'childs',
  width: 250
},
{
  title: 'Çocuk Sayısı',
  dataIndex: 'numberOfChildren',
  key: 'numberOfChildren',
  width: 120,
  sorter: (a, b) => a.numberOfChildren - b.numberOfChildren
},
{
  title: 'Giriş Tarihi',
  dataIndex: 'startingDate',
  key: 'startingDate',
  width: 120,
  render: (text, record) => moment(record.startingDate).format('DD.MM.YYYY'),
  sorter: (a, b) => moment(a.startingDate).diff(moment(b.startingDate))
},
{
  title: 'Adres',
  dataIndex: 'residences',
  key: 'residences',
  render: (text, record) => record.residences?.formatted_address,
  width: 250
},
{
  title: 'Ebeveyn Meslek',
  dataIndex: 'householderProfession',
  key: 'householderProfession',
  width: 120,
  sorter: (a, b) => a.householderProfession.localeCompare(b.householderProfession)
},
{
  title: 'Nakit Taahüt',
  dataIndex: ['pledges', 'cash'],
  key: ['pledges', 'cash'],
  width: 120,
  sorter: (a, b) => a.pledges?.cash - b.pledges?.cash,
  render: (text, record) => numeral((record.pledges || {}).cash).format('0,0[.]00 $')
},
{
  title: 'Erzak Taahüt',
  dataIndex: ['pledges', 'supply'],
  key: ['pledges', 'supply'],
  width: 120,
  render: (text, record) => numeral((record.pledges || {}).supply).format('0,0[.]00 $'),
  sorter: (a, b) => a.pledges?.supply - b.pledges?.supply
},
{
  title: 'Aile Sorumlusu',
  dataIndex: 'familyManager',
  key: 'familyManager',
  width: 200,
  render: (text, record) => record?.familyManager?.fullname,
  sorter: (a, b) => a.familyManager?.fullname.localeCompare(b.familyManager?.fullname)

},
{
  title: 'Durum',
  dataIndex: 'status',
  key: 'status',
  fixed: 'right',
  width: 120,
  render: (text, record) => {
    let statusIcon = null
    let statusText = ''
    switch (record.status) {
      case 'active':
        statusIcon = <CheckCircleTwoTone twoToneColor="#52c41a" />
        statusText = 'Aktif'
        break
      case 'onhold':
        statusIcon = <PauseCircleTwoTone twoToneColor="#d48806" />
        statusText = 'Onay Bekliyor'
        break
      case 'suspended':
        statusIcon = <ExclamationCircleTwoTone twoToneColor="#ff9c6e" />
        statusText = 'Dondurulmuş'
        break
      case 'deactive':
        statusIcon = <StopTwoTone twoToneColor="#cf1322" />
        statusText = 'Çıkartılmış'
        break
      default:
        return null
    }
    return <span>{statusIcon} {statusText}</span>
  },
  sorter: (a, b) => a.status.localeCompare(b.status)
}

]

const FamiliesTable = ({ data, pageSize, setPageSize, page, setPage }) => {
  return (
    <Table
      bordered
      pagination={{
        pageSize,
        current: page,
        position: ['bottomRight'],
        onChange: (current, pageSize) => {
          setPage(current)
          setPageSize(pageSize)
        },
        onShowSizeChange: (current, pageSize) => {
          setPageSize(pageSize)
          setPage(current)
        }
      }}
      rowKey="_id"
      scroll={{ x: 2000 }}
      dataSource={data}
      columns={columns}
        />
  )
}

export default FamiliesTable
