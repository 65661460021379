import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateTransaction } from '../../../services/transactionsService'
import {
  UPDATE_TRANSACTION,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERROR
}
  from '../../actions/transactions'

function * updateTransactionSaga (action) {
  try {
    const data =  yield call(UpdateTransaction, action.payload)
    yield put({
      type: UPDATE_TRANSACTION_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: UPDATE_TRANSACTION_ERROR, payload: error
    })
  }
}

export function * updateTransactionSagaWatcher () {
  yield takeLatest(UPDATE_TRANSACTION, updateTransactionSaga)
}
