import axios from 'axios'

import system from './config'

const setDefaults = () => {
  axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencode'
  axios.defaults.headers.common.Accept          = 'application/json'
}

const Login = (data) => {
  const { token } = data
  setDefaults()
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/auth/login/', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const Register = (data) => {
  setDefaults()
  return new Promise((resolve, reject) => {
    axios.post(system.webService + '/auth/register', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  Login,
  Register
}
