import { all } from 'redux-saga/effects'

import { addParticipantContactSagaWatcher } from './add_participant_contact.saga'
import { deleteParticipantContactSagaWatcher } from './delete_participant_contact.saga'
import { deleteParticipantContactsSagaWatcher } from './delete_participant_contacts.saga'
import { getParticipantContactSagaWatcher } from './get_participant_contact.saga'
import { getParticipantContactsSagaWatcher } from './get_participant_contacts.saga'
import { getParticipantContactsNamesSagaWatcher } from './get_participant_contacts_names.saga'
import { updateParticipantContactSagaWatcher } from './update_participant_contact.saga'
import { updateParticipantContactsSagaWatcher } from './update_participant_contacts.saga'

export default function * ParticipantContactsSaga () {
  yield all([
    getParticipantContactsSagaWatcher(),
    getParticipantContactSagaWatcher(),
    getParticipantContactsNamesSagaWatcher(),
    addParticipantContactSagaWatcher(),
    deleteParticipantContactSagaWatcher(),
    deleteParticipantContactsSagaWatcher(),
    updateParticipantContactSagaWatcher(),
    updateParticipantContactsSagaWatcher()
  ])
}
