import { all } from 'redux-saga/effects'

import { getAccountBalanceSagaWatcher } from './get_account_balance.saga'
import { getAccountBalancesSagaWatcher } from './get_account_balances.saga'
import { getAccountsSagaWatcher } from './get_accounts.saga'
import { saveAccountsSagaWatcher } from './save_accounts.saga'

export default function * accountsSaga () {
  yield all([
    getAccountBalanceSagaWatcher(),
    getAccountBalancesSagaWatcher(),
    getAccountsSagaWatcher(),
    saveAccountsSagaWatcher()
  ])
}
