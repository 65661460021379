import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateParticipantContacts } from '../../../services/contactsService'

import {
  UPDATE_PARTICIPANT_CONTACTS,
  UPDATE_PARTICIPANT_CONTACTS_SUCCESS,
  UPDATE_PARTICIPANT_CONTACTS_ERROR
}
  from '../../actions/contacts'

function * updateParticipantContactsSaga (action) {
  try {
    yield call(UpdateParticipantContacts, action.payload)
    yield put({
      type: UPDATE_PARTICIPANT_CONTACTS_SUCCESS,
      payload: action.payload
    })
  } catch (error) {
    yield put({
      type: UPDATE_PARTICIPANT_CONTACTS_ERROR, payload: error
    })
  }
}

export function * updateParticipantContactsSagaWatcher () {
  yield takeLatest(UPDATE_PARTICIPANT_CONTACTS, updateParticipantContactsSaga)
}
