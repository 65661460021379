import * as Actions from '../../actions/auth'

const initialState = {
  data: null,
  isLoggedIn: false,
  isLoading: false,
  error: false
}

const auth = function (state = initialState, action) {
  switch (action.type) {
    case Actions.AUTH_STATE_CHANGE: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.AUTH_STATE_CHANGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoggedIn: true,
        isLoading: false,
        error: false
      }
    }
    case Actions.AUTH_STATE_CHANGE_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        error: action.error
      }
    }

    case Actions.LOGOUT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.LOGOUT_SUCCESS: {
      return {
        data: {},
        isLoggedIn: false,
        isLoading: false,
        error: false
      }
    }
    case Actions.LOGOUT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }

    case Actions.REGISTER: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.REGISTER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoggedIn: true,
        isLoading: false,
        error: false
      }
    }
    case Actions.REGISTER_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        error: action.error
      }
    }

    default: {
      return state
    }
  }
}

export default auth
