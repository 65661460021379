import { put, call, takeLatest } from 'redux-saga/effects'

import * as authService from '../../../services/authService'

import {
  AUTH_STATE_CHANGE,
  AUTH_STATE_CHANGE_SUCCESS,
  AUTH_STATE_CHANGE_ERROR,
  LOGOUT_SUCCESS
}
  from '../../actions/auth'

function * authStateChangeSaga (action) {
  try {
    if (action.payload.user) {
      const token = yield action.payload.user.getIdToken()
      const data  = yield call(authService.Login, {
        email: action.payload.user.email,
        token
      })

      yield put({ type: AUTH_STATE_CHANGE_SUCCESS, payload: {  data } })
    } else { yield put({ type: LOGOUT_SUCCESS, payload: null }) }
  } catch (error) {
    yield put({ type: LOGOUT_SUCCESS, payload: null })
    yield put({ type: AUTH_STATE_CHANGE_ERROR, error: error && error.data ? error.data : error })
  }
}

export function * authStateChangeWatcher () {
  yield takeLatest(AUTH_STATE_CHANGE, authStateChangeSaga)
}
