import { put, takeLatest, call } from 'redux-saga/effects'

import { DeleteTransaction } from '../../../services/transactionsService'

import {
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERROR
}
  from '../../actions/transactions'

function * deleteTransactionSaga (action) {
  try {
    yield call(DeleteTransaction, action.payload)
    yield put({
      type: DELETE_TRANSACTION_SUCCESS,
      payload: action.payload._id
    })
  } catch (error) {
    yield put({
      type: DELETE_TRANSACTION_ERROR, payload: error
    })
  }
}

export function * deleteTransactionSagaWatcher () {
  yield takeLatest(DELETE_TRANSACTION, deleteTransactionSaga)
}
