import React, { useEffect } from 'react'

import {  BankOutlined, CreditCardOutlined, UserOutlined, SettingOutlined,  FacebookFilled, InstagramFilled, TwitterSquareFilled } from '@ant-design/icons'
import { Button,  Layout, Menu } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, Outlet } from 'react-router-dom'
import './index.css'

import Breadcrumbs from '../../components/Breadcrumbs'
import logo from '../../logo.svg'

import { Logout } from '../../store/actions/auth'

import { GetConfig } from '../../store/actions/config'

const MainPageLayout = (props) => {
  const navigate = useNavigate()
  const auth     = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const isAdmin = auth.data.role === 'admin'

  const { Header, Content, Footer, Sider } = Layout
  const { SubMenu }                        = Menu

  useEffect(() => { dispatch(GetConfig()) }, [])
  return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsedWidth={0} breakpoint="lg" width="220">
                <a className="logo" href='/' onClick={(e) => {
                  e.preventDefault()
                  return navigate('/')
                }}>
                    <img src={logo} alt='' />
                </a>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" >

                    <SubMenu key="sub1" title={<span><BankOutlined /> <span>Dernek İşlemleri</span> </span>}>

                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="1"><Link to="/participants">Katılımcılar</Link></Menu.Item>

                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="2"><Link to="/participants/yearview">Yıllık Tablo Özeti</Link></Menu.Item>
                        <Menu.Item key="3"><Link to="/participants/summary">Alacak Takip Tablosu</Link></Menu.Item>
                        <Menu.Item key="4"><Link to="/families">Aileler</Link></Menu.Item>
                    </SubMenu>

                    <SubMenu key="sub2" title={<span> <CreditCardOutlined /> <span>Hesap İşlemleri</span></span>}>
                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="5" ><Link to='/accounts'>Cari Hesaplar</Link></Menu.Item>
                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="7" ><Link to={{ pathname: '/accounts/balances' }}>Cari Mizan</Link></Menu.Item>
                        <Menu.Item key="6" ><Link to={{ pathname: '/transactions' }}>Hesap Kontrol</Link></Menu.Item>
                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="8" ><Link to={{ pathname: '/transactions/donation' }}>Toplanan Yardımlar</Link></Menu.Item>
                        <Menu.Item style={{ display: !isAdmin && 'none' }} key="9" ><Link to={{ pathname: '/transactions/contribution' }}>Yapılan Yardımlar</Link></Menu.Item>
                    </SubMenu>

                    <SubMenu style={{ display: !isAdmin && 'none' }} key="sub3" title={<span> <UserOutlined /> <span>Kullanıcı İşlemleri</span></span>}>
                        <Menu.Item key="10" ><Link to='/users'>Kullanıcı Hesabları</Link></Menu.Item>
                  </SubMenu>

                      <div style={{ display: 'flex', justifyContent: 'center', gap: 8, width: 220 }} >
                          <Button icon={<FacebookFilled style={{ fontSize: 25, color: 'white' }} />} onClick={() => window.open('https://www.facebook.com/KardesPayiDernegi/', '_blank')} type="text" />
                          <Button icon={<InstagramFilled style={{ fontSize: 25, color: 'white' }} />} onClick={() => window.open('https://www.instagram.com/kardespayidernegi/', '_blank')} type="text" />
                          <Button icon={<TwitterSquareFilled style={{ fontSize: 25, color: 'white' }} />} onClick={() => window.open('https://twitter.com/kardespayider', '_blank')} type="text" />
                      </div>
                    {/* <SubMenu key="sub3" title={<span><PieChartOutlined /><span>Stok Kontrol</span></span>}>
                        <Menu.Item key="11"><Link to="stocks/">Stok Sorgulama</Link></Menu.Item>
                        <Menu.Item key="12"><Link to="stockTransfer/">Stok Transfer</Link></Menu.Item>
                    </SubMenu>
                    <Menu.Item key="13" name="notifications" >
                        <DesktopOutlined />
                        <span>Bildirim Ayarları</span>
                  </Menu.Item>
                  */}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <Menu mode="horizontal" style={{ lineHeight: '48px', justifyContent: 'flex-end' }}>
                        <SubMenu key="sub4" title={<span className="submenu-title-wrapper"> <SettingOutlined /> {auth.data.contact.fullname}</span>}>
                            <Menu.Item key="setting:1" ><Link to={{ pathname: '/profile' }}>Profil Ayarları</Link></Menu.Item>
                            <Menu.Item key="setting:2" onClick={() => { dispatch(Logout()) }}>Çıkış Yap</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Header>
                <Content style={{ margin: '0 8px' }}>
                    <Breadcrumbs />
                    <div className="site-layout-background" style={{ padding: 5, minHeight: 360, overflow: 'scroll' }}>
                        <Outlet />

                    </div>

                </Content>
                <Footer style={{ textAlign: 'center' }}>Kardeş Payı Derneği ©2020 <a href='mailto:sefa@okumus.com'>Sefa Okumuş</a></Footer>
            </Layout>
        </Layout>
  )
}

// const AdminView = ({ children }) => {
//   const auth = useSelector((state) => state.auth)

//   const isAdmin = auth.data.role === 'admin' || auth.data.role === 'adminViewer'

//   return isAdmin ? children : null
// }
export default MainPageLayout
