import axios from 'axios'

import system from './config'

const GetFamilies = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetFamiliesTableView = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/tableview', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const GetFamily = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.get(system.webService + `/families/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddFamily = ({ token, family }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/add', family)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateFamily = ({ token, _id, family }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + `/families/update/${_id}`, family)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const DeleteFamily = ({ token, _id }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.delete(system.webService + `/families/${_id}`)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddChildToFamily = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/addchild', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const RemoveChildFromFamily = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/removechild', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const AddParentToFamily = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/addparent', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const RemoveParentFromFamily = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/removeparent', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

const UpdateFamilyContact = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common = { 'auth-token': `${token}` }
    axios.post(system.webService + '/families/updateFamilyContact', data)
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetFamilies,
  GetFamiliesTableView,
  GetFamily,
  AddFamily,
  UpdateFamily,
  DeleteFamily,
  AddChildToFamily,
  RemoveChildFromFamily,
  AddParentToFamily,
  RemoveParentFromFamily,
  UpdateFamilyContact
}
