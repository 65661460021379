import * as Actions from '../../actions/accounts'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getAccountsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ACCOUNTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_ACCOUNTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.SAVE_ACCOUNTS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.SAVE_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.sort((a, b) => a._id.localeCompare(b._id)),
        isLoading: false,
        error: false
      }
    }
    case Actions.SAVE_ACCOUNTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export default getAccountsReducer
