import { put, takeLatest, call } from 'redux-saga/effects'

import { GetAccountBalance } from '../../../services/accountsService'

import {
  GET_ACCOUNT_BALANCE,
  GET_ACCOUNT_BALANCE_SUCCESS,
  GET_ACCOUNT_BALANCE_ERROR
}
  from '../../actions/accounts'

function * getAccountBalanceSaga (action) {
  try {
    const data =  yield call(GetAccountBalance, action.payload)
    yield put({
      type: GET_ACCOUNT_BALANCE_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_ACCOUNT_BALANCE_ERROR, payload: error
    })
  }
}

export function * getAccountBalanceSagaWatcher () {
  yield takeLatest(GET_ACCOUNT_BALANCE, getAccountBalanceSaga)
}
