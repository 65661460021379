import { put, takeLatest, call } from 'redux-saga/effects'

import { AddUser } from '../../../services/usersService'
import {
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR
}
  from '../../actions/users'

function * addUserSaga (action) {
  try {
    const data = yield call(AddUser, action.payload)
    yield put({
      type: ADD_USER_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: ADD_USER_ERROR, error: error.data
    })
  }
}

export function * addUserSagaWatcher () {
  yield takeLatest(ADD_USER, addUserSaga)
}
