/* eslint-disable prefer-promise-reject-errors */
import React, { useState, useCallback, useMemo, useRef, forwardRef } from 'react'

import { Modal } from 'antd'
import t from 'prop-types'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

const noop = () => {}

const ImageCropper = forwardRef((props, ref) => {
  const {
    initialAspectRatio,
    guides,
    modalTitle,
    modalWidth,
    modalOk,
    modalCancel,

    beforeCrop,
    children
  } = props

  const [src, setSrc] = useState('')

  const beforeUploadRef = useRef()
  const fileRef         = useRef()
  const resolveRef      = useRef(noop)
  const rejectRef       = useRef(noop)

  const [cropper, setCropper] = useState()

  const getCroppedCanvas = () => {
    if (typeof cropper !== 'undefined') {
      return cropper.getCroppedCanvas()
    }
  }

  const renderUpload = useCallback(() => {
    const upload                                       = Array.isArray(children) ? children[0] : children
    const { beforeUpload, accept, ...restUploadProps } = upload.props
    beforeUploadRef.current                            = beforeUpload

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || 'image/*',
        beforeUpload: (file, fileList) =>
          new Promise((resolve, reject) => {
            if (beforeCrop && !beforeCrop(file, fileList)) {
              reject({ code: 'wrong-file', message: 'Wrong file type selected' })
              return
            }

            fileRef.current    = file
            resolveRef.current = resolve
            rejectRef.current  = reject

            const reader = new FileReader()
            reader.addEventListener('load', () => {
              setSrc(reader.result)
            })
            reader.readAsDataURL(file)
          })
      }
    }
  }, [beforeCrop, children])

  const modalProps = useMemo(() => {
    const obj = { width: modalWidth, okText: modalOk, cancelText: modalCancel }
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) delete obj[key]
    })
    return obj
  }, [modalCancel, modalOk, modalWidth])

  const onClose = () => setSrc('')

  const onOk = () => {
    onClose()
    const canvas = getCroppedCanvas() // document.createElement('canvas');
    // get the new image

    const { type, name, uid } = fileRef.current
    if (canvas) {
      canvas.toBlob(
        async (blob) => {
          let newFile = new File([blob], name, { type })
          newFile.uid = uid

          if (typeof beforeUploadRef.current !== 'function') return resolveRef.current(newFile)

          const res = beforeUploadRef.current(newFile, [newFile])

          if (typeof res !== 'boolean' && !res) {
            console.error('beforeUpload must return a boolean or Promise')
            return
          }

          if (res === true) return resolveRef.current(newFile)
          if (res === false) return rejectRef.current('not upload')
          if (res && typeof res.then === 'function') {
            try {
              const passedFile = await res
              const type       = Object.prototype.toString.call(passedFile)
              if (type === '[object File]' || type === '[object Blob]') newFile = passedFile
              resolveRef.current(newFile)
            } catch (err) {
              rejectRef.current(err)
            }
          }
        },
        type,
        0.4
      )
    }
  }

  return <>
        {renderUpload()}
        {src && (
            <Modal
                visible={true}
                title={modalTitle}
                onOk={onOk}
                onCancel={onClose}
                maskClosable={false}
                destroyOnClose
                width='80vw'
                {...modalProps}
            >
                <Cropper
                    style={{ height: 400, width: '100%' }}
                    initialAspectRatio={initialAspectRatio}
                    src={src}
                    // ref={ref}
                    viewMode={1}
                    guides={guides}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    dragMode='move'
                    rotatable={true}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                      setCropper(instance)
                    }}
                />

            </Modal>
        )}
    </>
})
ImageCropper.propTypes = {
  aspect: t.number,
  shape: t.oneOf(['rect', 'round']),
  grid: t.bool,

  modalTitle: t.string,
  modalWidth: t.oneOfType([t.number, t.string]),
  modalOk: t.string,
  modalCancel: t.string,
  beforeCrop: t.func,
  children: t.node
}
export default ImageCropper
