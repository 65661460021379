import * as Actions from '../../actions/families'

const initialState = {
  data: [],
  isLoading: false,
  error: false
}

const getFamiliesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FAMILIES: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_FAMILIES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_FAMILIES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.ADD_FAMILY: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.ADD_FAMILY_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload],
        isLoading: false,
        error: false
      }
    }
    case Actions.ADD_FAMILY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.UPDATE_FAMILY: case Actions.ADD_CHILD_TO_FAMILY: case Actions.REMOVE_CHILD_FROM_FAMILY: case Actions.ADD_PARENT_TO_FAMILY: case Actions.REMOVE_PARENT_FROM_FAMILY: case Actions.UPDATE_FAMILY_CONTACT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.UPDATE_FAMILY_SUCCESS: case Actions.ADD_CHILD_TO_FAMILY_SUCCESS: case Actions.REMOVE_CHILD_FROM_FAMILY_SUCCESS: case Actions.ADD_PARENT_TO_FAMILY_SUCCESS: case Actions.REMOVE_PARENT_FROM_FAMILY_SUCCESS: case Actions.UPDATE_FAMILY_CONTACT_SUCCESS:{
      const index       = state.data.findIndex(p => p._id === action.payload._id)
      state.data[index] = action.payload
      return {
        ...state,
        isLoading: false,
        error: false
      }
    }
    case Actions.UPDATE_FAMILY_ERROR: case Actions.ADD_CHILD_TO_FAMILY_ERROR: case Actions.REMOVE_CHILD_FROM_FAMILY_ERROR: case Actions.ADD_PARENT_TO_FAMILY_ERROR: case Actions.REMOVE_PARENT_FROM_FAMILY_ERROR:  case Actions.UPDATE_FAMILY_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }
    case Actions.DELETE_FAMILY: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.DELETE_FAMILY_SUCCESS: {
      return {
        ...state,
        data: state.data.filter(p => p._id !== action.payload._id),
        isLoading: false,
        error: false
      }
    }
    case Actions.DELETE_FAMILY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default getFamiliesReducer
