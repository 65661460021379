import { put, takeLatest, call } from 'redux-saga/effects'

import { GetParticipantContactsNames } from '../../../services/contactsService'

import {
  GET_PARTICIPANT_CONTACTS_NAMES,
  GET_PARTICIPANT_CONTACTS_NAMES_SUCCESS,
  GET_PARTICIPANT_CONTACTS_NAMES_ERROR
}
  from '../../actions/contacts'

function * getParticipantContactsNamesSaga (action) {
  try {
    const data = yield call(GetParticipantContactsNames, action.payload)
    yield put({
      type: GET_PARTICIPANT_CONTACTS_NAMES_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_PARTICIPANT_CONTACTS_NAMES_ERROR, payload: error
    })
  }
}

export function * getParticipantContactsNamesSagaWatcher () {
  yield takeLatest(GET_PARTICIPANT_CONTACTS_NAMES, getParticipantContactsNamesSaga)
}
