import { put, takeLatest, call } from 'redux-saga/effects'

import { DeleteParticipantContact } from '../../../services/contactsService'

import {
  DELETE_PARTICIPANT_CONTACT,
  DELETE_PARTICIPANT_CONTACT_SUCCESS,
  DELETE_PARTICIPANT_CONTACT_ERROR
}
  from '../../actions/contacts'

function * deleteParticipantContactSaga (action) {
  try {
    yield call(DeleteParticipantContact, action.payload)
    yield put({
      type: DELETE_PARTICIPANT_CONTACT_SUCCESS,
      payload: action.payload._id
    })
  } catch (error) {
    yield put({
      type: DELETE_PARTICIPANT_CONTACT_ERROR, payload: error
    })
  }
}

export function * deleteParticipantContactSagaWatcher () {
  yield takeLatest(DELETE_PARTICIPANT_CONTACT, deleteParticipantContactSaga)
}
