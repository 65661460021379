import React, { useEffect, useState } from 'react'

import { UnorderedListOutlined, CompassOutlined, SearchOutlined, TableOutlined } from '@ant-design/icons'
import { Tabs, Button, Input } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import { firebaseAuth } from '../../../firebaseApp'
import { GetFamiliesTableView } from '../../../store/actions/families'

import { useDebounce } from '../../../utils/customHooks'

import FamiliesList from './FamiliesList'
import FamiliesTable from './FamiliesTable'
import FamilyMap from './FamilyMap'

const Families = () => {
  const navigate  = useNavigate()
  const data      = useSelector((state) => state.families.tableview.data)
  const isLoading = useSelector((state) => state.families.tableview.isLoading)

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery                = useDebounce(searchQuery, 500)

  const [page, setPage]         = useState(localStorage.getItem('families.page') || 1)
  const [pageSize, setPageSize] = useState(localStorage.getItem('families.pageSize') || 10)
  const [tabKey, setTabKey]     = useState(localStorage.getItem('families.tabKey') || '1')

  const dispatch = useDispatch()
  useEffect(() => {
    getFamiliesTableView()
  }, [])

  useEffect(() => {
    getFamiliesTableView({
      query: debouncedQuery || null
    })
  }, [debouncedQuery])

  const getFamiliesTableView = (data) => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetFamiliesTableView({ token, data }))
    })
  }

  const handleSizeChange = (value) => {
    setPageSize(value)
    localStorage.setItem('families.pageSize', value)
  }

  const handlePageChange = (value) => {
    setPage(value)
    localStorage.setItem('families.page', value)
  }

  return <>
    <div>
      <Button type="primary" onClick={() => { navigate('/families/add') }}>Yeni Aile Ekle</Button>
    </div>

    <div style={{ marginBottom: 16 }}>
      <Input
        addonBefore="Ara"
        addonAfter={<SearchOutlined />}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>

    <Tabs activeKey={tabKey} onChange={(key) => { setTabKey(key); localStorage.setItem('families.tabKey', key) }}>
      <Tabs.TabPane tab={<span> <UnorderedListOutlined />Liste Görünümü </span>} key="1">
        <FamiliesList data={data} pageSize={pageSize} page={page}  setPage={handlePageChange} setPageSize={handleSizeChange} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<span> <TableOutlined />Tablo Görünümü </span>} key="2">
        <FamiliesTable data={data} pageSize={pageSize} page={page} setPage={handlePageChange} setPageSize={handleSizeChange} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<span> <CompassOutlined /> Harita Görünümü </span>} key="3" >
        <FamilyMap data={data} isLoading={isLoading} />
      </Tabs.TabPane>
    </Tabs>
  </>
}

export default Families
