import React, { useState, useEffect } from 'react'

import { Table, Tag } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { firebaseAuth } from '../firebaseApp'
import { GetTransactionsOfParticipant } from '../services/transactionsService'
const NestedTable = (props) => {
  const { participantID }         = props
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData]           = useState([])

  useEffect(() => {
    if (participantID) {
      setIsLoading(true)
      firebaseAuth.currentUser.getIdToken().then(token => {
        GetTransactionsOfParticipant({ token, _id: participantID }).then(d => {
          setIsLoading(false)
          setData(d)
        })
      })
    }
  }, [participantID])

  const nestedColumns = [
    { title: 'Tarih', dataIndex: 'date', key: 'date', render: (text, record) => (moment(record.date).format('DD.MM.YYYY')) },
    { title: 'Tutar', dataIndex: 'sourceTotal', key: 'sourceTotal' },
    { title: 'Bağış Tipi', dataIndex: 'donationType', key: 'donationType' },
    { title: 'Açıklama', dataIndex: 'note', key: 'note' },
    { title: 'Ödenene Hesap', dataIndex: ['destination', 'name'], key: 'destinationName' },
    {
      title: 'Ödeme Şekli',
      dataIndex: 'isPledge',
      key: 'isPledge',
      render: (text, record) => (
        <Tag className="nameTag" color={record.isPledge ? 'volcano' : ''} > {record.isPledge ? 'Taahüt' : 'Ekstra'} </Tag>
      )
    },
    {
      title: 'Düzenle',
      dataIndex: 'operation',
      key: 'operation',
      render: (text, record) => (
        <span className="table-operation">
          <Link to={{ pathname: `/transactions/donation/${record.groupCode}` }}>Düzenle</Link>
        </span>
      )
    }
  ]

  return <Table columns={nestedColumns}
    loading={isLoading}
    dataSource={data}
    rowKey='groupCode'
    pagination={false}
    size='small'
  />
}
export default NestedTable
