import { combineReducers } from 'redux'

import accounts from './accounts'
import auth from './auth'
import serverConfig from './config'
import families from './families'
import participants from './participants'
import statistics from './statistics'
import transactions from './transactions'
import users from './users'

const createReducer = (asyncReducers) =>
  combineReducers({
    accounts,
    auth,
    serverConfig,
    participants,
    families,
    statistics,
    transactions,
    users,
    ...asyncReducers
  })

export default createReducer
