import { put, takeLatest, call } from 'redux-saga/effects'

import { ChangeEmail } from '../../../services/usersService'

import {
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
  CHANGE_USER_EMAIL_ERROR
}
  from '../../actions/users'

function * changeUserEmailSaga (action) {
  try {
    const data = yield call(ChangeEmail, action.payload)
    yield put({
      type: CHANGE_USER_EMAIL_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: CHANGE_USER_EMAIL_ERROR, error: error.data
    })
  }
}

export function * changeUserEmailSagaWatcher () {
  yield takeLatest(CHANGE_USER_EMAIL, changeUserEmailSaga)
}
