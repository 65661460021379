import { put, takeLatest, call } from 'redux-saga/effects'

import { DeleteFamily } from '../../../services/familiesService'

import {
  DELETE_FAMILY,
  DELETE_FAMILY_SUCCESS,
  DELETE_FAMILY_ERROR
}
  from '../../actions/families'

function * deleteFamilySaga (action) {
  try {
    const data =  yield call(DeleteFamily, action.payload)
    yield put({
      type: DELETE_FAMILY_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: DELETE_FAMILY_ERROR, payload: error
    })
  }
}

export function * deleteFamilySagaWatcher () {
  yield takeLatest(DELETE_FAMILY, deleteFamilySaga)
}
