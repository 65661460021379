import { all } from 'redux-saga/effects'

import { addChildToFamilySagaWatcher } from './add_child_to_family.saga'
import { addFamilySagaWatcher } from './add_family.saga'
import { addParentToFamilySagaWatcher } from './add_parent_to_family.saga'
import { deleteFamilySagaWatcher } from './delete_family.saga'
import { getFamiliesSagaWatcher } from './get_families.saga'
import { getFamiliesTableViewSagaWatcher } from './get_families_tableview.saga'
import { getFamilySagaWatcher } from './get_family.saga'
import { removeChildFromFamilySagaWatcher } from './remove_child_from_family.saga'
import { removeParentFromFamilySagaWatcher } from './remove_parent_from_family.saga'
import { updateFamilySagaWatcher } from './update_family.saga'
import { updateFamilyContactSagaWatcher } from './update_family_contact.saga'

export default function * familiesSaga () {
  yield all([
    addChildToFamilySagaWatcher(),
    addParentToFamilySagaWatcher(),
    addFamilySagaWatcher(),
    deleteFamilySagaWatcher(),
    getFamiliesSagaWatcher(),
    getFamilySagaWatcher(),
    getFamiliesTableViewSagaWatcher(),
    addFamilySagaWatcher(),
    updateFamilySagaWatcher(),
    removeChildFromFamilySagaWatcher(),
    removeParentFromFamilySagaWatcher(),
    updateFamilyContactSagaWatcher()
  ])
}
