import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'

import { LoadingOutlined, MailOutlined } from '@ant-design/icons'
import { Col,  Divider, Form, Input, notification, Row, Select, Spin, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DebounceSelect from '../../../components/DebounceSelect'
import { firebaseAuth } from '../../../firebaseApp'
import { GetContacts } from '../../../services/contactsService'
import { GetUsers, AddUser, GetUserById, UpdateUser } from '../../../store/actions'
import { validateMessages } from '../../../utils/globalVars'

const { Title, Paragraph } = Typography
const key                  = 'notificationBoxKey'

const formItemLayout = {
  labelCol: {
    xl: { span: 7 },
    lg: { span: 8 },
    md: { span: 10 },
    sm: { span: 9 },
    xs: { span: 11 }

  },
  wrapperCol: {
    xl: { span: 17 },
    lg: { span: 16 },
    md: { span: 14 },
    sm: { span: 15 },
    xs: { span: 13 }
  }
}

const initialValues = {
  user: {
    role: 'familyManager',
    status: 'active'
  }
}

const UserForm = forwardRef(({ setIsSubmitting, hide }, ref) => {
  const dispatch = useDispatch()
  const [form]   = Form.useForm()
  const { _id }  = useParams()

  const initialData    = useSelector((state) => state.users.selected.data)
  const isLoading      = useSelector((state) => state.users.selected.isLoading)
  const isListUpdating = useSelector((state) => state.users.isLoading)

  const [selectedContact, setSelectedContact] = useState()
  const [actionClicked, setActionClicked]     = useState(false)

  useEffect(() => {
    if (_id) {
      firebaseAuth.currentUser.getIdToken().then((token) => {
        dispatch(GetUserById({ token, _id }))
      })
    }
  }, [_id])

  useEffect(() => {
    initialData ? fillForm(initialData) : clearForm()
  }, [initialData])

  useEffect(() => {
    if (actionClicked && isListUpdating === false) {
      setActionClicked(false)
      firebaseAuth.currentUser.getIdToken().then((token) => {
        dispatch(GetUsers({ token }))
      })
      clearForm()
      notification.success({
        key,
        message: 'Kayıt Edildi...',
        placement: 'bottomRight',
        duration: 0.5,
        onClose: () => {
          setIsSubmitting(false)
        }
      })
    }
  }, [isListUpdating])

  useImperativeHandle(ref, () => ({
    submitForm () { form.submit() },
    resetForm () { clearForm() }
  }))

  const clearForm = () => {
    form.resetFields()
    setSelectedContact(null)
  }

  const onFinish = ({ user }) => {
    setActionClicked(true)

    user.contact = {
      displayName: selectedContact.label,
      _id: selectedContact.value
    }

    notification.open({
      key,
      message: 'İşleniyor...',
      icon: <LoadingOutlined style={{ color: '#108ee9' }} />,
      placement: 'bottomRight'
    })

    setIsSubmitting(true)

    if (!_id) {
      firebaseAuth.currentUser.getIdToken().then((token) => {
        dispatch(AddUser({ token, user }))
      })
    } else {
      user._id = _id
      firebaseAuth.currentUser.getIdToken().then((token) => {
        dispatch(UpdateUser({ token, user }))
      })
      hide()
    }
  }

  const fillForm = (su) => {
    const userData = {
      firebaseuId: su.firebaseuId,
      contact: su.contact,
      email: su.email,
      role: su.role,
      status: su.status
    }
    setSelectedContact({
      label: su.contact.fullname,
      value: su.contact._id
    })

    form.setFieldsValue({
      user: userData
    })
  }

  const fetchContacts = async (debouncedQuery) => {
    return firebaseAuth.currentUser.getIdToken().then((token) =>
      GetContacts({
        token,
        data: {
          query: debouncedQuery || null,
          limit: 7
        }
      }).then((contacts) => contacts.docs.map((contact) => ({
        label: `${contact.fullname}`,
        value: contact._id,
        disabled: contact.disabled
      }))))
  }

  return (
    <Spin tip="Lütfen Bekleyin..." spinning={isLoading}>

      <Typography>
        <Title>{initialData ? 'Kullanıcı Bilgileri ' : 'Yeni Kullanıcı'}</Title>
        <Paragraph>
          Aşağıdaki formu kullanarak {initialData ? ' kullanıcı bilgilerini düzenleyebilirsiniz' : ' yeni bir kullanıcı ekleyebilirsiniz'}.
        </Paragraph>
        <Row>
          <Col span={24}>
            <Form form={form} {...formItemLayout} initialValues={initialValues} layout="horizontal" onFinish={onFinish} validateMessages={validateMessages}>

            <Form.Item
                name={['user', 'role']}
                label="Kullanıcı Tipi"
                rules={[{ required: true, message: 'Lütfen bir kullanıcı tipi seçin!' }]}
              >
                <Select placeholder="Kullanıcı Tipi">
                  <Select.Option value="admin">Yönetici</Select.Option>
                  <Select.Option value="adminViewer">Yönetici (Görüntülüyen)</Select.Option>
                  <Select.Option value="familyManager">Aile Sorumlusu</Select.Option>
                  <Select.Option value="participant">Katılımcı</Select.Option>
                  <Select.Option value="family">Aile Reisi</Select.Option>
                </Select>
              </Form.Item>

             <Form.Item label="Kişiler">
                  <DebounceSelect
                    showSearch
                    value={selectedContact}
                    placeholder="Kişi Ara"
                    fetchOptions={fetchContacts}
                    onChange={(newValue) => {
                      setSelectedContact(newValue)
                    }}
                    style={{
                      minWidth: 250
                    }}
                  />
                  </Form.Item>

              {!_id ? null : (<Form.Item
                name={['user', 'status']}
                label="Hesap Durumu"
                rules={[{ required: true, message: 'Lütfen kullanıcının mevcut kayıdını düzenleyin!' }]}
              >
                <Select placeholder="Kullanıcı Tipi">
                  <Select.Option value="active">Aktif</Select.Option>
                  <Select.Option value="suspended">Beklemede</Select.Option>
                  <Select.Option value="deactivated">Kapalı</Select.Option>
                </Select>
              </Form.Item>)}

              <Divider />
              <Form.Item
                name={['user', 'email']}
                label="Email"
                rules={[{ required: true, type: 'email' }]}
              >
                {_id ? <PlainText /> : <Input aria-label='email' autoComplete="new-email" />}
              </Form.Item>

              {_id ? null : (
                <>
                  <Form.Item label="Şifre" style={{ marginBottom: 0 }}>

                    <Form.Item
                      name={['user', 'password']}
                      rules={[{ required: true, message: 'Lütfen şifre giriniz!' }, { min: 6, message: 'Lütfen minimum 6 haneli bir şifre belirleyin' }]}
                      style={{
                        display: 'inline-block',
                        width: 'calc(50% - 3px)',
                        marginRight: 5
                      }}
                    >
                      <Input placeholder="Şifre" type="password" aria-label='password' autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                      name={['user', 'password2']}
                      dependencies={['user', 'password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen Şifrenizi doğrulayın!'
                        },
                        ({ getFieldValue }) => ({
                          validator (rule, value) {
                            if (!value || getFieldValue('user').password === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Girmiş olduğunuz şifreler uyuşmuyor!'))
                          }
                        })
                      ]}
                      style={{
                        display: 'inline-block',
                        width: 'calc(50% - 3px)'
                      }}
                    >
                      <Input placeholder="Şifre Tekrar" type="password" aria-label='password' autoComplete="new-password" />
                    </Form.Item>

                  </Form.Item>

                </>
              )}
            </Form>
          </Col>
        </Row>

      </Typography>
    </Spin>

  )
})
const PlainText = ({ value }) => <Typography.Text>{value ? <><MailOutlined /> {value}</> : null}</Typography.Text>

export default UserForm
