import React, { useState } from 'react'

import {
  InboxOutlined,
  CloseCircleFilled

} from '@ant-design/icons'
import { Form, Input, Upload, Row, Col, message, Spin, Modal, Badge } from 'antd'
import {  getDownloadURL, ref as sRef, uploadBytesResumable } from 'firebase/storage'
import moment from 'moment'

import AttachmentViewer from '../../../../components/AttachmentViewer'
import { firebaseStorage } from '../../../../firebaseApp'

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const DraggerAttachmentFormItem = ({ text, hint, root }) => {
  const [loading, setLoading]         = useState(false)
  const [visible, setVisible]         = useState(false)
  const [title, setTitle]             = useState('')
  const [previewItem, setPreviewItem] = useState('')

  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const handleChange = ({ info, add }) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false)

        add({
          name: info.file.name,
          url: info.file.xhr,
          date: moment(),
          fileType: info.file.type
        })
      })
    }
  }

  const beforeUpload = (file) => {
    const isFormatOk =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            file.type === 'application/pdf' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'audio/mpeg' ||
            file.type === 'video/mp4'
    if (!isFormatOk) {
      message.error('Sadece JPEG, PNG, PDF, XLSX, DOCX, MP3 ve MP4 formatlarında dosya yükleyebilirsiniz')
    }

    const isLt5M = file.size / 1024 / 1024 < 50
    if (!isLt5M) {
      message.error('Seçtiğiniz dosyalar 50MB dan düşük olmalı!')
    }
    return isFormatOk && isLt5M
  }

  const customUpload = ({ onError, onSuccess, file }) => {
    const folderName = moment().format('YYYY_MM') // store attachments into day folders
    // const fileName   = moment().format('YYYYMMDD_HHmmss')

    // create upload task for the new attachment
    // const uploadTask=storage.ref(`/${folderName}/${fileName}`).put(file)

    const uploadTask = uploadBytesResumable(sRef(firebaseStorage, `/${folderName}/${file.name}`), file)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      (err) => { console.log(err) },
      () => {
        // store image in firebase strorage
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onSuccess(null, downloadURL)
        }).catch((error) => console.log(error))
      })
  }

  return (

        <Spin spinning={loading} tip='Dosyalar yükleniyor'>

            <Form.List name={root}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            <Upload.Dragger
                                name="file"
                                multiple={true}
                                listType="text"
                                beforeUpload={beforeUpload}

                                onChange={(info) => handleChange({ info, add })}
                                customRequest={customUpload}
                                showUploadList={false}
                                style={{ width: '100%' }}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">{text || 'Dosya yüklemek için bu alana sürükle bırak yapabilirsiniz'} </p>
                                <p className="ant-upload-hint">
                                    {hint || 'Birden fazla dosya seçebilirsiniz . (Max : 5 mb)'}
                                </p>

                            </Upload.Dragger>

                            {fields.map((field, index) => (
                                <Row key={field.key} gutter={[2, 2]} style={{ marginTop: '10px' }}>

                                    <Col xs={19} sm={19} md={20} lg={21} xl={20}>
                                        <Form.Item label="Açıklama" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name={[field.name, 'description']}><Input /></Form.Item>
                                    </Col>
                                    <Col xs={5} sm={5} md={4} lg={3} xl={4}>
                                        <Badge style={{ cursor: 'pointer' }} count={<CloseCircleFilled style={{ color: '#f5222d' }} onClick={() => remove(field.name)}/>} >
                                            <Form.Item name={[field.name]} valuePropName='file'>
                                                <AttachmentViewer size={65} show={show} hide={hide} setTitle={setTitle} setPreviewItem={setPreviewItem} />
                                            </Form.Item>
                                        </Badge>
                                    </Col>
                                </Row>
                            ))}

                        </div>
                  )
                }}
            </Form.List>
            <Modal
                visible={visible}
                title={title}
                footer={null}
                onCancel={hide}
            >
                {previewItem}
            </Modal>
        </Spin>
  )
}

export default DraggerAttachmentFormItem
