import React, { useEffect, useState } from 'react'

import { FileExcelOutlined } from '@ant-design/icons'
import { Button, Form, Space, Table, Tag } from 'antd'
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom'

import DebounceSelect from '../../../components/DebounceSelect'
import { firebaseAuth } from '../../../firebaseApp'
import { GetAccounts } from '../../../services/accountsService'
import {  GetAccountBalances } from '../../../store/actions/accounts'
import { useQuery } from '../../../utils/customHooks'
import exportExcel from '../../../utils/exportExcel'
import { formatMoney } from '../../../utils/helpers'

export default function Balances () {
  const dispatch                              = useDispatch()
  const navigate                              = useNavigate()
  const queryParams                           = useQuery()
  const [selectedAccount, setSelectedAccount] = useState({ label: '', value: '' })

  const accountDetails = useSelector((state) => state.accounts.balances.data)
  const isLoading      = useSelector((state) => state.accounts.balances.isLoading)
  const auth           = useSelector((state) => state.auth)

  const query                       = queryParams.get('q')
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    defaultPageSize: 20
  })

  const columns = (mode) => [{
    title: 'Açılış Tarih',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (text, record, index) => {
      if (record.isSummaryRow) return ''

      return  moment(record.createdAt).format('DD.MM.YYYY')
    }
  },
  {
    title: 'Hesap Adı',
    dataIndex: ['name'],
    key: ['name'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''
      if (mode === 'export') return text

      return <Tag className='nameTag' color="pink" >{text}</Tag>
    },
    sorter: true
  },
  {
    title: 'Hesap Kodu',
    dataIndex: ['accountCode'],
    key: ['accountCode'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''

      return text
    },
    sorter: true
  },
  {
    title: 'Hesap Cinsi',
    dataIndex: ['currency'],
    key: ['currency'],
    render: (text, record) => {
      if (record.isSummaryRow) return ''

      return text
    },
    sorter: true
  },
  {
    title: 'Açılış Bakiyesi',
    dataIndex: 'initialBalance',
    key: 'initialBalance',
    render: (text, record) => {
      return formatMoney(text)
    },
    sorter: true
  },
  {
    title: 'Güncel Bakiye',
    dataIndex: 'balance',
    key: 'balance',
    render: (text, record) => {
      return formatMoney(text)
    },
    sorter: true
  }]

  useEffect(() => {
    if (query) {
      const fetchData = async (q) => {
        const data = await fetchAccounts(q)
        if (data.length > 0) { setSelectedAccount(data[0]) }
      }

      // check if user is admin
      if (auth.data.role === 'admin' || auth.data.role === 'adminViewer') {
        fetchData(query).catch(console.error)
      } else {
        fetchData(auth.data.contact.fullname).catch(console.error)
      }
    }
  }, [query, auth])
  useEffect(() => { getAccountBalance() }, [selectedAccount])
  useEffect(() => {
    setPagination((prev) => {
      return {
        ...prev,
        pageSize: accountDetails.limit,
        current: accountDetails.page,
        total: accountDetails.totalDocs
      }
    })
  }, [accountDetails])

  const getAccountBalance = (data) => {
    firebaseAuth.currentUser.getIdToken().then((token) => {
      dispatch(
        GetAccountBalances({
          token,
          data: {
            ...data,
            accountCode: selectedAccount.value
          }
        })
      )
    })
  }

  const handleTableChange = (pag, filters, sorter) => {
    getAccountBalance({
      sortField: sorter.field,
      sortOrder: sorter.order,
      page: pag.current,
      limit: pag.pageSize,
      ...filters
    })
  }

  const fetchAccounts = async (debouncedQuery) => {
    console.log('fetching accounts', debouncedQuery)

    return firebaseAuth.currentUser.getIdToken().then((token) =>
      GetAccounts({
        token,
        data: {
          query: debouncedQuery || null
        }
      }).then((accounts) => accounts.filter(a => a.accountCode.length < 7).map((account) => ({
        label: `${account.name} (${account.accountCode})`,
        value: account.accountCode
      }))))
  }

  const exportToExcel = () => {
    const exportData = [...accountDetails, { isSummaryRow: true }]
    exportExcel([{ headers: columns('export'), data: exportData, sheetName: `${selectedAccount.value} Nolu Hesaplar` }], 'Cari Mizan')
  }
  return (
    <>
      <Space align='start'>
        <Form.Item label="Hesaplar">
          <DebounceSelect
            showSearch
            value={selectedAccount}
            placeholder="Hesap Ara"
            fetchOptions={fetchAccounts}
            onChange={(newValue) => {
              navigate(`/accounts/balances?q=${newValue.value}`)
            }}
            style={{
              minWidth: 250
            }}
          />
        </Form.Item>

        <Button onClick={exportToExcel}>
          <FileExcelOutlined /> XLS İndir
        </Button>
      </Space>
      <Table
        dataSource={accountDetails}
        columns={columns()}
        bordered
        size="small"
        rowKey='groupCode'
        loading={isLoading}
        pagination={pagination}
        onChange={handleTableChange}
      >
      </Table>
    </>
  )
}
