import * as Actions from '../../actions/transactions'

const initialState = {
  data: null,
  isLoading: false,
  error: false
}

const getTransactionReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_TRANSACTION: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_TRANSACTION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_TRANSACTION_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default getTransactionReducer
