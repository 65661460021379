import React from 'react'

import { Tabs } from 'antd'

import ProfileForm from './ProfileForm'
import ResetPasswordForm from './ResetPasswordForm'

const Empty = (props) => {
  return (
    <Tabs tabPosition="left">
    <Tabs.TabPane tab="Kullanıcı Bilgilerim" key="1">
      <ProfileForm />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Şifre Ayarlarım" key="2">
      <ResetPasswordForm />
    </Tabs.TabPane>

  </Tabs>
  )
}
export default Empty
