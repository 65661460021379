import React, { useRef, useEffect } from 'react'

import { SaveFilled } from '@ant-design/icons'
import { Spin, message, Button } from 'antd'
import jexcel from 'jexcel'
import numeral from 'numeral'
import { useSelector, useDispatch } from 'react-redux'

import { firebaseAuth } from '../../../firebaseApp'
import { GetAccounts, SaveAccounts } from '../../../store/actions/accounts'

import { GetParticipantContactsNames } from '../../../store/actions/contacts'
import { GetFamiliesTableView } from '../../../store/actions/families'
import { jexcelText } from '../../../utils/globalVars'

import {  zeroPad } from '../../../utils/helpers'

import 'jexcel/dist/jexcel.css'
import './index.css'

const AccountsList = (props) => {
  const jexcelRef = useRef(null)

  const auth         = useSelector((state) => state.auth)
  const data         = useSelector((state) => state.accounts.list.data)
  const serverConfig = useSelector((state) => state.serverConfig.accounts)
  const isLoading    = useSelector((state) => state.accounts.list.isLoading)

  const headerRowStyle = (row, style) => {
    return Array(6).fill().map((_, i) => String.fromCharCode('A'.charCodeAt(0) + i)).reduce((acc, cur) => {
      acc[cur + row] = style
      return acc
    }, {})
  }

  const dispatch = useDispatch()
  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetParticipantContactsNames({ token }))
      dispatch(GetFamiliesTableView({ token }))
      dispatch(GetAccounts({ token }))
    })
  }, [])

  useEffect(() => {
    jexcelRef.current.jexcel && jexcelRef.current.jexcel.setData(data.filter(d => !serverConfig.defaults.map(r => r.root.value).some(root => d.accountCode.startsWith(root))))
  }, [data, serverConfig])

  useEffect(() => {
    if (jexcelRef.current.jexcel) { jexcelRef.current.jexcel.destroy(jexcelRef.current, true) }

    const options = {
      columns: [
        { type: 'text', title: 'Hesap Kodu', name: 'accountCode', width: 120 },
        { type: 'text', title: 'Hesap Adı', name: 'name', width: 350 },
        { type: 'dropdown', title: 'Hesap Cinsi', name: 'currency', width: 120, source: ['TRY', 'USD', 'EUR', 'GBP'] },
        { type: 'numeric', title: 'Başlangıç Bakiyesi', name: 'initialBalance', width: 120, mask: '#.##,00', decimal: ',' },
        { type: 'text', title: 'Grup', name: 'group', width: 120 },
        { type: 'hidden', name: 'createdBy' },
        { type: 'hidden', name: 'createdAt' },
        { type: 'hidden', name: 'updatedBy' },
        { type: 'hidden', name: 'updatedAt' }
      ],
      defaultColAlign: 'left',
      allowInsertColumn: false,
      allowDeleteColumn: false,
      allowRenameColumn: false,
      minDimensions: [4, 10],
      text: jexcelText,

      onchange: (instance, cell, x, y, value) => {
        if (x === '0') { jexcelRef.current.jexcel.setStyle(headerRowStyle(parseInt(y) + 1, value.split('.').length < 3 ? 'font-weight:bold' : 'font-weight:normal')) }
      },
      onbeforechange: (instance, cell, x, y, value) => {
        const regex = /^\d{3}(.\d{2}.\d{3}|.\d{2}$|$)/g
        if (x === '0') {
          if ((regex.test(value) || value === '') && !serverConfig.defaults.map(r => r.root.value).some(root => value.startsWith(root))) {
            return value
          } else if (serverConfig.defaults.map(r => r.root.value).some(root => value.startsWith(root))) {
            message.error('Server ayarları gereği hesap kodu şunlardan biri ile başlayamaz. [' + serverConfig.defaults.map(r => r.root.value).join(', ') + ']')
            return ''
          } else {
            message.error('Geçersiz hesap kodu. Hesap kodu \' ###.##.### \' şeklinde olmalıdır')
            return ''
          }
        }
      },
      onbeforeinsertrow: (instance, row) => {
        // return (jexcelRef.current.jexcel.getData().length!==row+1)
      },
      oninsertrow: (instance, row, col, trElem) => {
        if (row !== '0') { // if row is not instered to the top of the grid
          const codeAbove = jexcelRef.current.jexcel.getCell('A' + (parseInt(row) + 1)).innerText
          // if there is already a account code on row above
          if (codeAbove !== '') {
            const codeArr = codeAbove.split('.')
            if (codeArr.length === 1) { codeArr.push('01') } else if (codeArr.length === 2) { codeArr.push('001') } else if (codeArr.length > 2) { codeArr.push(zeroPad(parseInt(codeArr.pop()) + 1, 3)) }

            jexcelRef.current.jexcel.setValueFromCoords(0, parseInt(row) + 1, codeArr.join('.'))
            jexcelRef.current.jexcel.setValueFromCoords(2, parseInt(row) + 1, 'TRY')
          }
        }
      },
      onload: () => {
        const tableData = jexcelRef.current.jexcel.getJson()
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].accountCode.split('.').length < 3) {
            jexcelRef.current.jexcel.setStyle(headerRowStyle(i + 1, 'font-weight:bold'))
          }
        }
      },
      contextMenu: function (obj, x, y, e) {
        const items = []

        if (y !== null) {
          // Insert new row
          if (obj.options.allowInsertRow === true) {
            items.push({
              title: obj.options.text.insertANewRowAfter,
              onclick: function () {
                obj.insertRow(1, parseInt(y))
              }
            })
          }

          if (obj.options.allowDeleteRow === true) {
            items.push({
              title: obj.options.text.deleteSelectedRows,
              onclick: function () {
                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y))
              }
            })
          }
        }
        return items
      }
    }

    jexcel(jexcelRef.current, options)
    jexcelRef.current.jexcel.setData(data.filter(d => !serverConfig.defaults.map(r => r.root.value).some(root => d.accountCode.startsWith(root))))
  }, [serverConfig])

  const saveAccounts = () => {
    const grid = jexcelRef.current.jexcel.getJson()

    grid.map(row => {
      row.initialBalance = numeral(row.initialBalance).value()

      if (row.createdBy === '') { row.createdBy = auth.data.contact.fullname } else { row.updatedBy = auth.data.contact.fullname }

      return row
    })

    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(SaveAccounts({ token, accounts: grid }))
    })
  }

  return (

    <Spin tip="Lütfen Bekleyin..." spinning={isLoading}>
      <div>
        <div ref={jexcelRef} />
        <br />
        <Button type="primary" disabled={auth.data.role !== 'admin'} onClick={saveAccounts}><SaveFilled /> Hesapları Kaydet</Button>
      </div>
      {/* <Prompt
        when={!isSaved}
        message="Tabloyu kayıt etmediniz. Devam etmek istediğinizden emin minisiniz?"
      /> */}
    </Spin>

  )
}

export default AccountsList
