import React from 'react'

import { Empty, Avatar } from 'antd'

import wordSvg from '../images/doc.svg'
import mp3Svg from '../images/mp3.svg'
import mp4Svg from '../images/mp4.svg'
import pdfSvg from '../images/pdf.svg'
import excelSvg from '../images/xls.svg'

const AttachmentViewer = ({ file, size, padding, show, hide, setTitle, setPreviewItem }) => {
  const style = {
    height: (size + (padding * 2)) + (size / 3),
    width: (size + (padding * 2)),
    padding
  }
  let fileType

  switch (file.fileType) {
    case 'image/jpeg':
      fileType = 'jpg'
      break
    case 'image/png':
      fileType = 'png'
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      fileType = 'docx'
      break
    case 'application/pdf':
      fileType = 'pdf'
      break
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      fileType = 'xlsx'
      break
    case 'audio/mpeg':
      fileType = 'mp3'
      break
    case 'video/mp4':
      fileType = 'mp4'
      break
    default:
      fileType = 'jpg'
      break
  }

  if (fileType === 'jpg' || fileType === 'png') {
    return <span className='attachment' style={style}>
            <Avatar shape="square" src={file.url} size={size} onClick={() => {
              show()
              setTitle(file.name)
              setPreviewItem(<img alt="Resim" style={{ width: '100%' }} src={file.url} />)
            }} />

            <span className="attachmentName">{file.name}</span>
        </span>
  } else if (fileType === 'pdf') {
    return <span className='attachment' style={style}>
            <Avatar shape="square" src={pdfSvg} size={size} onClick={() => {
              window.open(file.url, '_blank')
            }} />
            <span className="attachmentName">{file.name}</span>

        </span>
  } else if (fileType === 'xlsx') {
    return <span className='attachment' style={style}><Avatar shape="square" src={excelSvg} size={size} onClick={() => {
      window.open(file.url, '_blank')
    }} />
            <span className="attachmentName">{file.name}</span>
        </span>
  } else if (fileType === 'docx') {
    return <span className='attachment' style={style}><Avatar shape="square" src={wordSvg} size={size} onClick={() => {
      window.open(file.url, '_blank')
    }} />
            <span className="attachmentName">{file.name}</span>
        </span>
  } else if (fileType === 'mp3') {
    return <span className='attachment' style={style}><Avatar shape="square" src={mp3Svg} size={size} onClick={() => {
      show()
      setTitle('MP3 Görüntüleyici')
      setPreviewItem(<audio style={{ width: '100%' }} controls >  <source src={file.url} type="audio/mpeg" /></audio>)
    }} />
            <span className="attachmentName">{file.name}</span>
        </span>
  } else if (fileType === 'mp4') {
    return <span className='attachment' style={style}><Avatar shape="square" src={mp4Svg} size={size} onClick={() => {
      show()
      setTitle('Video Görüntüleyici')
      setPreviewItem(<video width='100%' controls> <source src={file.url} type="video/mp4" /></video>)
    }} />
            <span className="attachmentName">{file.name}</span>
        </span>
  } else { return <Empty /> }
}
AttachmentViewer.defaultProps = {
  size: 48,
  padding: 4
}

export default AttachmentViewer
