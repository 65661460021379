import axios from 'axios'

import system from './config'

const    GetConfig = () => {
  return new Promise((resolve, reject) => {
    axios.get(system.webService + '/config/get')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetConfig
}
