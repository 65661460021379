import React, { useEffect } from 'react'

import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined

} from '@ant-design/icons'
import { Form, Input, Button, Row, Col, DatePicker, Select, InputNumber, Card, Divider } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'

import PhotoUploader from '../../../../components/PhotoUploader'
import EducationFormItem from '../FormItems/EducationFormItem'
import HealthFormItem from '../FormItems/HealthFormItem'
import PicturesAttachmentFormItem from '../FormItems/PicturesAttachmentFormItem'

const ContactForm = (params) => {
  const { form, fieldName, initialValues, visible, validateMessages } = params

  useEffect(() => {
    form && form.resetFields()
  }, [form, initialValues, visible])

  return (
        <Form form={form} layout="vertical" name={fieldName + 'Form'} initialValues={initialValues} validateMessages={validateMessages}>

            <Form.Item style={{ display: 'none' }} name={['_id']}> <Input hidden type='hidden' /></Form.Item>
            <Form.Item name={['profilePhotoURL']} label='Profil Resmi'>
                <PhotoUploader />
            </Form.Item>

            <Form.Item name={['firstname']} label="Adı" rules={[{ required: true }]}><Input /></Form.Item>
            <Form.Item name={['middlename']} label="İkinci Adı"><Input /></Form.Item>
            <Form.Item name={['lastname']} label="Soyadı" ><Input /></Form.Item>
            <Form.Item name={['gender']} label="Cinsiyet" rules={[{ required: true }]}>
                <Select >
                    <Select.Option value='m'>Erkek</Select.Option>
                    <Select.Option value='f'>Kadın</Select.Option>
                </Select>
            </Form.Item>

            <Divider />

            <Form.Item name={['identityNo']} label="Kimlik Numarası" ><Input /></Form.Item>
            <Form.Item name={['nationality']} label="Uyruk" ><Input /></Form.Item>

            <Divider />

            <Form.List name={['emails']}  >
                {(fields, { add, remove }) => {
                  return <ListGenerator
                        fieldName='Email'
                        valueText='Email Adresi'
                        valueType='input'
                        selectOptions={[{ value: 'primary', text: 'Genel' }, { value: 'work', text: 'İş' }, { value: 'other', text: 'Diğer' }]}
                        fields={fields}
                        add={() => add({ name: 'primary' })}
                        remove={remove}
                    />
                }}
            </Form.List>
            <Form.List name={['phones']}  >
                {(fields, { add, remove }) => {
                  return <ListGenerator
                        fieldName='Telefon'
                        valueText='Telefon Numarası'
                        valueType='input'
                        selectOptions={[{ value: 'mobile', text: 'Mobil' }, { value: 'home', text: 'Ev' }, { value: 'work', text: 'İş' }, { value: 'other', text: 'Diğer' }]}
                        fields={fields}
                        add={() => add({ name: 'mobile' })}
                        remove={remove}
                    />
                }}
            </Form.List>

            <Form.List name={['addresses']}  >
                {(fields, { add, remove }) => {
                  return <ListGenerator
                        fieldName='Adres'
                        valueText='Adres Bilgisi'
                        valueType='textarea'
                        selectOptions={[{ value: 'home', text: 'Ev' }, { value: 'work', text: 'İş' }, { value: 'other', text: 'Diğer' }]}
                        fields={fields}
                        add={() => add({ name: 'home' })}
                        remove={remove}

                    />
                }}
            </Form.List>

            <Form.Item name={['profession']} label="Meslek" >
                <Input />
            </Form.Item>

            <Form.Item name={['monthlyIncome']} label="Aylık Gelir" >
                <InputNumber />
            </Form.Item>

            <Divider />
            <Form.Item label="Banka Bilgileri" >
                <Form.Item name={['bankAccount', 'accountName']} label="Hesap Adı"><Input /></Form.Item>
                <Form.Item name={['bankAccount', 'accountNo']} label="Hesap No"><Input /></Form.Item>
                <Form.Item name={['bankAccount', 'IBAN']} label="IBAN No"><Input /></Form.Item>
                <Form.Item name={['bankAccount', 'bankName']} label="Banka Adı"><Input /></Form.Item>
                <Form.Item name={['bankAccount', 'bankBranch']} label="Banka Şubesi"><Input /></Form.Item>

            </Form.Item>

            <Divider />

            <Form.Item name={['pob']} label="Doğum Yeri" ><Input /></Form.Item>

            <Form.Item name={['dob']} label="Doğum Tarihi">
                <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name={['dod']} label="Ölüm Tarihi">
                <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name={['bloodGroup']} label="Kan Grubu">
                <Select allowClear >
                    <Select.Option value='0rh+'>0 Rh Pozitif</Select.Option>
                    <Select.Option value='arh+'>A Rh Pozitif</Select.Option>
                    <Select.Option value='brh+'>B RhD Pozitif</Select.Option>
                    <Select.Option value='abrh+'>AB RhD Pozitif</Select.Option>
                    <Select.Option value='0rh-'>0 Rh Negatif</Select.Option>
                    <Select.Option value='arh-'>A RhD Negatif</Select.Option>
                    <Select.Option value='brh-'>B Rhd Negatif</Select.Option>
                    <Select.Option value='abrh-'>Ab RhD Negatif</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name={['note']} label="Özel Not">
                <Input.TextArea />
            </Form.Item>

            <Form.Item label="Ölçüler">
                <Input.Group compact >
                    <Form.Item name={['height']} label="Boy">
                        <InputNumber formatter={value => `${value} cm`}
                            parser={value => value.replace(' cm', '')} />
                    </Form.Item>

                    <Form.Item name={['weight']} label="Kilo">
                        <InputNumber formatter={value => `${value} kg`}
                            parser={value => value.replace(' kg', '')} />
                    </Form.Item>

                    <Form.Item name={['clothSize']} label="Kıyafet Ölçüsü">
                        <Input style={{ width: '100px' }} />
                    </Form.Item>

                    <Form.Item name={['shoeSize']} label="Ayakkabı Ölçüsü">
                        <Input style={{ width: '100px' }} />
                    </Form.Item>

                </Input.Group>
            </Form.Item>

            <Form.List name={['educationHistory']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? 'Eğitim Geçmişi' : ''}
                                    key={field.key}
                                >
                                    <Card actions={[<DeleteOutlined key="delete" onClick={() => remove(field.name)} />]} >
                                        <EducationFormItem root={field.name} />
                                    </Card>

                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                                    <PlusOutlined /> Eğitim Geçmişi Ekle</Button>
                            </Form.Item>
                        </div>
                  )
                }}
            </Form.List>

            <Form.Item label='Sağlık Geçmişi'>
                <HealthFormItem root='healthInfo' />
            </Form.Item>

            <Form.Item name={['photos']} label='Fotoğraflar'>
                <PicturesAttachmentFormItem />
            </Form.Item>

        </Form>
  )
}
ContactForm.defaultProps = {
  initialValues: {
    gender: 'm'
  }
}

const ListGenerator = (params) => {
  const { fieldName, valueText, valueType, selectOptions, fields, add, remove } = params
  return (
        <div>
            {fields.map((field, index) => (
                <Row key={field.key}>
                    <Col span={10} >
                        <Form.Item
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24, offset: 0 }}
                            name={[field.name, 'name']}
                            label={index === 0 ? fieldName + ' Tipi' : ''}
                        >
                            <Select allowClear value={selectOptions[0].value} >
                                {selectOptions.map((option, i) => <Select.Option key={i} value={option.value}>{option.text}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            name={[field.name, 'value']}
                            label={index === 0 ? valueText : ''}
                            rules={[{ type: fieldName === 'Email' ? 'email' : 'any' }]}
                        >
                            {valueType === 'input' ? <Input /> : valueType === 'textarea' ? <Input.TextArea /> : valueType === 'inputNumber' ? <InputNumber /> : null}
                        </Form.Item>
                    </Col>
                    <Col span={4} >

                        <Form.Item label={index === 0 ? 'Kaldır' : ''} >
                            <MinusCircleOutlined className="dynamic-delete-button"
                                onClick={() => {
                                  remove(field.name)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            ))}
            <Form.Item wrapperCol={{ span: 24 }} >
                <Button type="dashed" onClick={() => { add() }} style={{ width: '100%' }} >
                    <PlusOutlined /> {fieldName} Ekle
                </Button>
            </Form.Item>
        </div>
  )
}
export default ContactForm
