import React, { useState, useEffect, useRef } from 'react'

import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default function Map ({ options, onMount, className, style }) {
  const ref           = useRef()
  const [map, setMap] = useState()

  useEffect(() => {
    if (ref.current) {
      setMap((prev) => {
        const m = L.map(ref.current).setView(options.center, options.zoom)

        L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png?{foo}', { foo: 'bar', attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }).addTo(m)
        return m
      })
    }
  }, [ref])

  useEffect(() => {
    if (map) {
      if (onMount) { onMount(map) }
    }
  }, [map, onMount])

  return (
        <div ref={ref} className={className} style={{
          ...style,
          height: style && style.height ? style.height : '70vh',
          borderRadius: '0.5em',
          boxShadow: '0px 1px 5px 1px rgba(0,0,0,0.15)',
          zIndex: 1

        }} />
  )
}

Map.defaultProps = {
  options: {
    center: [8, 48],
    zoom: 5
  }
}
