import * as Actions from '../../actions/statistics/index.js'

const initialState = {
  data: {},
  isLoading: false,
  error: false
}

const statistics = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_STATISTICS: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_STATISTICS_SUCCESS: {
      return {
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_STATISTICS_ERROR: {
      return {
        data: {},
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default statistics
