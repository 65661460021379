import { put, takeLatest, call } from 'redux-saga/effects'

import { GetTransactions } from '../../../services/transactionsService'

import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR
}
  from '../../actions/transactions/'

function * getTransactionsSaga (action) {
  try {
    const data =  yield call(GetTransactions, action.payload)
    yield put({
      type: GET_TRANSACTIONS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_TRANSACTIONS_ERROR, payload: error
    })
  }
}

export function * getTransactionsSagaWatcher () {
  yield takeLatest(GET_TRANSACTIONS, getTransactionsSaga)
}
