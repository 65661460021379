import { writeFile } from 'xlsx'

// exportExcel  sheets=[{headers:[],data:[],sheetName:""}] fileName =""
function exportExcel (sheets, fileName = 'dowloadFile') {
  const sheetsArr = sheets.map(sheet => {
    const _headers = sheet.headers.map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: getPostition(i) + 1 })).reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {})
    const dataArr  = sheet.data
      .map((item, i) => sheet.headers.map((head, j) => {
        let content = ''
        if (head.render) {
          content = head.render(item[head.dataIndex], item)
        } else {
          content = item[head.dataIndex]
        }
        return { content, position: getPostition(j) + (i + 2) }
      }
      ))
    //  Perform dimensionality reduction on the result just now (two-dimeensional array becomes one-dimensional array)
    const _data = dataArr.reduce((prev, next) => prev.concat(next))
    //  Structure required for conversion to worksheet
      .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content } }), {})

    //  Combine headers and data
    const output = Object.assign({}, _headers, _data)
    //  Get the position of all cells
    const outputPos = Object.keys(output)
    //  Calculate the range ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`
    return Object.assign(
      { sheetName: sheet.sheetName },
      output,
      {
        '!ref': ref,
        '!cols': getColWidth(sheet.headers, dataArr)
      }
    )
  })
  const sheetNames = sheetsArr.map(sheet => sheet.sheetName)
  const wbSheets   = sheetsArr.reduce((prev, next) =>
    Object.assign({}, prev, { [next.sheetName]: next }), {})
  //  Build the workbook object
  const wb = {
    SheetNames: sheetNames,
    Sheets: wbSheets
  }
  //  Export to Excel
  writeFile(wb, fileName + '.xlsx')
}

function getPostition (index) {
  let result = String.fromCharCode(65 + parseInt(index % 26))
  let value  = index / 26
  while (value >= 1) {
    result = String.fromCharCode(65 + parseInt(value % 26 - 1)) + result
    value  = parseInt(value / 26)
  }
  return result
}
function getColWidth (headers, dataArr) {
  const allWch = [headers].concat(dataArr).map(item => item.map(val => {
    const value = val.title || val.content || ''
    let length  = 10
    /* First determine whether it is null/undefined */
    if (value) {
      /* Then judge whether it is Chinese */
      if (value.toString().charCodeAt(0) > 255) {
        length = value.toString().length * 2
      } else {
        length = value.toString().length
      }
    }
    return {
      wch: length < 40 ? length : 40
    }
  }))
  /* Initial value in the first line */
  const colWidth = allWch[0]
  for (let i = 1; i < allWch.length; i++) {
    for (let j = 0; j < allWch[i].length; j++) {
      if (colWidth[j].wch < allWch[i][j].wch) {
        colWidth[j].wch = allWch[i][j].wch
      }
    }
  }
  return colWidth
}
export default exportExcel
