import axios from 'axios'

import system from './config'

const GetStatistics = () => {
  return new Promise((resolve, reject) => {
    axios.get(system.webService + '/statistics')
      .then(response => resolve(response.data))
      .catch((error) => { reject(error.response) })
  })
}

export {
  GetStatistics
}
