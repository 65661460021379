import { combineReducers } from 'redux'

import list from './families.reducer'
import tableview from './families_tableview.reducer'
import selectedFamily from './family.reducer'

const families = combineReducers({
  list,
  selectedFamily,
  tableview
})

export default families
