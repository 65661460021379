import * as Actions from '../../actions/families'

const initialState = {
  data: {},
  isLoading: false,
  error: false
}

const getFamilyReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FAMILY: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_FAMILY_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_FAMILY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    case Actions.DELETE_FAMILY: {
      if (state.data.id === action.payload._id) {
        return {
          ...state,
          isLoading: true,
          error: false
        }
      }
      return state
    }
    case Actions.DELETE_FAMILY_SUCCESS: {
      if (state.data.id === action.payload._id) {
        return {
          ...state,
          data: {},
          isLoading: false,
          error: false
        }
      }
      return state
    }
    case Actions.DELETE_FAMILY_ERROR: {
      if (state.data.id === action.payload._id) {
        return {
          ...state,
          isLoading: false,
          error: action.payload
        }
      }
      return state
    }

    default: {
      return state
    }
  }
}

export default getFamilyReducer
