import { put, takeLatest, call } from 'redux-saga/effects'

import { GetFamiliesTableView } from '../../../services/familiesService'

import {
  GET_FAMILIES_TABLEVIEW,
  GET_FAMILIES_TABLEVIEW_SUCCESS,
  GET_FAMILIES_TABLEVIEW_ERROR
}
  from '../../actions/families'

function * getFamiliesTableViewSaga (action) {
  try {
    const data = yield call(GetFamiliesTableView, action.payload)
    yield put({
      type: GET_FAMILIES_TABLEVIEW_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_FAMILIES_TABLEVIEW_ERROR, payload: error
    })
  }
}

export function * getFamiliesTableViewSagaWatcher () {
  yield takeLatest(GET_FAMILIES_TABLEVIEW, getFamiliesTableViewSaga)
}
