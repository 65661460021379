import { put, takeLatest, call } from 'redux-saga/effects'

import { GetTransaction } from '../../../services/transactionsService'

import {
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR
}
  from '../../actions/transactions'

function * getTransactionSaga (action) {
  try {
    if (!action.payload._id) { yield put({ type: GET_TRANSACTION_SUCCESS, payload: null }) } else {
      const data = yield call(GetTransaction, action.payload)
      yield put({
        type: GET_TRANSACTION_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    yield put({
      type: GET_TRANSACTION_ERROR, payload: error
    })
  }
}

export function * getTransactionSagaWatcher () {
  yield takeLatest(GET_TRANSACTION, getTransactionSaga)
}
