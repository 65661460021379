/* eslint-disable default-case */
import React, { useState, useRef } from 'react'

import { Drawer, Button, Grid } from 'antd'

import { useWindowSize } from '../utils/customHooks'
const { useBreakpoint } = Grid

const FormDrawer = (props) => {
  const { visible, hide, submitBtnText, cancelBtnText, formElement: FormElement, formStates, disableSubmit } = props
  const [isSubmitting, setIsSubmitting]                                                                      = useState(false)

  const screens = useBreakpoint()
  const ws      = useWindowSize()
  const formRef = useRef()

  const drawerWidth = (width) => {
    if (screens.xs) { return width } else if (screens.sm && !screens.md) { return width * 2 / 3 } else if (screens.md && !screens.xl) { return width / 2 } else { return width / 3 }
  }

  const handleSubmit = () => {
    formRef.current.submitForm()
  }

  const handleClose = () => {
    formRef.current.resetForm()
    hide()
  }

  return (
        <>
            <Drawer
                onClose={handleClose}
                width={drawerWidth(ws.width)}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={<div style={{ textAlign: 'right' }}
                >
                    <Button onClick={handleClose} style={{ marginRight: 8 }}>
                        {cancelBtnText}
                    </Button>
                    <Button onClick={handleSubmit} disabled={isSubmitting || disableSubmit} type="primary">
                        {submitBtnText}
                    </Button>
                </div>
                }
            >
                <FormElement ref={formRef} setIsSubmitting={setIsSubmitting} {...formStates} hide={hide} />
            </Drawer>
        </>
  )
}
FormDrawer.defaultProps = {
  submitBtnText: 'Kaydet',
  cancelBtnText: 'İptal'
}

export default FormDrawer
