import { put, takeLatest, call } from 'redux-saga/effects'

import { GetUsers } from '../../../services/usersService'
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR
}
  from '../../actions/users'

function * getUserSaga (action) {
  try {
    const data = yield call(GetUsers, action.payload)
    yield put({
      type: GET_USERS_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: GET_USERS_ERROR, error: error.data
    })
  }
}

export function * getUsersSagaWatcher () {
  yield takeLatest(GET_USERS, getUserSaga)
}
