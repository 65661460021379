/* eslint-disable react/no-children-prop */
/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'

import { ExclamationCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Input, DatePicker, Select, InputNumber, Typography, Switch, Spin, Row, Col, Tooltip, notification, Modal } from 'antd'
import locale from 'antd/es/date-picker/locale/tr_TR'
import moment from 'moment'
import numeral from 'numeral'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { IconFromStringComp } from '../../../components/IconFromStringComp'

import { firebaseAuth } from '../../../firebaseApp'
import { GetRate } from '../../../services/exchangeRateService'
import { GetAccounts } from '../../../store/actions/accounts'
import { AddTransaction, UpdateTransaction, GetTransaction } from '../../../store/actions/transactions'
import { useForceUpdate  } from '../../../utils/customHooks'
import { validateMessages } from '../../../utils/globalVars'
import {  s2v } from '../../../utils/helpers'

const { Title, Paragraph } = Typography
const { Option }           = Select
const { confirm }          = Modal

const key = 'notificationBoxKey'

const TransactionForm = forwardRef(({ setIsSubmitting, hide, transactionType, getTransactions }, ref) => {
  const dispatch    = useDispatch()
  const [form]      = Form.useForm()
  const { _id }     = useParams()
  const forceUpdate = useForceUpdate()

  const auth                 = useSelector((state) => state.auth)
  const accounts             = useSelector((state) => state.accounts.list.data)
  const areAccountsLoading   = useSelector((state) => state.accounts.list.isLoading)
  const initialData          = useSelector((state) => state.transactions.transaction.data)
  const isTransactionLoading = useSelector((state) => state.transactions.transaction.isLoading)
  const isListUpdating       = useSelector((state) => state.transactions.list.isLoading)
  const serverConfig         = useSelector((state) => state.serverConfig)

  const [actionClicked, setActionClicked]             = useState(false)
  const [date, setDate]                               = useState(moment())
  const [sourceCurrency, setSourceCurrency]           = useState('TRY')
  const [destinationCurrency, setDestinationCurrency] = useState('TRY')
  const [loadingExchangeRate, setLoadingExchangeRate] = useState(false)
  const [screenTotal, setScreenTotal]                 = useState({ sourceTotal: 0, destinationTotal: 0 })

  const initialValues = {
    transaction: {
      date: moment(),
      sourceTotal: {},
      exchangeRate: {},
      destinationTotal: {},
      isPledge: serverConfig.transactions.donationTypes.reduce((r, a) => {
        r[s2v(a)] = true
        return r
      }, {})

    }
  }

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      dispatch(GetAccounts({ token }))
    })
  }, [])

  useEffect(() => {
    firebaseAuth.currentUser.getIdToken().then(token => {
      _id
        ? dispatch(GetTransaction({ token, _id }))
        : dispatch(GetTransaction({ token, _id: null }))
    })
  }, [_id])
  useEffect(() => { initialData ? fillForm(initialData) : form.resetFields() }, [initialData])
  useEffect(() => {
    if (actionClicked && isListUpdating === false) {
      setActionClicked(false)

      getTransactions()
      form.resetFields()
      refreshScreenTotals()
      notification.success({
        key,
        message: 'Kayıt Edildi...',
        placement: 'bottomRight',
        duration: 0.5,
        onClose: () => {
          setIsSubmitting(false)
        }
      })
    }
  }, [isListUpdating])

  useEffect(() => {
    const transaction            = form.getFieldValue('transaction')
    transaction.sourceTotal      = transaction.sourceTotal || {}
    transaction.exchangeRate     = transaction.exchangeRate || {}
    transaction.destinationTotal = transaction.destinationTotal || {}

    if (sourceCurrency && destinationCurrency) {
      if (sourceCurrency === destinationCurrency) {
        serverConfig.transactions.donationTypes.forEach(dt => {
          const dtType = s2v(dt)

          if (!transaction.exchangeRate[dtType]) {
            transaction.exchangeRate[dtType]     = 1
            transaction.destinationTotal[dtType] = ((transaction.sourceTotal[dtType] || 0) * (transaction.exchangeRate[dtType] || 0)).toFixed(3)
          }
        })
        form.setFieldsValue({ transaction })
        refreshScreenTotals()
      } else {
        setLoadingExchangeRate(true)

        GetRate({ base: sourceCurrency }).then(result => {
          setLoadingExchangeRate(false)

          serverConfig.transactions.donationTypes.forEach(dt => {
            const dtType = s2v(dt)

            if (!transaction.exchangeRate[dtType] || transaction.exchangeRate[dtType] === 1) {
              transaction.exchangeRate[dtType]     = result.conversion_rates[destinationCurrency].toFixed(3)
              transaction.destinationTotal[dtType] = ((transaction.sourceTotal[dtType] || 0) * (transaction.exchangeRate[dtType] || 0)).toFixed(3)
            }
          })
          form.setFieldsValue({ transaction })
          refreshScreenTotals()
        })
      }
    }
  }, [sourceCurrency, destinationCurrency, date])

  useImperativeHandle(ref, () => ({
    submitForm () { form.submit() },
    resetForm () {
      form.resetFields()
      refreshScreenTotals()
    }

  }))

  const handleSourceTotalChange = () => {
    const transaction = form.getFieldValue('transaction')

    if (sourceCurrency !== destinationCurrency) {
      Object.keys(transaction.sourceTotal).forEach(stk => {
        transaction.destinationTotal[stk] = ((transaction.sourceTotal[stk] || 0) * (transaction.exchangeRate[stk] || 0)).toFixed(3)
      })
      form.setFieldsValue({ transaction })
    }
    refreshScreenTotals()
  }

  const handleDestionationTotalChange = () => {
    const transaction = form.getFieldValue('transaction')

    Object.keys(transaction.destinationTotal).forEach(stk => {
      transaction.sourceTotal[stk] = ((transaction.destinationTotal[stk] || 0) / (transaction.exchangeRate[stk] || 1)).toFixed(3)
    })
    form.setFieldsValue({ transaction })
    refreshScreenTotals()
  }

  const refreshScreenTotals = () => {
    const transaction = form.getFieldValue('transaction')
    let sourceTotal   = 0; let destinationTotal = 0

    serverConfig.transactions.donationTypes.forEach(stk => {
      sourceTotal      += (parseFloat(transaction.sourceTotal[s2v(stk)]) || 0)
      destinationTotal += (parseFloat(transaction.sourceTotal[s2v(stk)] || 0) * parseFloat(transaction.exchangeRate[s2v(stk)] || 0))
    })

    setScreenTotal({ sourceTotal: parseFloat(sourceTotal.toFixed(2)), destinationTotal: parseFloat(destinationTotal.toFixed(2)) })
    forceUpdate()
  }

  const onFinish = ({ transaction }) => {
    setActionClicked(true)

    transaction.transactionType = transactionType

    const foundSource       = accounts.find(a => a.accountCode === transaction.source)
    const foundDest         = accounts.find(a => a.accountCode === transaction.destination)
    transaction.source      = { accountCode: foundSource.accountCode, name: foundSource.name }
    transaction.destination = { accountCode: foundDest.accountCode, name: foundDest.name }

    const sourceTotalObj = Object.keys(transaction.sourceTotal).reduce((r, a) => {
      r[a] = parseFloat(transaction.sourceTotal[a]) || 0
      return r
    }, {})

    const destinationTotalObj = Object.keys(transaction.destinationTotal).reduce((r, a) => {
      r[a] = parseFloat(transaction.destinationTotal[a]) || 0
      return r
    }, {})

    if (transactionType === 'contribution') {
      transaction.isPledge = { sadaka: false }
    }

    transaction = {
      ...transaction,
      date: transaction.date.format('YYYY-MM-DDTHH:mm:ss'),
      sourceTotal: sourceTotalObj,
      destinationTotal: (sourceCurrency === destinationCurrency) ? sourceTotalObj : destinationTotalObj,

      exchangeRate: Object.keys(transaction.exchangeRate).reduce((r, a) => {
        r[a] = parseFloat(transaction.exchangeRate[a]) || 0
        return r
      }, {})
    }

    notification.open({
      key,
      message: 'İşleniyor...',
      icon: <LoadingOutlined style={{ color: '#108ee9' }} />,
      placement: 'bottomRight'
    })

    setIsSubmitting(true)
    if (!initialData) {
      transaction.createdBy = auth.data.contact.fullname
      firebaseAuth.currentUser.getIdToken().then(token => {
        dispatch(AddTransaction({ token, transaction }))
      })
    } else {
      transaction._id       = _id
      transaction.updatedBy = auth.data.contact.fullname
      transaction.updatedAt = moment().format('YYYY-MM-DDTHH:mm:ss')
      firebaseAuth.currentUser.getIdToken().then(token => {
        dispatch(UpdateTransaction({ token, transaction }))
      })
      hide()
    }
  }

  const fillForm = (t) => {
    const transactionData = {
      source: t.source.accountCode,
      destination: t.destination.accountCode,
      date: moment(t.date),
      sourceTotal: t.sourceTotal,
      destinationTotal: t.destinationTotal,
      exchangeRate: t.exchangeRate,
      isPledge: t.isPledge,
      note: t.note,
      createdAt: t.createdAt,
      createdBy: t.createdBy
    }
    setSourceCurrency(t.source.currency)
    setDestinationCurrency(t.destination.currency)

    form.setFieldsValue({
      transaction: transactionData
    })

    refreshScreenTotals()
  }

  const accountOptions = (optionsType) => {
    try {
      const accountGroups  = {}
      const listOfAccounts = accounts

      // if (serverConfig) {
      //   if (optionsType === 'p') { listOfAccounts = accounts.filter(d => d.accountCode.startsWith(serverConfig.accounts.defaults.find(value => value.key === 'participant').root.value)) }

      //   if (optionsType === 'f') { listOfAccounts = accounts.filter(d => d.accountCode.startsWith(serverConfig.accounts.defaults.find(value => value.key === 'family').root.value)) }

      //   if (optionsType === 'n') { listOfAccounts = accounts.filter(d => !serverConfig.accounts.defaults.map(r => r.root.value).some(root => d.accountCode.startsWith(root))) }
      // }

      listOfAccounts.filter(ac => ac.accountCode !== '' && ac.accountCode.split('.').length === 2).forEach((a, i) => {
        accountGroups[a.accountCode] = <Select.OptGroup key={i} label={a.name} children={[]}></Select.OptGroup>
      })

      listOfAccounts.filter(ac => ac.accountCode !== '' && ac.accountCode.split('.').length === 2).forEach((a, i) => {
        accountGroups[a.accountCode] = <Select.OptGroup key={i} label={a.name} children={[]}></Select.OptGroup>
      })

      listOfAccounts.sort((a, b) => (a?.name || '').localeCompare((b?.name || ''))).forEach((a, i) => {
        if (a.accountCode !== '') {
          const codeArr = a.accountCode.split('.')
          if (codeArr.length === 3) {
            const gr = accountGroups[codeArr.slice(0, 2).join('.')]

            if (gr) {
              gr.props.children.push(
                            <Option key={i} value={a.accountCode} data={a}>
                                <IconFromStringComp string={a.currency} /> {a.name} ({a.accountCode})
                        </Option>
              )
            }
          }
        }
      })
      return Object.keys(accountGroups).map((k) => accountGroups[k])
    } catch (e) {
      console.log(e)
    }
  }

  const donationTypeFields = () => {
    const rows = serverConfig.transactions.donationTypes.map((dt, index) => {
      return <Row key={index} gutter={[8, 8]}>
                <Col span={sourceCurrency === destinationCurrency ? 19 : 6}>
                    <Form.Item name={['transaction', 'sourceTotal', s2v(dt)]} label={dt} >
                        <InputNumber decimalSeparator=',' style={{ width: '100%' }} onChange={handleSourceTotalChange} />
                    </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 7} >
                    <Form.Item name={['transaction', 'exchangeRate', s2v(dt)]} label="Kur" >
                        <Input onChange={handleSourceTotalChange} suffix={loadingExchangeRate ? <LoadingOutlined /> : sourceCurrency === destinationCurrency ? <IconFromStringComp string={sourceCurrency} /> : <span><IconFromStringComp string={sourceCurrency} />/<IconFromStringComp string={destinationCurrency} /> </span>} />
                    </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 6}>
                    <Form.Item name={['transaction', 'destinationTotal', s2v(dt)]} label="Tutar" >
                        <InputNumber decimalSeparator=',' style={{ width: '100%' }} onChange={handleDestionationTotalChange} />
                    </Form.Item>
                </Col>

                <Col span={5} style={{ marginTop: 40, display: 'flex' }}>
                    <Form.Item name={['transaction', 'isPledge', s2v(dt)]} style={{ display: 'inline-table' }} valuePropName="checked">
                        <Switch checkedChildren="Taahüt" unCheckedChildren="Ekstra" />
                    </Form.Item>
                    <Tooltip color='geekblue' placement='left' title='Bu tutar, katılımcının aylık taahütü için yaptığı bir ödeme mi ? Yoksa ekstra bir ödeme mi ?' >
                        <QuestionCircleOutlined style={{ marginTop: '10px' }} />
                    </Tooltip>
                </Col>
            </Row>
    })

    return <>
            {rows}
            <Row gutter={[8, 8]}>
                <Col span={sourceCurrency === destinationCurrency ? 24 : 8}>
          <Form.Item label="TOPLAM" >
                        {numeral(screenTotal.sourceTotal).format('0,0[.]00')} <IconFromStringComp string={sourceCurrency} />
                    </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 8} offset={8}>
                    <Form.Item label='AKTARILACAK TOPLAM' >
                        {screenTotal.destinationTotal} <IconFromStringComp string={destinationCurrency} />
                    </Form.Item>
                </Col>
            </Row>

        </>
  }

  const contributionTypeFields = () => {
    return <>
            <Row  gutter={[8, 8]}>
                <Col span={sourceCurrency === destinationCurrency ? 24 : 8}>
                    <Form.Item name={['transaction', 'sourceTotal', 'sadaka']} label={'Tutar'} >
                        <InputNumber decimalSeparator=',' style={{ width: '100%' }} onChange={handleSourceTotalChange} />
                    </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 8} >
                    <Form.Item name={['transaction', 'exchangeRate', 'sadaka']} label="Kur" >
                        <Input onChange={handleSourceTotalChange} suffix={loadingExchangeRate ? <LoadingOutlined /> : sourceCurrency === destinationCurrency ? <IconFromStringComp string={sourceCurrency} /> : <span><IconFromStringComp string={sourceCurrency} />/<IconFromStringComp string={destinationCurrency} /> </span>} />
                    </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 8}>
                    <Form.Item name={['transaction', 'destinationTotal', 'sadaka']} label="Tutar" >
                        <InputNumber decimalSeparator=',' style={{ width: '100%' }} onChange={handleDestionationTotalChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={sourceCurrency === destinationCurrency ? 24 : 8}>
                  <Form.Item label="TOPLAM" >
                      {numeral(screenTotal.sourceTotal).format('0,0[.]00')} <IconFromStringComp string={sourceCurrency} />
                  </Form.Item>
                </Col>
                <Col span={sourceCurrency === destinationCurrency ? 0 : 8} offset={8}>
                    <Form.Item label='AKTARILACAK TOPLAM' >
                        {screenTotal.destinationTotal} <IconFromStringComp string={destinationCurrency} />
                    </Form.Item>
                </Col>
            </Row>

        </>
  }

  // attach keyboad shortcuts to submit form on ctrl + enter
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        form.submit()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [form])

  return (
        <Spin tip="Lütfen Bekleyin..." spinning={isTransactionLoading && areAccountsLoading}>

            <Title >{initialData ? 'Ödeme Bilgileri' : 'Yeni Ödeme Ekle'}</Title>
            <Paragraph>
                Aşağıdaki formu kullanarak {initialData ? 'gelen ödemeyi düzenleyebilirsiniz' : 'yeni ödeme girişi yapabilirsiniz.'}.
            </Paragraph>
            <Row>
                <Col span={24} >
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                confirm({
                  title: 'Bu formu göndermek istediğinizden emin misiniz?',
                  icon: <ExclamationCircleOutlined />,
                  onOk () {
                    form.submit()
                  }
                })
              }
            }}
            initialValues={initialValues}
            validateMessages={validateMessages}
          >
                        <Form.Item style={{ display: 'none' }} name={['transaction', 'createdAt']} ><Input type='hidden' /></Form.Item>
                        <Form.Item style={{ display: 'none' }} name={['transaction', 'createdBy']}><Input type='hidden' /></Form.Item>

                        <Form.Item name={['transaction', 'date']} label="Tarih" rules={[{ type: 'object', required: true, message: 'Lütfen geçerli bir tarih giriniz!' }]}>
                            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} onChange={date => setDate(date)} />
                        </Form.Item>

                        <Form.Item
                            name={['transaction', 'source']}
                            label="Borçlu Hesap"
                            rules={[{ required: true }]}
                        >
                            <Select showSearch placeholder="Hesap Seçin" optionFilterProp="children"
                                onChange={(e, option) => {
                                  setSourceCurrency(option.data.currency)

                                  // set monthly pledge automatically
                                  if (transactionType === 'donation') {
                                    const transaction = form.getFieldValue('transaction')
                                    if (option.data.connectedContact?.participation?.pledges) {
                                      transaction.sourceTotal[s2v(serverConfig.transactions.donationTypes[0])] = (option.data.connectedContact?.participation?.pledges[transaction.date.format('YYYY')] || 0)
                                    }
                                    refreshScreenTotals()
                                    form.setFieldsValue({ transaction })
                                  }
                                }}>
                                {
                                  accountOptions(transactionType === 'donation' ? 'p' : transactionType === 'contribution' ? 'n' : '')
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={['transaction', 'destination']}
                            label="Alacaklı Hesap"
                            rules={[{ required: true }]}
                        >
                            <Select showSearch placeholder="Hesap Seçin" optionFilterProp="children"
                                onChange={(e, option) => {
                                  setDestinationCurrency(option.data.currency)
                                  refreshScreenTotals()
                                }}>
                              {
                                accountOptions(transactionType === 'contribution' ? 'f' : '')
                              }
                            </Select>
                        </Form.Item>

                        {
                            transactionType === 'donation' ? donationTypeFields() : contributionTypeFields()
                        }

                        <Form.Item name={['transaction', 'note']} label="Açıklama" >
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Spin>

  )
})

export default TransactionForm
