import { all } from 'redux-saga/effects'

import { addTransactionSagaWatcher } from './add_transaction.saga'
import { deleteTransactionSagaWatcher } from './delete_transaction.saga'
import { deleteTransactionsSagaWatcher } from './delete_transactions.saga'
import { getTransactionSagaWatcher } from './get_transaction.saga'
import { getTransactionsSagaWatcher } from './get_transactions.saga'
import { getTransactionsOfParticipantsSagaWatcher } from './get_transactions_ofparticipants.saga'
import { updateTransactionSagaWatcher } from './update_transaction.saga'

export default function * transactionsSaga () {
  yield all([
    getTransactionsSagaWatcher(),
    getTransactionsOfParticipantsSagaWatcher(),
    getTransactionSagaWatcher(),
    addTransactionSagaWatcher(),
    deleteTransactionSagaWatcher(),
    deleteTransactionsSagaWatcher(),
    updateTransactionSagaWatcher()
  ])
}
