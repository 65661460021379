import React from 'react'

import { Form, Input, DatePicker, Select, InputNumber } from 'antd'

import locale from 'antd/es/date-picker/locale/tr_TR'

const EducationFormItem = (params) => {  return (<>

        <Form.Item name={[params.root, 'schoolName']} label="Okul Adı" rules={[{ required: true }]}><Input /></Form.Item>
        <Form.Item name={[params.root, 'degree']} label="Seviye" rules={[{ required: true }]}>
            <Select allowClear >
                <Select.Option value='pre'>Ana Okulu</Select.Option>
                <Select.Option value='primary'>İlk Okul</Select.Option>
                <Select.Option value='secondary'>Orta Okul</Select.Option>
                <Select.Option value='high'>Lise</Select.Option>
                <Select.Option value='undergraduate'>Lisans</Select.Option>
                <Select.Option value='graduate'>Yüksek Lisans</Select.Option>
                <Select.Option value='phd'>Doktora</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item name={[params.root, 'level']} label="Sınıf">
            <InputNumber />
        </Form.Item>

        <Form.Item name={[params.root, 'department']} label="Bölüm">
            <Input />
        </Form.Item>

        <Form.Item name={[params.root, 'start']} label="Başlangıç Tarihi">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name={[params.root, 'end']} label="Bitiş Tarihi">
            <DatePicker locale={locale} format='DD.MM.YYYY' style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item name={[params.root, 'note']} label="Bilgi Notu">
            <Input.TextArea />
        </Form.Item>

    </>

  )
}

export default EducationFormItem
