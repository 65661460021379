import React, { useState, useEffect, useRef } from 'react'

import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { Form, Card, Button, Empty, Row, Col, Timeline, Tooltip, Modal } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'

import AttachmentsViewer from '../../../../components/AttachmentsViewer'
import NotesFormItem from '../FormItems/NotesFormItem'

const { confirm } = Modal

const NotesForm = (params) => {
  const { mainForm, validateMessages, next, prev, mode, setMode, data } = params
  const editMode                                                        = mode === 'edit'
  const addNewMode                                                      = mode === 'addNew'
  const wizardMode                                                      = (next && prev)
  const notesData                                                       = (
    data &&  // if there is data
    data.notes) || // return if contains a notes array
    []

  const auth                                  = useSelector((state) => state.auth)
  const [noteIndexToEdit, setNoteIndexToEdit] = useState(undefined)
  const buttonRef                             = useRef(null)

  useEffect(() => {
    if (typeof noteIndexToEdit !== 'undefined') { setMode('edit') }
  }, [noteIndexToEdit])

  useEffect(() => {
    if (mode === 'addNew') {
      const family = mainForm.getFieldValue('family')
      family.notes.push({ noteTaker: auth.data.contact.fullname, date: moment() })

      mainForm.setFieldsValue({
        family
      })
    }
  }, [mode])

  const formItemLayout = index => {
    return {
      labelCol: wizardMode ? {
        xs: { span: 24 },
        sm: { span: 4 },
        md: { span: 4 },
        lg: { span: 3 },
        xl: { span: 1 }
      } : { span: 24 },
      wrapperCol: wizardMode ? {
        xs: { span: 24, offset: 0 },
        sm: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        md: { span: index !== 0 ? 20 : 24, offset: index !== 0 ? 4 : 0 },
        lg: { span: index !== 0 ? 21 : 24, offset: index !== 0 ? 3 : 0 },
        xl: { span: index !== 0 ? 23 : 24, offset: index !== 0 ? 1 : 0 }
      } : { span: 24 }
    }
  }
  return editMode || addNewMode ? (
        <Form form={mainForm} name='notesStepForm' validateMessages={validateMessages} onFinish={next} >
            <Form.List name={['family', 'notes']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='Hiç Not Eklenmemiş' />
                              : fields.map((field, index) => {
                                return (
                                        <Form.Item
                                            label={index === 0 && wizardMode ? 'Notlar' : ''}
                                            {...formItemLayout(index)}
                                            key={field.key}
                                            style={{
                                              display:
                                                    !wizardMode &&
                                                    ((!addNewMode && index !== noteIndexToEdit) ||
                                                    (addNewMode && index !== fields.length - 1))
                                                      ? 'none' : null
                                            }}
                                        >
                                            <Card actions={[<DeleteOutlined key="delete" onClick={() => {
                                              if (wizardMode) { remove(field.name) } else {
                                                confirm({
                                                  title: 'Bu Notu Silmek istediğinizden emin misiniz?',
                                                  icon: <ExclamationCircleOutlined />,
                                                  content: 'Bu not tamamen silinecektir',
                                                  okText: 'Evet',
                                                  okType: 'danger',
                                                  cancelText: 'Hayır',
                                                  onOk () {
                                                    const family = mainForm.getFieldValue('family')
                                                    family.notes.splice(field.name, 1)
                                                    next({ values: { family } })
                                                    setNoteIndexToEdit(undefined)
                                                    setMode('display')
                                                  }
                                                })
                                              }
                                            }
                                            } />]} >
                                                <NotesFormItem root={field.name} wizardMode={wizardMode} />
                                            </Card>

                                        </Form.Item>
                                )
                              })}

                            {wizardMode
                              ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }}>
                                    <Button type="dashed" onClick={() => { add({ noteTaker: auth.data.contact.fullname, date: moment() }) }} style={{ width: '100%' }} >
                                        <PlusOutlined />Not Ekle</Button>
                                </Form.Item>
                              : null}
                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode || addNewMode ? <Button ref={buttonRef} type="primary" htmlType='submit' onClick={() => { setNoteIndexToEdit(undefined) }} style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>
  )
    : <NotesTimeline notesData={notesData} setNoteIndexToEdit={setNoteIndexToEdit} />
}

const NotesTimeline    = ({ notesData, setNoteIndexToEdit }) => {
  if (notesData.length) {
    return <Timeline mode='left'>
            {notesData.sort((a, b) => moment(b.date).diff(moment(a.date))).map((n, index) =>
                <Timeline.Item key={index} label={moment(n.date).format('DD.MM.YYYY')}>
                    {n.note}
                    <span className='noteTakerSpan'>{n.noteTaker}</span>
                    <Tooltip title='Düzenle' > <EditOutlined style={{ cursor: 'pointer' }} onClick={() => setNoteIndexToEdit(index)} /></Tooltip>
                    <AttachmentsViewer attachments={n.attachments} />
                </Timeline.Item>
            )}

        </Timeline>
  } else { return <Empty description='Hiç Not Eklenmemiş' /> }
}
NotesForm.defaultProps = {
  mode: 'edit'
}
export default NotesForm
