import { all } from 'redux-saga/effects'

import { authStateChangeWatcher } from './authStateChange.saga'
import { logoutWatcher } from './logout.saga'
export default function * userSaga () {
  yield all([
    logoutWatcher(),
    authStateChangeWatcher()
  ])
}
