import { put, takeLatest, call } from 'redux-saga/effects'

import { UpdateParticipantContact } from '../../../services/contactsService'

import {
  UPDATE_PARTICIPANT_CONTACT,
  UPDATE_PARTICIPANT_CONTACT_SUCCESS,
  UPDATE_PARTICIPANT_CONTACT_ERROR
}
  from '../../actions/contacts'

function * updateParticipantContactSaga (action) {
  try {
    const data =  yield call(UpdateParticipantContact, action.payload)
    yield put({
      type: UPDATE_PARTICIPANT_CONTACT_SUCCESS,
      payload: data
    })
  } catch (error) {
    yield put({
      type: UPDATE_PARTICIPANT_CONTACT_ERROR, payload: error
    })
  }
}

export function * updateParticipantContactSagaWatcher () {
  yield takeLatest(UPDATE_PARTICIPANT_CONTACT, updateParticipantContactSaga)
}
