import React from 'react'

import {
  PlusOutlined,
  MinusCircleOutlined, BankOutlined
} from '@ant-design/icons'
import { Form, Input, Button, Empty, Row, Col, Typography } from 'antd'

const AssociationsForm = (params) => {  const { mainForm, validateMessages, next, prev, mode } = params
  const editMode                                         = mode === 'edit'
  const wizardMode                                       = (next && prev)

  const formItemLayout = index => {
    return {
      labelCol: wizardMode ? {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 7 },
        lg: { span: 7 },
        xl: { span: 6 }
      } : { span: 24 },
      wrapperCol: wizardMode ? {
        xs: { span: 24, offset: 0 },
        sm: { span: 24, offset: 0 },
        md: { span: index !== 0 ? 17 : 24, offset: index !== 0 ? 7 : 0 },
        lg: { span: index !== 0 ? 17 : 24, offset: index !== 0 ? 7 : 0 },
        xl: { span: index !== 0 ? 18 : 24, offset: index !== 0 ? 6 : 0 }
      } : { span: 24 }
    }
  }

  return (
        <Form form={mainForm} name='associationsStepForm' validateMessages={validateMessages} onFinish={next} >

            <Form.List name={['family', 'otherHelpingAssociations']}>
                {(fields, { add, remove }) => {
                  return (
                        <div>
                            {fields.length === 0 ? <Empty description='Yardım Alınan Başka Bir Kurum Eklenmemiş' />
                              : fields.map((field, index) => (
                                    <Row key={field.key}>
                                        <Col span={22}>
                                            <Form.Item
                                                label={index === 0 && wizardMode ? 'Yardım Al. Kuruluşlar' : ''}
                                                name={[field.name]}
                                                {...formItemLayout(index)}
                                                key={field.key}
                                            >
                                                {editMode ? <Input /> : <PlainText />}
                                            </Form.Item>
                                        </Col>
                                        {editMode
                                          ? <Col span={2}>
                                                <MinusCircleOutlined className="dynamic-delete-button"
                                                    onClick={() => {
                                                      remove(field.name)
                                                    }}
                                                />
                                            </Col>
                                          : null}
                                    </Row>
                              )
                              )}

                            {editMode
                              ? <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }} >
                                    <Button type="dashed" onClick={() => add()} style={{ width: '100%' }} >
                                        <PlusOutlined />Yardım Alınan Kuruluş Ekle</Button>
                                </Form.Item>
                              : null}
                        </div>
                  )
                }}
            </Form.List>

            {/* if this compopnent used in wizard show next previus buttons, else display Submit button */}
            {
                wizardMode
                  ? <Row gutter={[4, 4]} align='bottom'>
                        <Col span={12}>
                            <Button onClick={prev} style={{ width: '100%' }} >Önceki</Button>
                        </Col>
                        <Col span={12}>
                            <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Sonraki </Button>
                        </Col>
                    </Row>
                  : editMode ? <Button type="primary" htmlType='submit' style={{ width: '100%' }}> Kaydet </Button> : null
            }
        </Form>

  )
}

const PlainText = ({ value }) => <Typography.Text><BankOutlined />{value}</Typography.Text>

AssociationsForm.defaultProps = {  mode: 'edit'
}

export default AssociationsForm
