import React from 'react'

import { Routes, Route } from 'react-router-dom'

import ParticipantsList from './ParticipantsList'
import Summary from './Summary'
import YearView from './YearView'

const Participants = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ParticipantsList />} />
      <Route path={'/yearview'} element={<YearView />} />
      <Route path={'/summary'} element={<Summary />} />
      <Route path={'/:_id'} element={<ParticipantsList />} />
    </Routes>
  )
}
export default Participants
