import * as Actions from '../../actions/contacts'

const initialState = {
  data: null,
  isLoading: false,
  error: false
}

const getParticipantReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PARTICIPANT_CONTACT: {
      return {
        ...state,
        isLoading: true,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACT_SUCCESS: {
      return {
        ...state,
        data: JSON.parse(JSON.stringify(action.payload)),
        isLoading: false,
        error: false
      }
    }
    case Actions.GET_PARTICIPANT_CONTACT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default getParticipantReducer
